import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import './Modal.scss'
import EmployeeLogin from './EmployeeLogin';
import ClubNumber from './ClubNumber';
import Timeout from './Timeout';
import Terms from './Terms';
import QuitPlan from './QuitPlan';


class Modal extends Component {
    constructor(props){
        super(props);
        this.modalClose = this.modalClose.bind(this)
        this.outsideClick = this.outsideClick.bind(this)
    }
    modalClose(e){
        e.preventDefault();
        e.stopPropagation();
        //clear children
        this.props.ModalStore.setSection('nothing')
        this.props.ModalStore.closeModal()
    };

    outsideClick(e){
        //close modal from oustide click
        if (e.target.className === 'appModal'){
            if (this.props.ModalStore.closeHidden === false){
                this.props.ModalStore.setSection('nothing')
                this.props.ModalStore.closeModal()
            }
        }
    }
    modalItem(){
        switch (this.props.ModalStore.section){
            case "employeeLogin":
                return (<EmployeeLogin history={this.props.history}/>)
            case "clubNumber":
                return (<ClubNumber history={this.props.history}/>)
            case "timeout":
                return (<Timeout/>)
            case "terms":
                return (<Terms/>)
            case "quitPlan":
                return (<QuitPlan/>)
            default:
                return (<div>default</div>)
        }
    }
    render() {
        const { closeHidden, isOpen  } = this.props.ModalStore;
        const styles = {
            modalVisibility: () => {
                if (isOpen) {
                    return {
                        display: 'block',
                    }
                }else{
                    return {
                        display: 'none',
                    }
                }
            },
			closeVisibility: () => {
                if (closeHidden) {
                    return {
                        display: 'none',
                    }
                }else{
                    return {
                        display: 'block',
                    }
                }
            }
        }
        return(
            <div className="appModal"
                style = {styles.modalVisibility()}
                onClick = { this.outsideClick }
            >
                <div className="modalWrapper">
                    <button  className="closeButton"
                        style = {styles.closeVisibility()}
                        onClick={this.modalClose}
                    >x</button>
                    {this.modalItem()}
                </div>
            </div>
        )
    }
}

Modal  = inject('ModalStore')(observer(Modal))
export default Modal;



