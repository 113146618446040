import ClubDataStore from '../../Stores/ClubDataStore'
class ScriptGenerator {

    removeClubItems(script){
        script = script.filter( (value, index) => {
            return !(value.includes('club'))
        })
        return script
    }
    userHasNoInfoCheck(user){
        var hasNoInfo = true 
        var neededInfo = [
            'gender',
            'height',
            'weight',
            'non_gym_activities',
            'goals',
            'primary_goal',
            'equipment',
            'overall_fitness',
            'frequency',
            'intensity',
            'pushup',
            'pullup',
            'bdw_squat',
            'endurance_stairs',
            'flexibility',
            'single_leg_balance'
        ]
        neededInfo.forEach( (item)=> {
            if (user[item]){
                hasNoInfo  = false
            }
        })
        return hasNoInfo

    }
    userHasAllInfoCheck(user){
        //TODO make this neededInfo a constant
        var hasAllInfo = true 
        var neededInfo = [
            'gender',
            'height',
            'weight',
            'non_gym_activities',
            'goals',
            'primary_goal',
            'equipment',
            'overall_fitness',
            'intensity',
            'pushup',
            'pullup',
            'bdw_squat',
            'endurance_stairs',
            'flexibility',
            'single_leg_balance'
        ]
        neededInfo.forEach( (item)=> {
            if (!user[item]){
                hasAllInfo = false
            }
        })
        return hasAllInfo
    }

    createScript(user){
        const {clubDataLoaded} = ClubDataStore
        console.log(clubDataLoaded)
        var script = []
        if (this.userHasAllInfoCheck(user)){
            script.push('success')
            return script
        }
        //if any missing, add personalize
        var profile_info = [
            'gender',
            'height',
            'weight',
            'club_selector_question',
            'club_amenities',
            'club_group_classes',
            'club_strength_equipment',
            'club_cardio_equipment',
            'non_gym_activities',
            'goals',
            'primary_goal',
            'equipment',
        ]

        
        //if gym is not known add club_selector_question

        //
        //if any missing, add fitness_assement_intro
        var assement_info = [
            'overall_fitness',
            'frequency',
            'intensity',
            'pushup',
            'pullup',
            'bdw_squat',
            'endurance_stairs',
            'flexibility',
            'single_leg_balance'
        ]


        var gym_info = [
            'club_amenities',
            'club_group_classes',
            'club_strength_equipment',
            'club_cardio_equipment',
        ]

        // eslint-disable-next-line
        var haveAllProfileInfo = true
        var haveSomeProfileInfo = false
        var haveAllAssementInfo = true
        var haveAllGymInfo = false 

        //do we have all profile_info  
        profile_info.forEach( (item)=> {
            if (!user[item]){
                script.push(item)
                haveAllProfileInfo = false
            }else{
                haveSomeProfileInfo = true
            }
        })

        gym_info.forEach( (item)=> {
            if (!user[item]){
                haveAllGymInfo = false
            }
        })
        
        //if we have some, show returning user screen
        if (haveSomeProfileInfo){
            script.unshift('welcome_back')
        }
        
        /////
        //add fitness_assment_intro if haveAllAsset = false
        assement_info.forEach( (item)=> {
            if (!user[item]){
                haveAllAssementInfo = false 
            }
        }) 
        if (!haveAllAssementInfo){
            script.push("assessment_intro")
        }
        assement_info.forEach( (item)=> {
            if (!user[item]){
                script.push(item)
            }
        }) 
        
        //if have all club info, remove club question
        //or remove club selector if club is known
		//or club id exists in user object TODO
        if (clubDataLoaded || haveAllGymInfo) {
            script = script.filter(function(value, index, arr){
                return value !==  'club_selector_question';
            });
        }
        
        //add final items
        //'processing_animation',
        //'success'
        
        script.push('processing_animation')
        script.push('success')

        
        return script
    }



}
export default new ScriptGenerator();
