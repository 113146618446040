import { observable, action, runInAction, decorate } from 'mobx';
import agent from '../../api/Agent';


class StreamStore {
    stream = {}
    getStream(id) {
        this.inProgress = true;
        return agent.Stream.getStream(id)
        .then( (stream)  => {
            runInAction( () => {
               this.stream = stream  
            })
          })
		.catch(action((err) => {
			alert(err)
			//TODO error handling
			//TODO successfull errors too
			throw err;
		}))
        .finally(action(() => { this.inProgress  = false; }))

    }

}
decorate(StreamStore, {
    inProgress: observable,
    stream: observable,
    getStream: action,
});

export default new StreamStore();
