import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { inject, observer } from 'mobx-react';

import GymListings from './GymListings'
import GymMapHeader from './GymMapHeader'
import GymMap from './GymMap'

import { Hidden, withStyles, Modal, Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        height: 'calc(100% - 4.25rem)',
        [theme.breakpoints.down('xs')]: {
            height: '100%',
        },
        '@media (orientation: landscape) and (max-height: 600px)': {
            height: '100%',
        },
    },
    mapHeader: {
        height: '7rem',
        boxShadow: '0 8px 19px -3px rgba(0, 0, 0, 0.09)',
        '@media (orientation: landscape) and (max-height: 600px)': {
            display: 'none',
        },
    },
    gymContainer: {
        height: 'calc(100% - 7rem);',
        '@media (orientation: landscape) and (max-height: 600px)': {
            height: '100%',
        },
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            height: '100%',
            flexDirection: 'column',
        },
    },
    gymMap: {
        [theme.breakpoints.down('xs')]: {
            height: '40%',
            flexGrow: 'unset',
            flexBasis: '60%',
        },
    },
    gymList: {
        overflowY: 'auto',
        [theme.breakpoints.down('xs')]: {
            height: '60%',
        },
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        maxWidth: '400px',
        margin: '0 10px',
        outline: 'none',
        boxShadow: theme.shadows[5],
        padding: '24px',
        '& > * > p > a': {
            textDecoration: 'none',
        }
    },
});

class GymLocator extends Component {
    componentDidMount() {
        const {scrollIntoView} = this.props;
        // Show searchbox in mobile devices when first entering to the screen
        if (scrollIntoView) {
            var gymMapContainer = document.getElementById('gym-map-container');
            if (gymMapContainer) {
                gymMapContainer.scrollIntoView(true);
            }
        }

    }
    
    claimOnClick(gym) {
        this.props.selectionClick(gym);
    }

    getPermissionsModal() {
        const { classes } = this.props;
        let {showFailure, finishShowingError} = this.props.GymFinderStore;
        return (
            <Modal
            aria-labelledby="modal-title"
            aria-describedby="simple-modal-description"
            open={showFailure}
            className={classes.modal}
            onClick={finishShowingError}>
                <div className={classes.modalContent}>
                    <Typography variant="h6" id="modal-title">
                    Could not access to the location, please check your browser permissions.
                    </Typography>
                    <Typography variant="subtitle1" id="simple-modal-description">
                        <p><a href="https://support.google.com/chrome/answer/114662" target="_blank" rel="noopener noreferrer">Chrome</a><br/>
                        <a href="https://support.apple.com/guide/safari/ibrw7f78f7fe" target="_blank" rel="noopener noreferrer">Safari</a><br/>
                        <a href="https://support.apple.com/en-us/HT207092" target="_blank" rel="noopener noreferrer">Safari Mobile</a></p>
                    </Typography>
                </div>
            </Modal>
        );
    }

    render(){
        const { classes } = this.props;
        console.log('RENDER')
        return (
            <Grid className={classes.root}>
                {this.getPermissionsModal()}
                <Hidden xsDown>
                    <Grid item xs={12} className={classes.mapHeader}>
                        <GymMapHeader/>
                    </Grid>
                </Hidden>
                <div className={classes.gymContainer} id='gym-map-container'>
                    <Grid item xs={12} sm={8} className={classes.gymMap}>
                        <GymMap/>
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.gymList}>
                        <GymListings claimOnClick={this.claimOnClick.bind(this)}/>
                    </Grid>
                </div>
            </Grid>
        )
    }
}

GymLocator = inject('GymFinderStore')(observer(GymLocator))

export default withStyles(styles)(GymLocator);
