import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';


class Gender extends Component {

	radioSelected = undefined

    data = [
        {
            "name": "m",
            "label": "Male"
        },
        {
            "name": "f",
            "label": "Female"
        },
        {
            "name": "u",
            "label": "Prefer not to say"
        }
    ]

    handleToggle = e => (e) => {
        const {ident} = this.props
        if (e.target.value){
            this.radioSelected = Number(e.target.value);
        }
        if (e.currentTarget.getAttribute('value')) {
            this.radioSelected = Number(e.currentTarget.getAttribute('value'));
        }

        this.props.UserStore.setValue(ident,this.data[this.radioSelected].name)
    };


    render(){
        const {ident} = this.props
        var selected = undefined
        if (this.props.UserStore.currentUser[ident]){
            selected = this.props.UserStore.currentUser[ident]
        }
        if (selected){
            this.data.forEach( (value, index) => {
                if (selected === value.name){
                    this.radioSelected = index
                }
            })
        }
		const radios = ()=> {
			var components = []
			this.data.forEach( (value, index)=> {
				components.push(

                    <ListItem
                            value= {index}
                        key={index}
                        role={undefined}
                        dense
                        className="listItem"
                        disableGutters={true}
                        button
                        divider={true}
                        onClick={this.handleToggle(index)}
                    >
                        <FormControlLabel
                            labelPlacement="start"
                            value= {String(index)}
                            checked={index === this.radioSelected}
                            control={<Radio
                                style={{position:'absolute',right:0,bottom:-3}}
                                color='primary'
                            />}
                            style={{paddingLeft:'15px',paddingRight:'15px'}}
                            label={this.data[index].label} />
                    </ListItem>
 				)
			})
			return components
		}

        return (
                    <div className="stepContent animate-fadeInDown progress-Info-Wrapper">
                      <h3>What is your gender?</h3>
                        <List
                            dense={true}
                            disablePadding={true}
                        >
                                {radios()}
                        </List>
                    </div>

        )
    }
}

Gender = inject('UserStore')(observer(Gender))
export default Gender;


