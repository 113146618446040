import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';

class WorkoutStyles extends Component {

    state = {
        checked: [],
    };

    data =  [
        {
            "name": "alone",
            "label": "Alone"
        },
        {
            "name": "partner",
            "label": "With a partner"
        },
        {
            "name": "small_group",
            "label": "With a small group"
        },
        {
            "name": "large_group",
            "label": "With a large group"
        }
    ]

    constructor(props){
        super(props);
        this.handleToggle = this.handleToggle.bind(this)
    }
    handleToggle = (value) => () => {
        const {ident} = this.props
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({
            checked: newChecked,
        });
        var correctData = []
        newChecked.forEach( ( value ) => {
            correctData.push(this.data[value].name)
        })
        var pipeString = correctData.join("|") 
        this.props.UserStore.setValue(ident,pipeString)

    };


    render(){
        const {ident} = this.props
		const checkboxes = ()=> { 
			var components = [] 
            var selectedItems = '' 
            if (this.props.UserStore.currentUser[ident]){
                selectedItems  = this.props.UserStore.currentUser[ident] 
            }
            selectedItems = selectedItems.split('|')
            var selectedIndexes = []
            selectedItems.forEach( (selectedValue)=>{
                this.data.forEach( (dataValue, index )=> {
                    if(selectedValue === dataValue.name){
                       selectedIndexes.push(index) 
                    }
                })
            })
			this.data.forEach( (value, index)=> {
				components.push( 
                    <ListItem
                        key={value.name}
                        role={undefined}
                        dense
                        className="listItem"
                        disableGutters={true}
                        button
                        divider={true}
                        onClick={this.handleToggle(index)}
                    >
                        <div className=''> {` ${value.label}`} </div>
                        <Checkbox
                            checked={selectedIndexes.indexOf(index) !== -1}
                            tabIndex={-1}
                            style={{ color: '#3CB6CE',position:'absolute',right:0 }}
                            disableRipple
                            className = "shawn"
                        />
                    </ListItem>
 				)
			})	
			return components
		}

        return (
            <div className="stepContent animate-fadeInDown">
              <h1>I like to work out:</h1>
              <h2>Select all that apply.</h2>
                <List 
                    dense={true}
                    disablePadding={true}
                >
                    {checkboxes()}	
                </List> 
            </div>
        )
    }
}

WorkoutStyles = inject('UserStore')(observer(WorkoutStyles))    
export default WorkoutStyles;
