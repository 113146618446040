import { observable, action,  decorate } from 'mobx';

import agent from '../../api/Agent';

class RegistrationStore {
    inProgress = false;
    regValues = {}
    errors = {}

    getReg(){
        this.inProgress = true;
        this.errors = undefined;
        return agent.GO24.getRegistrationData().then( (response) => {
			if (response.error){
				//TODO successful error
				console.log(response.error)
			}
			return response
        })
        .catch(action( (err) => {
            //TODO - figure out a loss of connection error
            this.errors = err.message 
            //throw err;
        }))
        .finally(action(() => { 
            this.inProgress = false; 
        }));
    }
}

decorate( RegistrationStore, {
    regValues: observable,
    inProgress: observable
})

export default new RegistrationStore();
