import { observable, action,  decorate } from 'mobx';

class ModalStore {
    //observables
    isOpen = false;
    section = 'employeeLogin'
    closeHidden = false 

    //setSection
    openModal(){
        this.isOpen = true
    }
    closeModal(){
        this.isOpen = false 
    }
    setSection(section){
        this.section = section   
    }
    setCloseHidden(){
        this.closeHidden = true
    }
    setCloseVisible(){
        this.closeHidden = false 
    }
}

decorate(ModalStore, {
    closeHidden:  observable,
    openModal: action,
    closeModal: action,
    setSection: action,
    isOpen: observable,
    setCloseHidden: action,
    setCloseVisible: action,
    section: observable
});

export default new ModalStore();
