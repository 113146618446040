import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import Grid from '@material-ui/core/Grid';

import WelcomeBack from './WelcomeBack.js';
import Gender from './Gender.js';
import Height from './Height.js';
import Weight from './Weight.js';
import WorkoutStyle from './WorkoutStyle.js';
import SkipReasons from './SkipReasons.js';
import Motivate from './Motivate.js';
import Intermission from './Intermission.js';
import Goals from './Goals.js';
import PrimaryGoal from './PrimaryGoal.js';
import Equipment from './Equipment.js';

import ClubSelectorQuestion from './ClubSelectorQuestion.js';
import GymLocatorModal from '../GymLocator/GymLocatorModal.js'

import ModalReferral  from '../ModalReferral/ModalReferral.js'

import ClubAmenities from './ClubAmenities.js';
import ClubGroupClasses from './ClubGroupClasses.js';
import ClubStrengthEquipment from './ClubStrengthEquipment.js';
import ClubCardioEquipment from './ClubCardioEquipment.js';
import NonGymActivities from './NonGymActivities.js';


import AssessmentIntro from './AssessmentIntro.js';
import OverallFitness from './OverallFitness.js';
import Frequency from './Frequency.js';
import Intensity from './Intensity.js';

import Pullup from './Pullup.js'
import Pushup from './Pushup.js'
import BDWSquat from './BDWSquat.js'
import Stairs from './Stairs.js'
import Flexibility from './Flexibility.js'
import SingleLegBalance from './SingleLegBalance.js'

import ProcessingAnimation from './ProcessingAnimation.js';
import Success from './Success.js';
import ScriptGenerator from './ScriptGenerator.js';

import './Profile.scss'
import { IconArrowLeft } from '../Icons/Icons';

class Profile extends Component {


    screens = []

    constructor(props){
        super(props);
        this.goForward = this.goForward.bind(this)
        this.goBack = this.goBack.bind(this)
        this.skipGym = this.skipGym.bind(this)
        this.selectGym = this.selectGym.bind(this)
        this.gymSelected = this.gymSelected.bind(this)
        this.returningUserProgress = this.returningUserProgress.bind(this)
    }

    componentWillMount(){
        const {currentUser} = this.props.UserStore
        //if returning user
        //set current screen
        //if last profile step, otherwise returning user or currentStep = 0
        this.screens = ScriptGenerator.createScript(currentUser)
        this.props.UserStore.setValue('current_step', 0)

        //DEBUG
        if (this.props.CommonStore.ericDebugProfileGym ){
            var workout_days_index = this.screens.indexOf('club_selector_question')
            this.props.UserStore.setValue('current_step', workout_days_index)
            
        }
    }

    ////Gym selection items
    selectGym(){
        //present gym overlay
        this.props.ClubDataStore.openSelectClubModal()
    }
    skipGym(){
        this.screens = ScriptGenerator.removeClubItems(this.screens)
        this.goForward()
    }

    gymSelected(){
        this.props.ClubDataStore.closeSelectClubModal()
        //examine user object, if no member show modal
        this.goForward()
        if (!this.props.UserStore.member){
            this.props.ReferralStore.openModal()
        }
    }

    showModal(){
        const {  clubDataModalIsOpen  } = this.props.ClubDataStore
        const {  referralModalisOpen  } = this.props.ReferralStore
        if (clubDataModalIsOpen){
            return (
                <GymLocatorModal gymSelected={this.gymSelected}/>
            )
        }
        if (referralModalisOpen){
            return (
                <ModalReferral />
            )
        }
    }
    //////////



    singleGoalCheck(){
        //TODO - this could go somewhere else, but checks
        //for  array having 1 item for primary goal
        //needs to happen here for both back and forward
        //skip functionality
        var goals = this.props.UserStore.currentUser.goals
        goals= goals.split('|')
        if (goals.length === 1){
            this.props.UserStore.setValue('primary_goal',goals[0])
            return true
        }
        return false

    }
    goBack(){
        var { current_step } = this.props.UserStore.currentUser
        current_step = current_step-1
        if (this.screens[current_step]==='primary_goal'){
            if (this.singleGoalCheck()){
                current_step = current_step-1
            }
        }
        this.props.UserStore.setValue('current_step', current_step)
    }

    goForward(){
        var { current_step} = this.props.UserStore.currentUser

		var optionalItems = [
            'club_amenities',
            'club_group_classes',
            'club_strength_equipment',
            'club_cardio_equipment',
            'non_gym_activities',
            'equipment'
		]

		var isOptional = optionalItems.includes(this.screens[current_step])
		if (isOptional){
			if (!this.props.UserStore.currentUser[this.screens[current_step]]){
                this.props.UserStore.setValue(
                    this.screens[current_step],
                    'none'
                )
			}
		}

        if (this.screens[current_step] !== 'processing_animation'){
            this.props.UserStore.updateUserPassive()
            current_step = current_step+1
            if (this.screens[current_step]==='primary_goal'){
                if (this.singleGoalCheck()){
                    current_step = current_step+1
                }
            }
            this.props.UserStore.setValue('current_step', current_step)
         }else{
            this.props.UserStore.updateUserAndSave()
            .then(() => {
                current_step = current_step+1
                this.props.UserStore.setValue('current_step', current_step)
            })
	    }
    }

    showBack(){
        //find current screen in array,
        //go back 1
        const { current_step} = this.props.UserStore.currentUser
        if (current_step > 0 && this.screens[current_step]!== "success"){

            return (
				<button href="#" onClick={this.goBack} className="progressBtn progressBtnPrev">
				  <IconArrowLeft nativeColor="#999" className="icon icon-arrowleft" />
				</button>
            )
        }
    }
    showForward(){
        //if current ident has value in current user
        const { current_step} = this.props.UserStore.currentUser
        //console.log(this.screens[current_step])
        var showForward = false

        switch (this.screens[current_step]){
            case 'frequency':
        		if (this.props.UserStore.currentUser[this.screens[current_step]] || this.props.UserStore.currentUser[this.screens[current_step]] === 0){
                	showForward = true
                }
                break;
            case 'intensity':
            case 'gender':
            case 'weight':
            case 'goals':
            case 'primary_goal':
            case 'overall_fitness':
            case 'pushup':
            case 'pullup':
            case 'bdw_squat':
            case 'endurance_stairs':
            case 'single_leg_balance':
            case 'flexibility':
        		if (this.props.UserStore.currentUser[this.screens[current_step]]){
                	showForward = true
				}
				break;
            case 'height':
        		if (this.props.UserStore.currentUser['height'] > 12){
                	showForward = true
				}
                break;
            case 'club_amenities':
            case 'club_group_classes':
            case 'club_strength_equipment':
            case 'club_cardio_equipment':
            case 'non_gym_activities':
            case 'equipment':
                showForward = true
                break;
            case 'processing_animation':
            case 'assessment_intro':
        	case 'success':
			default:
				showForward = false
				break;
        }
        
        var nextVerbage = '' 

        var nextVerbageObj = {
            'gender':'Gender',
            'height':'Height',
            'weight':'Weight',
            'club_selector_question':'Club Selection',
            'club_amenities':'Club Activities',
            'club_group_classes':'GX Classes',
            'club_strength_equipment':'Strength Training',
            'club_cardio_equipment':'Cardio Training',
            'non_gym_activities':'Home Activities',
            'goals':'Goals',
            'primary_goal':'Primary Goal',
            'equipment':'Equipment',
            'overall_fitness':'Overall Fitness',
            'frequency':'Workout Frequency',
            'intensity':'Workout Intensity',
            'pushup':'Upper Body Push',
            'pullup':'Upper Body Pull',
            'bdw_squat':'Lower Body Strength',
            'endurance_stairs':'Cardio',
            'flexibility':'Flexibility',
            'single_leg_balance':'Balance',
            "assessment_intro":"Fitness Assessment"
        }
        nextVerbage = nextVerbageObj[this.screens[(current_step+1)]]
            
        if ( showForward === true){
            return (
                <button href="#" onClick={this.goForward} className="progressBtn progressBtnNext">
                  <IconArrowLeft nativeColor="#fff" className="icon icon-arrowleft" />
                  <span className="label">Next: {nextVerbage}</span>
                </button>
            )
        }
    }
    showProgress(){
        const { current_step} = this.props.UserStore.currentUser
        var width =  (current_step+1) /this.screens.length *100
        var divStyle = {
            width:width+'%'
        };
        return(
                <Grid container spacing={0} className="gc gc-ProgressBar">
                  <Grid item xs={12} className="gi gi-ProgressBar-line">
                    <div className="line" style={divStyle}></div>
                  </Grid>
                </Grid>
        )
    }
    showScreen(){
        //switch on current screen possibly welcome back logic
        const { current_step, first_name} = this.props.UserStore.currentUser

        if ( window.analytics && this.props.UserStore.trackedLog.indexOf( this.screens[current_step] ) === -1 ) {
            this.props.UserStore.trackedLog.push( this.screens[current_step] );
            window.analytics.track( 'profile_create', {
                profile_step: this.screens[current_step],
                club_id: this.props.ClubDataStore.values.id,
                club_number: this.props.ClubDataStore.club_number,
                trainer_code: this.props.TrainerSyncStore.values.share_code
            });
        }

        window.scrollTo(0, 0);
        switch(this.screens[current_step]){
            case 'welcome_back':
                return (
                    <WelcomeBack goForward={this.goForward} first_name={ first_name} />
                )
            case 'equipment':
                return (<Equipment ident='equipment' />)
            case 'gender':
                return (<Gender ident='gender' />)
            case 'height':
                return (<Height ident='height' />)
            case 'weight':
                return (<Weight ident='weight' />)
            case 'club_selector_question':
                return (
                    <ClubSelectorQuestion skipGym={this.skipGym} selectGym={this.selectGym}/>
                )
            case 'club_amenities':
                return (
                    <ClubAmenities ident='club_amenities' />
                )
            case 'pullup':
                return (
                    <Pullup ident='pullup' />
                )
            case 'pushup':
                return (
                    <Pushup ident='pushup' />
                )
            case 'bdw_squat':
                return (
                    <BDWSquat ident='bdw_squat' />
                )
            case 'flexibility':
                return (
                    <Flexibility ident='flexibility' />
                )
            case 'endurance_stairs':
                return (
                    <Stairs ident='endurance_stairs' />
                )
            case 'single_leg_balance':
                return (
                    <SingleLegBalance ident='single_leg_balance' />
                )
            case 'club_group_classes':
                return (
                    <ClubGroupClasses ident='club_group_classes' />
                )
            case 'club_strength_equipment':
                return (
                    <ClubStrengthEquipment ident='club_strength_equipment' />
                )
            case 'club_cardio_equipment':
                return (
                    <ClubCardioEquipment ident='club_cardio_equipment' />
                )
            case 'non_gym_activities':
                return (
                    <NonGymActivities ident='non_gym_activities' />
                )
            case 'assessment_intro':
                return (
                    <AssessmentIntro goForward={this.goForward} />
                )
            case 'workout_style':
                return (
                    <WorkoutStyle ident='workout_style' />
                )
            case 'skip_reasons':
                return (
                    <SkipReasons ident='skip_reasons' />
                )
            case 'motivate_me':
                return (
                    <Motivate ident='motivate_me' />
                )
            case 'intermission':
                return (
                    <Intermission continueFunc={this.goForward} />
                )
            case 'frequency':
                return (
                    <Frequency ident='frequency' />
                )
            case 'overall_fitness':
                return (
                    <OverallFitness ident='overall_fitness' />
                )
            case 'intensity':
                return (
                    <Intensity ident='intensity' />
                )
            case 'goals':
                return (
                    <Goals ident='goals' />
                )
            case 'primary_goal':
                return (
                    <PrimaryGoal ident='primary_goal' />
                )
            case 'processing_animation':
                setTimeout(this.goForward,4000)
                return (
                    <ProcessingAnimation />
                )
            case 'success':
                return (
                    <Success />
                )
            default:
                return
        }
    }
    returningUserProgress(){
        this.props.UsersRoutingStore.setShowReturningProfile(false)
    }

    render() {
        //progressbar
        //leftbutton
        //rightbutton
        // catch for last step
        //////way need to refactor this
		  return (
				<div className="appProgressSteps">
				  {this.showModal()}
				  {this.showProgress()}
						<Grid container spacing={0} className="gc gc-Progress">
						  <Grid item xs={1} className="gi gi-Progress-Spacer"></Grid>
						  <Grid item xs={10} className="gi gi-Progress-Info">
						  {this.showScreen()}
						   </Grid>
		          <Grid item xs={1} className="gi gi-Progress-Spacer"></Grid>
					</Grid>
				  {this.showBack()}
				  {this.showForward()}
				</div>
		  );

    }
}

Profile = inject('CommonStore', 'UserStore', 'UsersRoutingStore','ClubDataStore', 'TrainerSyncStore','ReferralStore')(observer(Profile))
export default Profile
