import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

class SearchResultsHeader extends Component {
    timer = ''
    handleChange = (e) => {
        this.props.ChangeDayStore.searchTxt = e.target.value;
        if (this.timer){
            clearTimeout(this.timer);
        }
      //TODO - make this smarter
        this.timer = setTimeout( ()=>{
             this.props.ChangeDayStore.fetchResults()
        }, 1000)
    }
    handleClearClick = (e)=> {
        this.props.ChangeDayStore.searchTxt = '';
        this.props.ChangeDayStore.fetchResults()
    }

    render() {
        const {canSearchWorkouts, currentTask, searchResultsCount, searchTxt} = this.props.ChangeDayStore
        const count = ()=> {
				if (canSearchWorkouts && searchResultsCount === 0){
					return (
						<div className="resultsCount">No results found. Please update your selection criteria</div>
					)
				}else if (!canSearchWorkouts && searchResultsCount === 0 ){
					return (
						<div className="resultsCount">Please select a Location, Type, Time, and Theme</div>
					)
				}else{
					return (
						<div className="resultsCount"> Search Results Count : <strong>{searchResultsCount}</strong>  </div>
					)
				}
        }
        const search = ()=> {
            //add additional clear button
            if (canSearchWorkouts){
                return (
                    <div className="searchInput">
                        <TextField
                            key="0"
                            label=" "
                            onChange={this.handleChange}
                            className=""
                            placeholder="Search"
                            name="search"
                            value={searchTxt}
                            margin="normal"
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                <SearchIcon />
                                </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                )
            }
        }
        const replacementText = ()=> {
            if (currentTask.name){
                if (currentTask.id === 1679) return (<div className='replacementText'>You're replacing a Fit Appointment Workout</div> )
                if (currentTask.id === 632) return (<div className='replacementText'>You're replacing a PT Session</div> )
                if (currentTask.class_name === "GroupClass"){
                    return (<div className='replacementText'>You're replacing the class:  {currentTask.name}</div>)  
                }
                if (currentTask.id === 655) return(<div></div>)
                return (
                    <div className='replacementText'>You're replacing the workout: {currentTask.name}</div>  
                )
            }
        }
        return(
            <div>
            <div className="rightHeader" >
                {count()}
                {search()}
            </div>
                {replacementText()} 
            </div>
        );
    }
}

SearchResultsHeader = inject('ChangeDayStore')(observer(SearchResultsHeader))
export default SearchResultsHeader;
