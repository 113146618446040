
import { observable, action,  decorate } from 'mobx';

class VideoModalStore {
    //observables
    isOpen = false;
    section = 'nothing'
    video = ''

    //setSection
    openModal(){
        this.isOpen = true
    }
    closeModal(){
        this.isOpen = false 
    }
    setSection(section){
        this.section = section   
    }
    setVideo(video){
        this.section = 'video'   
        this.video = video   
    }
}

decorate(VideoModalStore, {
    openModal: action,
    closeModal: action,
    setSection: action,
    setVideo: action,
    isOpen: observable,
    video: observable
});

export default new VideoModalStore();
