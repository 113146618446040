import React from 'react';
import './VideoModal.scss'
import { observer , inject } from 'mobx-react';
import ReactPlayer from 'react-player'


class VideoItem extends React.Component {
  render() {
        const { video  } = this.props.VideoModalStore;
    return (
      <div className=''>	
          <div className="videoPreloader">
            <div className="appLoadingSpinner">
              <div className="loader"></div>
              <span className="loadingText"></span>
            </div>
          </div>
            <div className='videoItem'>
                <ReactPlayer url={video}  playing loop height="100%" width="100%"/>
            </div>
      </div>
    )
  }
}

VideoItem  = inject('VideoModalStore')(observer(VideoItem))
export default VideoItem


