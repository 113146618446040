import { observable, action, runInAction, decorate } from 'mobx';
import agent from '../../api/Agent';
//import cookie from 'js-cookie'


class TrainerSyncStore {

    values = {
        class_name: '',
        id:'' ,
        first_name: '',
        last_name: '',
        avatar: '',
        share_code: '' 
    }

    inProgress = false;
    clearValues(){
        this.values = {

            class_name: '',
            id:'' ,
            first_name: '',
            last_name: '',
            avatar: '',
            share_code: '' 
        }
    }
    /*
    {
        "class_name": "User",
        "id": 338,
        "first_name": "Micah",
        "last_name": "Nolte",
        "avatar": "https://storage.googleapis.com/assets.staging.24go.io/a7375d50-3be5-41db-9833-dad543ae556d/poster-4449.jpg",
        "share_code": "44b6b"
    }
    */

    setValue(key, value){
        if ( this.values[key] !== undefined ) {
            if (value !== null){
                this.values[key] = value
            }
        }
    }

    trainerLookup() {
        this.inProgress = true;
        const { share_code } = this.values 
        return agent.TrainerSync.lookup(share_code)
        .then( (trainer)  => {
            runInAction( () => {
                if (trainer.error){
                    alert(trainer.error)
                }
                if (trainer.id){
                    this.values = trainer 
                    //cookie.set('trainerSync', trainer)
                }
            })
          })
        .catch(action((err) => {
            //cookie.remove('trainerSync')
            alert('Trainer not found')
            throw err;
        }))
        .finally(action(() => { this.inProgress  = false; }))
    }
    trainerSync(share_code) {
        this.inProgress = true;
        var is_editor = true
        return agent.TrainerSync.sync(share_code, is_editor)
        .then( (trainer)  => {
            if (trainer.id){
                this.values = trainer 
                //cookie.set('trainerSync', trainer)
            }
         })
        .catch(action((err) => {
            //cookie.remove('trainerSync')
            alert('Trainer not found')
            throw err;
        }))
        .finally(action(() => { this.inProgress  = false; }))
    }
    trainerLink() {
        if (this.values.id){
            this.inProgress = true;
            const { share_code } = this.values 
            return agent.TrainerSync.link(share_code)
            .then( (trainer)  => {
                runInAction( () => {
                    if (trainer.id){
                        this.values = trainer 
                         
                        //cookie.set('trainerSync', trainer)
                    }
                })
              })
            .catch(action((err) => {
                //cookie.remove('trainerSync')
                alert('Trainer not found')
                throw err;
            }))
            .finally(action(() => { this.inProgress  = false; }))
        }else{
            return Promise.resolve()
        }
    }

    getSyncTrainer(){
        //var storedTrainerData = {};
        //TODO clearing all cookie calls for now
        /*
        try { storedTrainerData = JSON.parse( cookie.get('trainerSync') ); } catch (o_0) {}
            // Only use this data if we were able to get good info.
            if ( storedTrainerData.id ) {
                this.values = storedTrainerData ;
            }
        */
    }
}

decorate( TrainerSyncStore, {
    inProgress: observable,
    values: observable,
    trainerSync: action
});

export default new TrainerSyncStore();
