import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { withRouter } from 'react-router-dom';
//




class LoginForm extends Component {

    handleSubmitForm = (e) => {
        e.preventDefault();
        this.props.UserStore.loginCheckIn()
            .then(() => {
                const { class_name } = this.props.UserStore.currentUser;
                //TODO - possibly not do routing logic here
                //if member - go to confirm
                //otherwise go to common store
                //otherwise go to dashboard
                //TODO finish routing
                if (class_name === 'Member'){
                    this.props.history.replace('/users/confirmation');
                }else{
                    //dashboard
                    this.props.TrainerSyncStore.trainerLink()
                        .then( ()=> {
                            this.props.history.replace('/dashboard');
                        })
                }
            })
    };



    handleChange = (e) => {
        this.props.UserStore.setValue(e.target.name,e.target.value);
    }

    render() {
        const { checkin_code, year, day, month  } = this.props.UserStore.currentUser;
        //TODO make external function for this if ever used again...
        const fillRange = (start, end) => {
            return Array(end - start + 1).fill().map((item, index) => start + index);
        };

        const buttonItem = () => {
            const pathname = this.props.location.pathname
            if (pathname.indexOf("login") !== -1){
                return(
                 <button className="button-teal loginButton">Sign In with Member Info</button>
                )
            }else{
                return(
                 <button className="button-teal createButton">Register with Member Info</button>
                )
            }
        }

        const daysArray = fillRange(1, 31);
        const days = daysArray.map( (day, i ) => {
            return (
                <MenuItem
                    key={i}
                    value={day}
                 >{day}</MenuItem>
            );
        })
        const monthsArray = fillRange(0, 11);
        const months = monthsArray.map( (month, i) => {
            var momentInstance = Moment
            const monthName = momentInstance.months(i)
            return (
                <MenuItem
                    key={i}
                    value={i}
                 >{monthName}</MenuItem>
            )
        })
        var yearsArray = fillRange(1920, new Date().getFullYear() );
        yearsArray = yearsArray.reverse()
        const years = yearsArray.map( (year, i ) => {
            return (
                <MenuItem
                    className="year"
                    key={i} value={year}
                    >{year}
                </MenuItem>
            );
        })


        return(
            <ValidatorForm
                className="inputFormWrapper"
                ref="form"
                autoComplete="off"
                instantValidate = {false}
                onSubmit={this.handleSubmitForm}
                onError={errors => console.log(errors)}
            >
              <div className="inputInlineWrapper">
                <TextValidator
                    key="0"
                    label="Check-In Code"
                    onChange={this.handleChange}
                    className="checkin_code"
                    placeholder="eg: 1234567890"
                    id="checkin_codeField"
                    name="checkin_code"
                    value={checkin_code}
                    validators={['required']}
                    errorMessages={['this field is required']}
                    margin="normal"
                />
              </div>
                <div className="inputInlineWrapper">
                  <div className='birthday' >Date of Birth</div>

                  <div className="inputInlineWrapper">
                    <SelectValidator
                        name="month"
                        className="monthSel"
                        value={month}
                        onChange={this.handleChange}
                        InputProps={{
                            name: 'month',
                            id: 'month',
                        }}
                    >
                        {months}
                    </SelectValidator>
                  </div>
                  <div className="inputInlineWrapper">
                    <SelectValidator
                        name="day"
                        className="daySel"
                        value={day}
                        onChange={this.handleChange}
                        InputProps={{
                            name: 'day',
                            id: 'day',
                        }}
                    >
                        {days}
                    </SelectValidator>
                  </div>
                  <div className="inputInlineWrapper">
                    <SelectValidator
                        name="year"
                        className="yearSel"
                        value={year}
                        onChange={this.handleChange}
                        InputProps={{
                            name: 'year',
                            id: 'year',
                        }}
                    >
                        {years}
                    </SelectValidator>
                  </div>
                </div>
                {buttonItem()}
            </ValidatorForm>
        )

    }
}

LoginForm = inject('TrainerSyncStore', 'UserStore','UsersRoutingStore', 'CommonStore')(observer(LoginForm ))
LoginForm = withRouter(LoginForm)
export default LoginForm;
