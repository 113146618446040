import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from "react-dom";


export default function(InnerComponent) {
  class FetchOnScrollComponent extends React.Component {
    constructor(props) {
      super(props);
      this.onScroll = this.onScroll.bind(this);

    }

    componentDidMount() {
        this.n = ReactDOM.findDOMNode(this);
        //console.log(this.n.offsetTop);
        window.addEventListener('scroll', this.onScroll, true);
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.onScroll, true);
    }

    onScroll(e) {
        if (this.n.scrollTop + 1000 > this.n.scrollHeight){
            this.props.scrollFunction();
        }
    }

    render() {
      return <InnerComponent {...this.props} />;
    }
  }

  FetchOnScrollComponent.propTypes = {
    scrollFunction: PropTypes.func.isRequired,
  };

  return FetchOnScrollComponent;
}
