import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import Grid from '@material-ui/core/Grid';

class ReferralQuestion extends Component {
    constructor(props){
        super(props);
        this.getPass = this.getPass.bind(this)
        this.skipPass = this.skipPass.bind(this)
    }

    skipPass(){
        this.props.ReferralStore.closeModal()
    }

    getPass(){
        this.props.ReferralStore.section = "form"
    }

    render() {
        return (
            <div className="stepContent animate-fadeInDown progress-Info-Wrapper">
                <h3>Would you like a free guest pass to 24 Hour Fitness?</h3>
                <h4></h4>
                <div className='buttons'>
                <button
                    onClick= {this.getPass}
                    className="button-teal button-skewed">Yes</button>
                <button
                    onClick= {this.skipPass}
                    className="button-teal button-skewed">No Thanks</button>
                </div>
            </div>
        )
  }

}

ReferralQuestion= inject('ReferralStore')(observer(ReferralQuestion))
export default ReferralQuestion 
