import React, { Component } from 'react';
import { inject } from 'mobx-react';


class ModalPromptForGym extends Component {

    constructor(props){
        super(props);
        this.skipGymPressed = this.skipGymPressed.bind(this)
        this.selectGymPressed = this.selectGymPressed.bind(this)
    }
    skipGymPressed (e){
        this.props.PlanBuilderStore.promptForGymModalIsOpen = false
        this.props.PlanBuilderStore.hasSeenClubSelectorPrompt  = true
    }

    selectGymPressed(e){
        this.props.PlanBuilderStore.promptForGymModalIsOpen = false
        this.props.PlanBuilderStore.hasSeenClubSelectorPrompt  = true
        this.props.ClubDataStore.clubDataModalIsOpen = true
    }

    render() {
        return (
            <div className="appModal">
              <div className="modalWrapper">
                <div className="modalContent">
                  <h1 className="modalTitle">
                    To continue please tell us what 24 Hour Fitness gym you workout at
                  </h1>
                  <div className="modalSubTitle">If you do not want to proceed with a gym selection please select "No Thanks"</div>
                  <button
                      onClick= {this.selectGymPressed}
                      className="button-teal button-skewed">Select Club</button>
                  <button
                      onClick= {this.skipGymPressed}
                      className="button-blue button-skewed">No Thanks</button>
                </div>
              </div>
            </div>

        )
    }
}

ModalPromptForGym = inject('ClubDataStore','PlanBuilderStore')(ModalPromptForGym)
export default ModalPromptForGym
