import React, { Component } from 'react';
import {  inject } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { IconGoLogo, IconTarget, IconLevel, IconCalendar, IconLocationIos, IconClasses } from '../Icons/Icons';


class Home extends Component {
    goLogin(){
        this.props.history.replace('/users/login')
    }
    goCreate(){
        this.props.history.replace('/users/create')
    }
    constructor(props){
        super(props);
        this.goCreate = this.goCreate.bind(this)
        this.goLogin = this.goLogin.bind(this)
    }
    componentWillMount(){
        const isLoggedIn = this.props.UserStore.isLoggedIn
        if( isLoggedIn ){
            console.log("IS LOGGED IN!!!!!!!")
            this.props.history.replace('/dashboard');
        } else {
            this.props.UserStore.logout();
            this.props.TrainerSyncStore.clearValues();
            this.props.PlanBuilderStore.clearValues();

            if ( window.analytics ) window.analytics.track( 'view_fitplan_home', {
                club_id: this.props.ClubDataStore.values.id,
                club_number: this.props.ClubDataStore.club_number
            });
        }
    }

    render() {
        const version = () => {
            if (this.props.CommonStore.version.version ) {
                var versionNum = this.props.CommonStore.version.version
                return (
                   <span>{versionNum}</span>
                )
            }
        }

        const {id} = this.props.ClubDataStore.values
        return (

          <div>




          <div className="splash">


            <Grid container spacing={0} className="gc gc-Header gc-HeaderLanding">
              <Grid item xs={5} sm={3} md={2} className="gi gi-Header-Logo">
                  {/* <IconGoLogo nativeColor="#fff" className="icon icon-gologo" /> */}
                  <a className="long-24hf-logo" href="https://www.24hourfitness.com">
                    <img alt="24 hour fitness" src="/fitplan/imgs/24HF_Logo-FullColor_RGB.png" />
                  </a>
              </Grid>
              <Grid item xs={7} sm={9} md={10} className="gi gi-Header-Title">
                <p>GET A FREE FIT PLAN</p>
              </Grid>

            </Grid>

            <Grid alignItems="stretch" direction="row" container spacing={0} className="gc gc-FitPlanHero">
              <Grid item xs={12} className="gi gi-FitPlanHero-Gradient"></Grid>
              <Grid item md={7} xs={12} className="gi gi-FitPlanHero-Info">
                <h2>
                  <img alt="fit-plan" src="/fitplan/imgs/fitplan-logo-bubble.png" />
                </h2>
                <p>From losing weight to building muscle, learn how to achieve the results you want with our FREE personalized Fit Plan. Access your plan anytime, anywhere online or with our free 24GO&reg; app.</p>
                <video autoPlay className="promoVideo" loop muted>
                  <source src="https://static.24go.co/files/video/fitplan_final_optimized.mp4" type="video/mp4" />
                </video>
              </Grid>
              <Grid item md={5} xs={12} className="gi gi-FitPlanHero-CTA">
                {/* <h2>Get Started Now</h2> */}
                <p>
                  <button id="register-button" onClick={this.goCreate} type="button" className="btn-primary">Get Started</button>
                </p>
                <p>
                  Have a 24GO® account? <a id="sign-in-button" href="#" onClick={this.goLogin}>Sign in</a>
                </p>
              </Grid>
            </Grid>

            <Grid container spacing={0} justify="center" className="gc gc-FitPlanBenefits">
              <Grid item xs={12} className="gi gi-FitPlanBenefits-title">
                <div className="topCircle"><span></span></div>
                <h2>Fully personalized just for you.</h2>
                <p>We'll build you a personalized plan based on your:</p>
              </Grid>
              <Grid item md={2} sm={3} xs={6} className="gi gi-FitPlanBenefits-feature">
                <IconTarget nativeColor="#fff" className="icon icon-target" />
                <h4>Goal</h4>
              </Grid>
              <Grid item md={2} sm={3} xs={6} className="gi gi-FitPlanBenefits-feature">
                <IconLevel nativeColor="#fff" className="icon icon-level" />
                <h4>Fitness Level</h4>
              </Grid>
              <Grid item md={2} sm={3} xs={6} className="gi gi-FitPlanBenefits-feature">
                <IconCalendar nativeColor="#fff" className="icon icon-calendar" />
                <h4>Schedule</h4>
              </Grid>
              <Grid item md={2} sm={3} xs={6} className="gi gi-FitPlanBenefits-feature">
                <IconLocationIos nativeColor="#fff" className="icon icon-location-ios" />
                <h4>Location</h4>
              </Grid>
              <Hidden smDown>
              <Grid item md={2} sm={3} xs={12} className="gi gi-FitPlanBenefits-feature">
                <IconClasses nativeColor="#fff" className="icon icon-classes" />
                <h4>GX24&reg; Classes </h4>
              </Grid>
              </Hidden>
            </Grid>

            <Grid container spacing={0} className="gc gc-FitPlanMoreBenefits">
              <div className="topCircle"><span></span></div>
              <Hidden smDown>
                <Grid item md={6} className="gi gi-FitPlanMoreBenefits-phones">
                  <img alt="phones" src="/fitplan/imgs/phones.png" />
                </Grid>
              </Hidden>
              <Grid item md={6} className="gi gi-FitPlanMoreBenefits-info">
                <h2>Join over 23,000 people using our Fit Plan to maximize their results</h2>
                <p><button onClick={this.goCreate} type="button" className="btn-secondary-white">Get Started</button></p>
                <ul>
                  <li>7 & 28-day digital plans based on your goals, schedule and interests</li>
                  <li>Created by our team of kinesiologists, personal trainers and fitness experts</li>
                  <li>Huge variety of workouts for the gym, home or anywhere</li>
                </ul>
              </Grid>
            </Grid>

            <Grid container spacing={0} className="gc gc-TeamSection">
              <Grid item xs={12} className="gi gi-TeamSectionInfo">
                <h2>We've Got Your Back</h2>
                <p>Our team of top kinesiologists, personal trainers and fitness experts (along with a few computer geniuses) have come together to create the algorithms, the workouts and the accountability to build a personalized Fit Plan - just for you. Want help getting started? Set up a free fitness appointment today at a <a href="https://www.24hourfitness.com/health_clubs/find-a-gym/">24 Hour Fitness club near you</a>.</p>
                <div className="team">
                  <div className="person">
                    <img alt="kines" src="/fitplan/imgs/profiles/kines.png" />
                    <h3>Kinesiologists</h3>
                  </div>
                  <div className="person">
                    <img alt="trainers" src="/fitplan/imgs/profiles/trainers.png" />
                    <h3>Personal Trainers</h3>
                  </div>
                  <div className="person">
                    <img alt="professionals" src="/fitplan/imgs/profiles/professionals.png" />
                    <h3>Fitness Experts</h3>
                  </div>
                </div>
                {/*
                <div className="divider"></div>
                <h2>Want help getting started?</h2>
                <p>Set up a free fitness appointment today at a 24 Hour Fitness club near you.</p>
                <a href="https://www.24hourfitness.com/health_clubs/find-a-gym/" className="btn-secondary-white">Find A Club</a>
                */}
              </Grid>

            </Grid>

            <div className="versionNumber">
              <p>Fit Plan Version: <strong>{version()} - {id}</strong></p>
            </div>


          </div>






            </div>
        );
  }
}

Home = inject('UserStore','UsersRoutingStore','ClubDataStore','TrainerSyncStore','PlanBuilderStore','CommonStore')(Home)
export default Home;
//<Link to="users/trainer_login" className="trainerLogin">Trainer Login</Link>
