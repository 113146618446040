import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import {  IconClose } from '../Icons/Icons';
import ReferralQuestion from './ReferralQuestion';
import ReferralForm from './ReferralForm';
import ReferralConfirmation from './ReferralConfirmation';
import Grid from '@material-ui/core/Grid';
import './ModalReferral.scss'
class ReferralModal extends Component {

    constructor(props){
        super(props);
        this.closeModal = this.closeModal.bind(this)
    }
    closeModal(){
        this.props.ReferralStore.closeModal()
    }
    getSection(){
        const {section} = this.props.ReferralStore
        switch (section) {
            case "question": 
                return (
                    <ReferralQuestion />
                )
                break
            case 'form':
                return (
                    <ReferralForm />
                )
                break;
            case 'confirmation':
                return (
                    <ReferralConfirmation />
                )
                break;
        }
    }


    render() {
        //todo loading overlay 
        return (

            <div className="previewDayModal appFullModal animate-fadeIn" >
                <div className="modalHeader animate-fadeIn animate-delay-0-5" onClick= {this.closeModal}>
                    <span className="leftSide">
                    <strong>Free Guest Pass</strong>
                    </span>
                    <button className="rightSide" >
                    Done <IconClose nativeColor="#fff" id="icon" className="icon icon-close" />
                    </button>
                </div>

                <div className="modalWrapper animate-slideUp">
                    <div className="modalContent">
						<Grid container spacing={0} className="refModal">
						  <Grid item xs={1} className=""></Grid>
						  <Grid item xs={10} className="">
                            {this.getSection()}
						   </Grid>
                          <Grid item xs={1} className=""></Grid>
					</Grid>
                    </div>
                </div>
            </div>
        )
    }
}

ReferralModal = inject('ReferralStore')(observer(ReferralModal ))
export default ReferralModal
