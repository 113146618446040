import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { Hidden } from '@material-ui/core';

class AllPlans extends Component {

    constructor(props){
        super(props);
        this.quitPlan= this.quitPlan.bind(this)
    }
    componentWillMount(){
        this.props.CurrentPlanStore.getAllPlans()
    }
    quitPlan(e){
        e.preventDefault();
        e.stopPropagation();
        //this.props.CurrentPlanStore.quitCurrentPlan(e.currentTarget.value)

        this.props.ModalStore.setSection('quitPlan')
        this.props.ModalStore.setCloseVisible()
        this.props.ModalStore.openModal()
    }
    render(){
        const {plans, inProgress} = this.props.CurrentPlanStore
        var momentInstance = Moment

        const quitButton = (plan)=> {
            if (plan.is_active){
                return (

                    <button
                        value={plan.id}
                        onClick={this.quitPlan}
                        className="button-pill"
                >
                        Quit
                    </button>
                )
            }
        }
        const activeLabel = (plan)=> {
            if (plan.is_active){
                return (
                    <span className="activePlan"> Active </span>
                )
            }
        }
        const planDisplayName = (plan)=> {
            if ( plan.custom_name ) return plan.custom_name;
            if ( plan.plan && plan.plan.name ) return plan.plan.name;
            return 'Fitness Plan';
        }
        const getPlans = ()=> {
            var planItems = []
            if (plans.length > 0){
                    plans.forEach( ( plan, index ) => {
                        var start_date = momentInstance(plan.start_int, "x").format("YYYY-MM-DD")
                        var end_date = 'n/a'
                        if (plan.completed_int){
                            end_date = momentInstance(plan.completed_int, "x").format("YYYY-MM-DD")
                        }
                        planItems.push(
                            <tr key={index}>
                              <td>{ planDisplayName( plan ) }
                                {activeLabel(plan)}
                                </td>
                              <td>{start_date}</td>
                              <td>{end_date}</td>
                              <td>{plan.total_workouts}</td>
                              <td>{plan.completed_workouts}</td>
                              <td className="lastColumn">
                                {quitButton(plan)}
                              </td>
                            </tr>
                        )
                    })
            }else{
                return (
                    <tr>
                        <td>No Plans</td>
                    </tr>
                )
            }
            return planItems
        }

        const showCreatePlan = ()=> {
            var showCreate = true
            if (plans.length > 0){
                    plans.forEach( ( plan, index ) => {
                        if (plan.is_active){
                            showCreate = false
                        }
                    })
            }
            if (showCreate){
                return (
                    <Link to="/plans" className="tableLink">Create New Plan</Link>
                )

            }
        }

        if (!inProgress) {
            return (
                <div className="appTableData appTableMyPlans plans">
                  <div className="tableHeader">
                    <h2>My Plans</h2>
                    {showCreatePlan()}
                  </div>
                  <table className="tableData">
                    <tbody>
                    <Hidden smDown>
                        <tr>
                            <th>Plan Name</th>
                            <th>Started</th>
                            <th>Ended</th>
                            <th>Workouts</th>
                            <th>Completed</th>
                            <th className="lastColumn"></th>
                        </tr>
                    </Hidden>
                    {getPlans()}
                    </tbody>
                  </table>
                </div>
            )
        }else{
            return (
                <div>  </div>
            )
        }
    }
}
AllPlans = inject('ModalStore', 'CurrentPlanStore')(observer(AllPlans))
export default AllPlans
