import cookie from 'js-cookie'

class CookieID {
    identifyMe(){
        var ID = cookie.get('fp_24_kiosk_id')
        if (!ID) {
            ID = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 10; i++){
                ID += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            cookie.set('fp_24_kiosk_id', ID, {expires:1500})
        }
    }
}

export default new CookieID();
