import React, { Component } from 'react';
import MapGL, {Marker, Popup} from 'react-map-gl';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import './GymMap.scss';
import Geocoder from 'react-map-gl-geocoder'
import { inject, observer } from 'mobx-react';
import CityPin from './CityPin';
import LocationPin from './Location-pin';
import { withStyles, Button, Hidden } from '@material-ui/core';
import NearMeIcon from '@material-ui/icons/NearMeOutlined';

const styles = theme => ({
    fullSize: {
        height: '100%',
        width: '100%',
    },
    currentLocationButton: {
        backgroundColor: '#3cb6ce !important',
        borderRadius: '50%',
        bottom: 10,
        color: 'white',
        height: '3rem',
        minWidth: 'unset',
        position: 'absolute',
        right: 10,
        width: '3rem'
    },
    nearIcon: {
        fontSize: '2.0rem',
    },
    popupClass: {
        backgroundColor: 'white',
    },
    searchBox: {
        margin: '10px 0 0 10px',
        
    },
});

class GymMap extends Component {
    divElement = ''
    mapRef = React.createRef()
    
    componentDidMount() {
        window.addEventListener("resize", this.resize);
        window.addEventListener("orientationchange", this.onOrientationChangeHanlder);
        this.resize();
        //TODO - check if enough data is present to be here, if not, bail

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    isInputSelected = () => {
        var activeElement = document.activeElement;
        var inputs = ['input', 'select', 'textarea'];
        return (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1);
        
    }

    onOrientationChangeHanlder = () => {
        if (this.isInputSelected()) {
            document.activeElement.blur();
        }
    }

    isMobile() {
        return ( navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i));            
    }

    resize = () => {
        console.log('resize event')
        this.handleViewportChange({
            width: '100%',
            height: '100%'
        });
    };

    handleViewportChange = new_viewport => {
        // don't refresh the viewport in mobile devices when the keyboard is opened
        if (!new_viewport.search && this.isMobile() && this.isInputSelected()){
            return;
        }
        var {viewport} = this.props.GymFinderStore;
        this.props.GymFinderStore.viewport = { ...viewport, ...new_viewport }
        this.props.GymFinderStore.popupInfo = null;
                
        if (this.mapRef.current && this.mapRef.current.getMap()) {
            var map = this.mapRef.current.getMap().getBounds();
            this.props.GymListingsStore.getGymsOnMapBoundaries(map._ne, map._sw);
        }
    };
    
    handleGeocoderViewportChange = viewport => {
        console.log('handleGeocoderViewportChange ')
        const geocoderDefaultOverrides = { transitionDuration: 1000 };
        return this.handleViewportChange({
            ...viewport,
            ...geocoderDefaultOverrides,
            // Tell the viewport hanlder to reset the viewport even if is a mobile device
            search: true
        });
    };

    handleOnResult = event => {
        console.log('handleOnResult')
        this.props.GymFinderStore.locationUpdated(event.result)
    };

    renderCityMarker = (city, index) => {
        let selected = this.props.GymListingsStore.highlightedClubId === city.id;
        return (
            <Marker 
                key={`marker-${index}`}
                longitude={city.address_longitude}
                latitude={city.address_latitude} >
                <CityPin 
                    city={city}
                    index={index + 1}
                    size={30} 
                    fontSize={'10px'}
                    selected={selected}
                    onClick={() => this.selectCityOnMap({city})} 
                />
            </Marker>
        );
    }
    selectCityOnMap(city){
        if (this.props.GymListingsStore.highlightedClubId === city.city.id) {
            this.props.GymFinderStore.popupInfo = null;
            this.props.GymListingsStore.highlightedClubId = null;
        } else {
            this.props.GymFinderStore.popupInfo = city.city;
            this.props.GymListingsStore.highlightedClubId = city.city.id;
        }
    }
    renderCurrentLocation(){
        const {mapLocation} = this.props.GymFinderStore;
        if (mapLocation.lat && mapLocation.lon){
            return (
                <Marker 
                    key='currentLocation'
                    longitude={mapLocation.lon}
                    latitude={mapLocation.lat} >
                    <LocationPin 
                        size={20} 
                    />
                </Marker>
            )
        }
    }
    
    renderPopup() {
        const {classes} = this.props;
        const {popupInfo} = this.props.GymFinderStore;
        return popupInfo && (
            <Popup tipSize={5}
                className={classes.popupClass}
                anchor="top"
                longitude={popupInfo.address_longitude}
                latitude={popupInfo.address_latitude}
                closeOnClick={true}
                onClose={() => this.props.GymFinderStore.popupInfo = null} >
                <div> {popupInfo.name} </div>
                <div> {popupInfo.full_address} </div>
            </Popup>
        );
    }
    render(){
        const {classes} = this.props;
        const {gyms} = this.props.GymListingsStore
        const {MAPBOX_TOKEN, findCurrentLocation, viewport} = this.props.GymFinderStore

        return(
            <div className={classes.fullSize} ref={ (divElement) => this.divElement = divElement}>
            <MapGL
                ref={this.mapRef}
                {...viewport}
                onViewportChange={this.handleViewportChange}
                mapboxApiAccessToken={MAPBOX_TOKEN}
                mapStyle="mapbox://styles/mapbox/streets-v10"
            >
                { gyms.map(this.renderCityMarker) }
                {!this.isMobile() ? this.renderPopup() : undefined}
                {this.renderCurrentLocation()}
                <Geocoder
                    bbox={[-164.267578,15.029686,-66.708984,49.724479]}
                    mapRef={this.mapRef}
                    onResult={this.handleOnResult}
                    onViewportChange={this.handleGeocoderViewportChange}
                    mapboxApiAccessToken={MAPBOX_TOKEN}
                    position="top-left"
                    className={classes.searchBox}
                    countries={'US'}
                />
                <Hidden smUp>
                    <Button className={classes.currentLocationButton} onClick={findCurrentLocation}><NearMeIcon className={classes.nearIcon}></NearMeIcon></Button>
                </Hidden>
            </MapGL>
            </div>
        )
    }
}
GymMap = inject('GymFinderStore', 'GymListingsStore')(observer(GymMap))
export default withStyles(styles)(GymMap);