import { observable, action,  decorate } from 'mobx';


class PreviewModalStore {
    planItem = {
      tasks: []
    }
    isOpen = false;
    openModal(){
        this.isOpen = true
    }
    closeModal(){
        this.isOpen = false 
    }
}

decorate(PreviewModalStore , {
    planItem: observable,
    isOpen: observable,
    openModal: action,
    closeModal: action,
})

export default new PreviewModalStore();
