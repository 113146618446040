import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';


class SkipReasons extends Component {
	
	radioSelected = undefined		

    data = [
        {
            "name": "not_motivated",
            "label": "I'm not motivated"
        },
        {
            "name": "schedule_changes",
            "label": "My schedule changes"
        },
        {
            "name": "low_on_energy",
            "label": "I'm low on energy"
        },
        {
            "name": "dont_know",
            "label": "I don't know what to do"
        },
        {
            "name": "dont_skip",
            "label": "I don't skip workouts"
        }
    ]

    handleToggle = e => (e) => {
        const {ident} = this.props
        if (e.target.value){
            this.radioSelected = Number(e.target.value);
        }
        if (e.currentTarget.getAttribute('value')) {
            this.radioSelected = Number(e.currentTarget.getAttribute('value'));
        }
        this.props.UserStore.setValue(ident,this.data[this.radioSelected].name)
    };


    render(){
        const {ident} = this.props
        var selected = undefined
        if (this.props.UserStore.currentUser[ident]){
            selected = this.props.UserStore.currentUser[ident] 
        }
        if (selected){
            this.data.forEach( (value, index) => {
                if (selected === value.name){
                    this.radioSelected = index
                }
            })
        }
		const radios = ()=> { 
			var components = [] 
			this.data.forEach( (value, index)=> {
				components.push( 

                    <ListItem
                            value= {index}
                        key={index}
                        role={undefined}
                        dense
                        className="listItem"
                        disableGutters={true}
                        button
                        divider={true}
                        onClick={this.handleToggle(index)}
                    >
                        <FormControlLabel 
                            value= {index}
                            checked={index === this.radioSelected} 
                            control={<Radio color='primary' />} 
                            label={this.data[index].label} />
                    </ListItem>
 				)
			})	
			return components
		}

        return (
            <div className="stepContent animate-fadeInDown">
              <h1>I usually skip workouts when</h1>
              <h2>Optional sub instruction text can go here.</h2>

                <List 
                    dense={true}
                    disablePadding={true}
                >
						{radios()}	
                </List> 
            </div>
        )
    }
}

SkipReasons = inject('UserStore')(observer(SkipReasons ))    
export default SkipReasons;
