import React, { Component } from 'react';
import './Mock.scss';

class Mock extends Component {

    render(){
      return (


        <div>




          {/* inline styling

          <div style={pStyle}></div>

          */}




          {/* tempalte

          <Grid container spacing={0} className="gc gc-xxx">
            <Grid item xs={1} className="gi gi-xxx"></Grid>
          </Grid>

          */}






            {/* header with profile included

            <Grid container spacing={0} className="gc gc-Header gc-HeaderAuth">
              <Grid item xs={1} className="gi gi-Header-Profile">
                <a href="#">
                  <IconProfile nativeColor="#fff" className="icon icon-profile" />
                </a>
              </Grid>
              <Grid item xs={10} className="gi gi-Header-Logo">
                  <IconGoLogo nativeColor="#fff" className="icon icon-gologo" />
              </Grid>
              <Grid item xs={1} className="gi gi-Header-Logout">
                <a href="#">
                  <IconDoor nativeColor="#fff" className="icon icon-logout" />
                </a>
              </Grid>
            </Grid>

            */}



            {/* generic background
            <Grid container spacing={0} className="gc gc-Background">
              <Grid item xs={12} className="gi gi-BackgroundGeneric">
                <IconGoLogo nativeColor="#fff" className="icon icon-gologo" />
              </Grid>
            </Grid>

            */}


            {/* header without profile nav

            <Grid container spacing={0} className="gc gc-Header gc-HeaderAuth">
              <Grid item xs={1} className="gi gi-Header-Profile"></Grid>
              <Grid item xs={10} className="gi gi-Header-Logo">
                  <IconGoLogo nativeColor="#fff" className="icon icon-gologo" />
              </Grid>
              <Grid item xs={1} className="gi gi-Header-Logout">
                <button href="#">
                  <IconDoor nativeColor="#fff" className="icon icon-logout" />
                </button>
              </Grid>
            </Grid>

             */}


            {/* profile/assesment question

            <Grid container spacing={0} className="gc gc-ProgressBar">
              <Grid item xs={12} className="gi gi-ProgressBar-line">
                <div className="line"></div>
              </Grid>
            </Grid>

            <Grid container spacing={0} className="gc gc-Progress">
              <Grid item xs={1} sm={2} className="gi gi-Progress-Spacer"></Grid>
              <Grid item xs={10} sm={8} className="gi gi-Progress-Info">


                <div className="progress-Info-Wrapper">
                  <h3>How tall are you?</h3>
                  <h4>This is some random shit talking about other random stuff ho man crazy town.</h4>
                  <p>
                    <input type="text" />
                  </p>
                </div>


                <div className="progress-Info-Wrapper">
                  <h3>What types of group classes, if any, are you interested in?</h3>
                  <h4>Select all that apply</h4>

                  <Grid container spacing={24} justify="center" className="gc gc-ClassType">

                    <Grid item xs={12} sm={6} md={4} className="gi">
                      <div className="gi-ClassType-Card">
                        <IconWarrior nativeColor="#000" className="icon icon-warrior" />
                        <h5>Dance Classes</h5>
                        <p>Zumba, U-Jam Fitness, Cardio Dance Party</p>
                        <input type="checkbox" />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className="gi">
                      <div className="gi-ClassType-Card gi-ClassType-Card-Selected">
                        <IconMartialArts nativeColor="#000" className="icon icon-martialarts" />
                        <h5>Combat Classes</h5>
                        <p>BODYCOMBAT, Turbo Kick LIVE, STRONG by Zumba, Kickboxing</p>
                        <input type="checkbox" checked />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className="gi">
                      <div className="gi-ClassType-Card">
                        <IconCore nativeColor="#000" className="icon icon-core" />
                        <h5>Yoga Classes</h5>
                        <p>Yoga, BODYFLOW, Yoga-lates, SilverSneakers Yoga</p>
                        <input type="checkbox" />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className="gi">
                      <div className="gi-ClassType-Card">
                        <IconMuscle nativeColor="#000" className="icon icon-muscle" />
                        <h5>Strength Classes</h5>
                        <p>BODYPUMP, Strength Training, LES MILLS GRIT</p>
                        <input type="checkbox" />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className="gi">
                      <div className="gi-ClassType-Card">
                        <IconHeartRate nativeColor="#000" className="icon icon-heartrate" />
                        <h5>Step Classes</h5>
                        <p>Cardio Step, Core Cardio & More, BODYATTACK</p>
                        <input type="checkbox" />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className="gi">
                      <div className="gi-ClassType-Card">
                        <IconCycle nativeColor="#000" className="icon icon-cycle" />
                        <h5>Cycling Classes</h5>
                        <p>RPM, Les Mills Sprint, Cycle</p>
                        <input type="checkbox" />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className="gi">
                      <div className="gi-ClassType-Card">
                        <IconPool nativeColor="#000" className="icon icon-pool" />
                        <h5>Water Aerobics Classes</h5>
                        <p>Aqua, Aqua Zumba, Aqua Bootcamp</p>
                        <input type="checkbox" />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className="gi">
                      <div className="gi-ClassType-Card">
                        <IconTpose nativeColor="#000" className="icon icon-tpose" />
                        <h5>Active Aging Classes</h5>
                        <p>Silver Sneakers Classes, Silver Sneakers Yoga, Senior Fitness, Zumba Gold</p>
                        <input type="checkbox" />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className="gi">
                      <div className="gi-ClassType-Card">
                        <IconStretch nativeColor="#000" className="icon icon-stretch" />
                        <h5>Pilates & Barre Classes</h5>
                        <p>POP Pilates, Barre, PiYo LIVE, Pilates, bootybarre</p>
                        <input type="checkbox" />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className="gi">
                      <div className="gi-ClassType-Card">
                        <IconJumpRope nativeColor="#000" className="icon icon-jumprope" />
                        <h5>Athletic Training Classes</h5>
                        <p>Bootcamp, ATC (Athletic Training Club), INSANITY LIVE, HIIT</p>
                        <input type="checkbox" />
                      </div>
                    </Grid>

                  </Grid>

                </div>

              </Grid>
              <Grid item xs={1} sm={2} className="gi gi-Progress-Spacer"></Grid>
            </Grid>

            */}

            {/* not worth doing these in Grid, tried different ways

            <button href="#" className="progressBtn progressBtnPrev">
              <IconArrowLeft nativeColor="#999" className="icon icon-arrowleft" />
            </button>

            <button href="#" className="progressBtn progressBtnNext">
              <IconArrowLeft nativeColor="#fff" className="icon icon-arrowleft" />
              <span className="label"></span>
            </button>

             */}

        </div>

      )
    }
}

export default Mock
