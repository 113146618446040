import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withRouter } from 'react-router-dom';
//




class LoginFormEmail extends Component {

    handleChange = (e) => {
        this.props.UserStore.setValue(e.target.name,e.target.value);
    }
    handleSubmitForm = (e) => {
        e.preventDefault();
        this.props.UserStore.login()
            .then(() => {
                const { class_name } = this.props.UserStore.currentUser;
                if (class_name === 'Member'){
                    this.props.history.replace('/users/confirmation');
                }else{
                    //dashboard
                    this.props.TrainerSyncStore.trainerLink()
                        .then( ()=> {
                            this.props.history.replace('/dashboard');
                        })
                }
            })
    }
    render() {
        const { email, password  } = this.props.UserStore.currentUser;
        return (

            <ValidatorForm
                className=""
                ref="form"
                autoComplete="off"
                instantValidate = {false}
                onSubmit={this.handleSubmitForm}
                onError={errors => console.log(errors)}
            >
              <div className="inputInlineWrapper">
                <TextValidator
                    key="0"
                    label="Email"
                    onChange={this.handleChange}
                    className="email"
                    name="email"
                    id="emailField"
                    value={email}
                    validators={['required', 'isEmail']}
                    errorMessages={['This field is required', 'email is not valid']}
                    placeholder="eg: name@domain.com"
                    margin="normal"
                />
              </div>
              <div className="inputInlineWrapper">
                <TextValidator
                    key="1"
                    label="Password"
                    onChange={this.handleChange}
                    className=""
                    placeholder=""
                    name="password"
                    id="passwordField"
                    type="password"
                    value={password}
                    validators={['required']}
                    errorMessages={['this field is required']}
                    margin="normal"
                />
              </div>
              <Link to="/users/reset_password" className="subLink resetPassword">Reset my password</Link>
             <button id="sign-in-button" className="button-teal loginButton">Sign In with Email</button>
            </ValidatorForm>
        )

    }

}
LoginFormEmail = inject('TrainerSyncStore', 'UserStore','UsersRoutingStore', 'CommonStore')(observer(LoginFormEmail  ))
LoginFormEmail  = withRouter(LoginFormEmail )
export default LoginFormEmail ;
