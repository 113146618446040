import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

class ResetPassword extends Component {

    handleSubmitForm = (e) => {
        e.preventDefault();
        this.props.UserStore.resetPassword();
        this.props.history.replace('/users/login');
    }

    handleChange = (e) => {
        this.props.UserStore.setValue(e.target.name,e.target.value);
    }

    render() {
        const { email } = this.props.UserStore.currentUser;
        return (
            <div className="login">
                <div className="appUsers">
                    <h1>Reset Password</h1>
                    <h2>We'll email you a link to reset your password.</h2>
            <ValidatorForm
                className=""
                ref="form"
                autoComplete="off"
                instantValidate = {false}
                onSubmit={this.handleSubmitForm}
                onError={errors => console.log(errors)}
            >
              <div className="inputInlineWrapper">
                <TextValidator
                    key="0"
                    label="Email"
                    onChange={this.handleChange}
                    className="email"
                    name="email"
                    value={email}
                    validators={['required', 'isEmail']}
                    errorMessages={['This field is required', 'email is not valid']}
                    placeholder="eg: name@domain.com"
                    margin="normal"
                />
              </div>
             <button className="button-teal loginButton">Reset Password</button>

             <Link to="/users/login" className="subLink">Return to Login</Link>
            </ValidatorForm>
            </div>
            </div>
        );
  }



}
ResetPassword = inject( 'UserStore')(observer(ResetPassword))
ResetPassword = withRouter(ResetPassword)
export default ResetPassword
