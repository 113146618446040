import React, { Component } from 'react';
import Moment from 'moment';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';

class RestDay extends Component {
    render(){
        const { index, day, showChange, styles, replaceActive  } = this.props
        var momentInstance = Moment
        var replaceButton = () => {
            if (replaceActive){
                return (
                    <IconButton
                        onClick={showChange}
                        className="restDayAdd"
                        value={index}
                        aria-label="Delete">
                        <AddCircleOutline />
                    </IconButton>
                )
            }
        }
       var momentDay= momentInstance(day.day_str)
        return (
            <div key={index} className={styles} >
                <div className="dayNumber">{momentDay.format('DD').replace(/^0+/, '')}</div>
                <div className="dayName">{momentDay.format('ddd')}</div>
                <div className="dayTheme">Active Recovery / Rest</div>
                <div className='addTask dayButton edit'>
                {replaceButton()}
                </div>
            </div>
        )
    }
}
export default RestDay;
