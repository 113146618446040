import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import './GymListings.scss';
import classNames from 'classnames/bind';

import Button from '@material-ui/core/Button';
import { withStyles, Hidden, withWidth } from '@material-ui/core';

const styles = theme => ({
    locationContainer: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    gymListings: {
        padding: '0 5%',
    },
    gymLocation: {
        padding: '15px 0',
        borderTop: 'solid 1px rgba(0, 0, 0, 0.1)',
    },
    gymImageContainer: {
        position: 'relative',
        height: '90px',
        minWidth: '135px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > button ': {
            height: 'fit-content',
            fontSize: '0.9rem',
            color: 'white',
            backgroundColor: '#3cb6ce',
            borderRadius: 'unset',
            padding: '3px 9px',
        },
        '& > button > span:first-child': {
            height: '100%',
        }
    },
    gymImage: {
        height: '90px',
        width: '135px',
        position: 'absolute',
        top: 0,
        left: 0,
        '& > img': {
            height: '100%',
            width: '100%',
        },
    },
    locationInfo: {
        marginLeft: '15px',
        color: '#37424a',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
        },
    },
    locationName: {
        fontSize: '1.1rem',
        [theme.breakpoints.down('sm')]: {
            display: 'inline-flex',
            fontSize: '1.2rem',
        },
    },
    locationAddress: {
        fontSize: '0.75rem',
        opacity: '0.57',
        marginTop: '5px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
        },
    },
    locationDistance: {
        fontSize: '0.8rem',
        marginTop: '5px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        }
    },
    claimButton: {
        height: 'fit-content',
        fontSize: '0.9rem',
        color: 'white',
        backgroundColor: '#3cb6ce',
        borderRadius: 'unset',
        padding: '3px 9px',
        marginTop: '10px',
        '& > span:first-child': {
            height: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '1px 15px',
            marginTop: 'unset',
        },
    },
    circularNumber: {
        backgroundColor: '#37424a',
        color: 'white',
        fontSize: '0.9rem',
        height: '1.5rem',
        width: '1.5rem',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: '50%',
        marginRight: '5px',
        fontWeight: 'bold',
    },
    squaredNumber: {
        backgroundColor: 'rgba(151, 151, 151, 0.2)',
        display: 'inline-block',
        fontSize: '1rem',
        fontWeight: 'bold',
        marginRight: '5px',
        minWidth: '1.3rem',
        padding: '0.20rem',
        textAlign: 'center',
    },
    listingHeader: {
        fontSize: '1.2rem',
        padding: '10px 0',
        color: 'rgba(97, 99, 101, 0.56)',
    },
});

class GymListings extends Component {

    listingClick(gym) {
        //Gym Finder Store update viewport
        if (this.props.GymListingsStore.highlightedClubId !== gym.id) {
            this.props.GymFinderStore.popupInfo = gym;
            this.props.GymListingsStore.highlightedClubId = gym.id;
            var {viewport} = this.props.GymFinderStore;
    
            var lon = gym.address_longitude;
            var lat = gym.address_latitude;
            var new_viewport = {
                latitude: lat,
                longitude: lon
            }
            this.props.GymFinderStore.viewport = { ...viewport, ...new_viewport }
        } else {
            this.props.GymFinderStore.popupInfo = null;
            this.props.GymListingsStore.highlightedClubId = null;
        }
    }
    claimClick(gym){
       const {claimOnClick} = this.props;
       claimOnClick(gym);
    }

    focusElement(elementId) {
        const element = document.getElementById(elementId);
        const parent = document.getElementById('gym-listing');
        if (element && parent) {
            const bounding = element.getBoundingClientRect();
            if (!(bounding.top >= parent.offsetTop &&
                bounding.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + 10))) {
                element.scrollIntoView({behavior: "smooth"});
            }
        }
    }

    render(){
        const {classes} = this.props;
        console.log('Listings RENDER')
        const {gyms, highlightedClubId } = this.props.GymListingsStore

        const getGyms = ()=> {
            var gymItems = []
            if (gyms.length > 0){
                gyms.forEach( ( gym, index ) => {
                    var highlighted = (gym.id  === highlightedClubId)
                    var highlightClass = classNames({
                        'listingHighlighted': highlighted
                    }, classes.gymLocation);
                    var gymImgUrl = "http://www.24hourfitness.com/images/clubs/club_slideshows/"+gym.club_number.replace(/^0+/, '') + "/album1/large/image1.jpg"
                    if (highlighted) {
                        this.focusElement('gym-' + gym.id);
                    }
                    gymItems.push(
                        <div className={highlightClass} key={index} id={'gym-' + gym.id}>
                            <div
                                onClick={this.listingClick.bind(this, gym)}
                                className={classes.locationContainer}
                            >
                                <Hidden smDown>
                                    <div className={classes.gymImageContainer}>
                                        <div className={classes.gymImage} >
                                            <img alt="img" src={gymImgUrl} />
                                        </div>
                                        <Button onClick={this.claimClick.bind(this, gym)}> CLAIM PASS </Button>
                                    </div>
                                </Hidden>
                                <div className={classes.locationInfo}>
                                    <div className={classes.locationName}>
                                        <Hidden smUp>
                                            <span className={classes.circularNumber}>{index+1}</span>
                                        </Hidden>
                                        {gym.name}
                                    </div>
                                    <div className={classes.locationAddress}>
                                        {gym.full_address} 
                                    </div>
                                    <div className={classes.locationDistance}> 
                                        <Hidden xsDown>
                                            <span className={classes.squaredNumber}>{index+1}</span>
                                        </Hidden>
                                        <span>
                                            {gym.distance} miles away
                                        </span>
                                    </div>
                                </div>
                                <Hidden mdUp>
                                    <Button className={classes.claimButton} onClick={this.claimClick.bind(this, gym)}> Claim </Button> 
                                </Hidden>
                            </div>
                        </div>
                    )
                })
            }
            return gymItems  
        }

       
        return (
            <div className={classes.gymListings} id='gym-listing'>
                <Hidden smUp>
                    <div className={classes.listingHeader}>{gyms.length} Clubs Near {this.props.GymFinderStore.mapLocation.name}</div>
                </Hidden>
                <Hidden xsDown>
                    {
                        gyms.length === 0 ? 
                        <div className={classes.listingHeader}>Could Not Find Clubs Near {this.props.GymFinderStore.mapLocation.name}</div>
                        : 
                        <span></span>
                    }
                </Hidden>
                {getGyms()}
            </div>
        )
    }
}

GymListings = inject('GymListingsStore', 'GymFinderStore')(observer(GymListings))
export default withWidth()(withStyles(styles)(GymListings));
