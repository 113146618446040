import {  observable, computed, action,  decorate  } from 'mobx';
import agent from '../../api/Agent';
import ClubDataStore from '../ClubDataStore';
import Moment from 'moment';

class ChangeDayStore {
    defaultDayValues(){
        return  {
            is_rest_day: '',
            is_club: '',
            club_id: '',
            theme: '',
            old_theme: '',
            time: '',
            is_duration_less_than_30: '',
            is_duration_30_to_45: '',
            is_duration_45_to_60: '',
            is_duration_more_than_60: '',
            is_easy:'',
            is_medium:'',
            is_hard:'',
            tagfilters: '',
            //
            //non ui items
            stream_id: '',
            group_class_id: '',
            //
            //non used for api searches
            start_date:'',
            type:'',
            //used for for replacment api
            task_int:'',
            task_date:'',
            task_type:'',
            task_id:'',
            user_plan_task_id:'',
            user_plan_id:''
        }
    }

    //observables
    dayValues = this.defaultDayValues();
    startdate = ''
    inProgress = false;
    updateInProgress = false;
    currentTask = {}


    searchResults = []
    searchResultsCurrentPage = 1
    searchResultsPagesAvailable = 1
    clearResults = true


    previewDayModalIsOpen = false;
    previewDayItem = {}

    searchTxt = ''
    //mode will either be edit or create
    mode = "edit"



    //helper
    //hard coded
    themeObject = {
        'cardio':"181",
        'strength':"180",
        'core':"180|48",
        'stretch':'183',
        'recovery':'261',
        'combo':'181|180'
    }
    //computed
    get canSearchWorkouts(){
        //if workouts/classes
        if (this.dayValues.theme && this.dayValues.time && this.dayValues.type !== 'class'){
            return true
        }
        return false
    }
    //actions
    setMode(value){
        this.mode = value
    }

    openPreviewDayModal(){
        this.previewDayModalIsOpen = true
    }

    closeModals(){
        this.previewDayModalIsOpen = false
    }

    clearDayValues(){
        this.dayValues = this.defaultDayValues();
        this.searchResults = []
        this.searchResultsCount = 0
        this.clearResults = true
        this.searchResultsCurrentPage = 1
        this.searchResultsPagesAvailable = 1
    }

    setValue(key, value){
        if ( this.dayValues[key] !== undefined ) {
            if (value !== null){
                this.dayValues[key] = value
            }
        }
    }

    fetchResults(){
        this.inProgress = true;
        this.clearResults = true
        this.searchResultsCurrentPage = 1
        this.searchResultsPagesAvailable = 1
        if (this.dayValues.type && this.dayValues.type === 'rest_day'){
            this.getRestDay()
        }else if (this.dayValues.type && this.dayValues.type === 'fit_appointment_workout'){
            this.getFitAppointment()
        }else if (this.dayValues.type && this.dayValues.type === 'daily_challenge'){
            this.getDailyChallenge()
        }else if (this.dayValues.type && this.dayValues.type === 'pt_session'){
            this.getPTSession()
        }else if (this.dayValues.type && this.dayValues.type === 'gx_class' && ClubDataStore.clubDataLoaded ){
            this.getClasses()
        }else if (this.dayValues.type && this.dayValues.type === 'gx_class' && !ClubDataStore.clubDataLoaded ){
            this.getGenericClass()
        }else if (this.dayValues.type && this.dayValues.type === 'activity'){
            this.getActivities()
        }else{
            this.getStreams()
        }
    }

    updateResults(){
        //if not in progress
        if (!this.inProgress && !this.updateInProgress){
            //increment page if pages available < currentpage
            if (this.searchResultsPagesAvailable > this.searchResultsCurrentPage){
                this.clearResults = false
                this.updateInProgress = true
                this.searchResultsCurrentPage = this.searchResultsCurrentPage + 1
                this.updateInProgress = true
                    if (this.dayValues.type && this.dayValues.type === 'gx_class'){
                        this.getClasses()
                    } else if (this.dayValues.type && this.dayValues.type === 'activity'){
                        this.getActivities()
                    }else{
                        this.getStreams()
                    }
            }else{
              this.clearProgress()
            }
        }
    }

    getClasses(){
        if (!ClubDataStore.values.id || !this.dayValues.start_date){
            return
        }
        var momentInstance = Moment
        var start_int = momentInstance(this.dayValues.start_date, "YYYY-MM-DD Z").unix() * 1000
        var stop_int  =  start_int  + 86400000
		var clubs = ClubDataStore.values.id
        var onboarding_day = 0
        var per_page = 100
        return agent.WeekClasses.getClasses(
            start_int, stop_int, per_page, clubs, onboarding_day
        )
        .then( (response ) => {
            if (response.results) var obj = JSON.parse( JSON.stringify( response.results ) )
            if (obj){
                this.searchResultsCount = response.total
                this.searchResults.replace(obj)

            }
        })
		.catch(action((err) => {
            alert(err)
			throw err;
		}))
		.finally(action(() => { this.clearProgress() }));
    }

    getActivities(){
        //page stuff
        var url = '?is_manual_log=1&published=all&tagexclude=2451'
        url = url+"&page="+this.searchResultsCurrentPage

        if (!this.dayValues.type || !this.dayValues.time){
            this.clearProgress()
            return
        }
        return agent.Stream.getStreams(url)
        .then( (response ) => {
            if (response.results) var obj = JSON.parse( JSON.stringify( response.results ) )
            if (obj) {
                this.searchResultsPagesAvailable = response.total_pages
                this.searchResultsCount = response.total

                if (this.clearResults){
                    this.searchResults.replace(obj)
                }else{
                    this.searchResults.push(obj)
                }
            }
        })
		.catch(action((err) => {
            alert(err)
			throw err;
		}))
		.finally(action(() => { this.clearProgress() }));
    }
    getGenericClass(){
        var url = '627'
        if (!this.dayValues.type || !this.dayValues.time){
            this.clearProgress()
            return 
        }
        return agent.Stream.getStream(url)
        .then( (response ) => {
            var results = []
            if (response) {
                results.push(response)
                this.searchResults.replace(results)    
                this.searchResultsCount = 1 
            }
        })
		.catch(action((err) => {
            alert(err)
			throw err;
		}))
		.finally(action(() => { this.clearProgress() }));
    }
    getDailyChallenge(){
        var url = '2296'
        if (!this.dayValues.type || !this.dayValues.time){
            this.clearProgress()
            return
        }
        return agent.Stream.getStream(url)
        .then( (response ) => {
            var results = []
            if (response) {
                results.push(response)
                this.searchResults.replace(results)
                this.searchResultsCount = 1
            }
        })
		.catch(action((err) => {
            alert(err)
			throw err;
		}))
		.finally(action(() => { this.clearProgress() }));
    }
    getFitAppointment(){
        var url = '1679'
        if (!this.dayValues.type || !this.dayValues.time){
            this.clearProgress()
            return
        }
        return agent.Stream.getStream(url)
        .then( (response ) => {
            var results = []
            if (response) {
                results.push(response)
                this.searchResults.replace(results)
                this.searchResultsCount = 1
            }
        })
		.catch(action((err) => {
            alert(err)
			throw err;
		}))
		.finally(action(() => { this.clearProgress() }));
    }
    getPTSession(){
        var url = '?tagfilters=2451'
        if (!this.dayValues.type || !this.dayValues.time){
            this.clearProgress()
            return
        }
        return agent.Stream.getStreams(url)
        .then( (response ) => {
            if (response.results) var obj = JSON.parse( JSON.stringify( response.results ) )
            if (obj) {
                this.searchResultsPagesAvailable = response.total_pages
                this.searchResultsCount = response.total
                this.searchResults.replace(obj)
            }
        })
		.catch(action((err) => {
            alert(err)
			throw err;
		}))
		.finally(action(() => { this.clearProgress() }));
    }
    getRestDay(){
        var url = '655'
        return agent.Stream.getStream(url)
        .then( (response ) => {
            var results = []
            if (response) {
                results.push(response)
                this.searchResults.replace(results)
                this.searchResultsCount = 1
            }
        })
		.catch(action((err) => {
            alert(err)
			throw err;
		}))
		.finally(action(() => { this.clearProgress() }));
    }
    getStreams(){
        if (!this.dayValues.theme || !this.dayValues.time){
            this.inProgress = false
            this.searchResultsCount = 0
            this.searchResults.clear()
            return
        }
        var url = ''
        //get vars needed
        const {is_club, theme } = this.dayValues

        //Location and Tags
        url += (is_club) ? "?is_club=1&is_daily_challenge=0&is_list=1" : '?is_club=0&is_daily_challenge=0'

        var themeTags = "&tagfilters=" + this.themeObject[theme]

        if (this.dayValues.tagfilters){
            themeTags = themeTags+"|"+this.dayValues.tagfilters
        }
        url = url+themeTags
        url = url.replaceAll(",","|")

        //additional theme urls
        var themeVar = ""
        switch (this.dayValues.theme){
            case "cardio":
                themeVar =  '&is_endurance_65=1'
                break;
            case "strength":
                themeVar = '&is_strength_65=1'
                break;
            case "core":
                themeVar = '&is_strength_65=1'
                break;
            case "stretch":
                themeVar = '&is_flexibility_50=1'
                break;
            case "recovery":
                themeVar = '&is_mobility_50=1'
                break;
            case "combo":
                themeVar = '&is_combo=1'
                break;
            default:
                break;
        }
        url = url+themeVar

        //additional duration
        const {is_duration_less_than_30, is_duration_30_to_45, is_duration_45_to_60, is_duration_more_than_60 } = this.dayValues

        if (is_duration_less_than_30) url = url + "&is_duration_less_than_30=1";
        if (is_duration_30_to_45) url = url + "&is_duration_30_to_45=1";
        if ( is_duration_45_to_60) url = url + "&is_duration_45_to_60=1";
        if (is_duration_more_than_60 ) url = url + "&is_duration_more_than_60=1";

        //additional intensity  
        const {is_easy, is_medium, is_hard} = this.dayValues
        
        if (is_easy) url = url + "&is_easy=1";
        if (is_medium) url = url + "&is_medium=1";
        if (is_hard) url = url + "&is_hard=1";

        
         //search filter
        if (this.searchTxt){
            url = url + "&term=" + this.searchTxt
        }

        url = url+"&page="+this.searchResultsCurrentPage

        return agent.Stream.getStreams(url)
        .then( (response ) => {
            if (response.results) var obj = JSON.parse( JSON.stringify( response.results ) )
            if (obj){
                this.searchResultsPagesAvailable = response.total_pages
                this.searchResultsCount = response.total
                if (this.clearResults){
                    if (this.currentTask){
                        if (this.currentTask.class_name && this.currentTask.class_name !== 'GroupClass'){
                            //obj.unshift(this.currentTask)
                        }
                    }

                    //filter out currentTask, todo make actual filter
                    var spliceIndex
                    for (var i=0; i < obj.length; i++) {
                        if (obj[i].id === this.currentTask.id){
                            //spliceIndex = i
                        }
                    }
                    if (spliceIndex){
                        obj.splice(spliceIndex,1)
                    }
                    this.searchResults.replace(obj)

                }else{
                    //concat doesn't seem to work with mobx arry ?
                    for (var z=0; z < obj.length; z++) {
                        if (obj[z].id !== this.currentTask.id){
                            this.searchResults.push( obj[z] );
                        }
                    }
                }
            }
        })
		.catch(action((err) => {
            alert(err)
			throw err;
		}))
		.finally(action(() => { this.clearProgress() }));
    }

    clearProgress(){
        this.inProgress = false
        this.updateInProgress = false
    }

    updateDay(){
        //TODO - check for values
        const { task_int, task_type, task_id, user_plan_task_id, theme,type, is_club } = this.dayValues
        //if type is gx_class, change theme to class i think
        var nTheme = theme
        if (type === 'gx_class') nTheme = 'gx_class'
        if (type === 'fit_appointment_workout') nTheme = 'fit_appointment_workout'
        if (type === 'pt_session') nTheme = 'pt_session'
        if (type === 'daily_challenge') nTheme = 'daily_challenge'
        if (type === 'activity') nTheme = 'activity'
        this.inProgress = true;
        return agent.Plans.changeDay(task_int, task_type, task_id, user_plan_task_id, nTheme, is_club  )
        .then( (response ) => {
            //alls good
            //console.log('day has been updated')
        })
        .catch(action((err) => {
            alert(err)
            throw err;
        }))
        .finally(action(() => { this.inProgress = false; }));
    }
    createDay(){
        const { user_plan_id, task_int, task_type, task_id, theme, type, is_club } = this.dayValues
        var nTheme = theme
        if (type === 'gx_class') nTheme = 'gx_class'
        if (type === 'fit_appointment_workout') nTheme = 'fit_appointment_workout'
        if (type === 'pt_session') nTheme = 'pt_session'
        if (type === 'daily_challenge') nTheme = 'daily_challenge'
        if (type === 'activity') nTheme = 'activity'
        this.inProgress = true;
        return agent.Plans.createDay(user_plan_id, task_int, task_type, task_id, nTheme, is_club )
        .then( (response ) => {
            //alls good
        })
        .catch(action((err) => {
            alert(err)
            throw err;
        }))
        .finally(action(() => { this.inProgress = false; }));
    }


    populateChangeDayStoreWithItems(plan, week, index){
        var momentInstance = Moment
        if (plan[week] && plan[week].days && plan[week].days.length > 0  && plan[week].days[index]){
            var day = plan[week].days[index]
            this.setValue('start_date', day.day_str)
            this.setValue('type', 'workout')
            this.setValue('searchTxt', '')
            //we have a task, this is not an empty day
            //TODO possibly get out if we are a rest day as task too
            if (day.tasks && day.tasks.length > 0 && day.tasks[0]) {
                var task = day.tasks[0]

                if (task.task.id){
                    this.setValue('stream_id', task.task.id)
                }

                //add Current Task to changeDay task
                this.currentTask = task.task
                if (task.task.class_name === 'GroupClass'){
                    this.setValue('is_club', true)
                }else{
                    this.setValue('is_club', task.is_club)
                }
                //is there a time for the task
                if (task.task_int){
                    this.setValue('task_int', task.task_int)
                }else if (task.task_date){
                    this.setValue('task_date', task.task_date)
                }
                this.setValue('user_plan_task_id', task.id)
                var start = momentInstance(task.task_int, "x")
                if (!isNaN(start)){
                    start = start.format('h:mm')
                    this.setValue('time', start)
                }
                //if task theme is class, set type to class
                //if task theme is fit appt, set type to fit appt
                this.setValue('old_theme', task.theme)
                switch (task.theme){
                    case 'full_body_strength':
                        this.setValue('theme', 'strength')
                        this.setValue('tagfilters', "179")
                        break
                    case 'upper_body_strength':
                        this.setValue('theme', 'strength')
                        this.setValue('tagfilters', "47")
                        break
                    case 'lower_body_strength':
                        this.setValue('theme', 'strength')
                        this.setValue('tagfilters', "49")
                        break
                    case 'abs_core':
                        this.setValue('theme', 'core')
                        break
                    case 'cardio':
                        this.setValue('theme', 'cardio')
                        break
                    case 'combo_cardio_strength':
                        this.setValue('theme', 'combo')
                        break
                    case 'flexibility_recovery':
                        this.setValue('theme', 'stretch')
                        break
                    case "gx_class":
                        this.setValue('type', 'gx_class')
                        break
                    case "daily_challenge":
                        this.setValue('type', 'daily_challenge')
                        break
                    case "activity":
                        this.setValue('type', 'activity')
                        break
                    case "fit_appointment_workout":
                        this.setValue('type', 'fit_appointment_workout')
                        break
                    case "pt_session":
                        this.setValue('type', 'pt_session')
                        break
                    default:
                        this.setValue('theme', task.theme)
                        break
                }
            }else{
                this.setValue('task_date', day.day_str)
            }
        }
    }
}

decorate(ChangeDayStore, {
    dayValues: observable,
    mode:observable,
    setMode: action,
    startdate: observable,
    inProgress: observable,
    searchTxt: observable,
    previewDayModalIsOpen: observable,
    previewDayItem: observable,
    canSearchWorkouts: computed,
    searchResults: observable,
    searchResultsCount: observable,
	openPreviewDayModal: action,
	closeModals: action,
    clearDayValues: action,
    clearProgress: action,
    setValue: action,
    populateChangeDayStoreWithItems:action
});

export default new ChangeDayStore();
