import React, { Component } from 'react';
import TrainerActiveSyncForm from './TrainerActiveSyncForm.js';
import { observer , inject } from 'mobx-react';

class TrainerLogin extends Component {
    componentDidMount() {
        this.props.TrainerSyncStore.clearValues()
    }
    goLoginCreate(){
        if (this.props.UsersRoutingStore.routeToLogin){
            this.props.history.replace('/users/login')
        }else{
            this.props.history.replace('/users/create')
        }
    }
    constructor(props){
        super(props);
        this.goLoginCreate = this.goLoginCreate.bind(this)
    }

    render() {
        return (
            <div className="trainerLogin">
                <div className="appUsers">
                    <h1>Enter your trainer’s 24GO 5 digit code</h1>
                    <h2 style={{maxWidth:"800px"}}>
                      <strong>Trainers:&nbsp;</strong>
                      To access your trainer code, open your 24GO app and tap "Get My Trainer Code" on the GO screen. If you are unable to access your trainer code, please email <a href="mailto:customerservicewt@24hourfit.com">customerservicewt@24hourfit.com</a>.
                      <br/><strong>Note:</strong> It may take up to 3 business days to receive a response.
                    </h2>
                    <TrainerActiveSyncForm />
                    <div className="subText">
                      {/* <button class="button-blue">Skip Trainer Assistance</button> */}
                      <button className="subLink" onClick={this.goLoginCreate}>
                        Skip Trainer Assistance
                      </button>
                    </div>
                </div>
            </div>
        );
  }
}

TrainerLogin = inject('TrainerSyncStore', 'UsersRoutingStore')(observer(TrainerLogin))
export default TrainerLogin;
