import React, { Component } from 'react';

class ClubSelectorQuestion extends Component {

    constructor(props){
        super(props);
        this.skipGymPressed = this.skipGymPressed.bind(this)
        this.selectGymPressed = this.selectGymPressed.bind(this)
    }

    skipGymPressed (e){
        const {skipGym} = this.props
        skipGym()
    }

    selectGymPressed(e){
        const {selectGym} = this.props
        selectGym()
    }

    render() {
        return (
            <div className="stepContent animate-fadeInDown progress-Info-Wrapper">
                <h4>We don't have a club location for you</h4>
                <h3>To continue please tell us what 24 Hour Fitness Gym you work out at. </h3>
            
                <button
                    onClick= {this.selectGymPressed}
                    className="button-teal button-skewed">Select 24 Hour Fitness Club</button>
                <button
                    onClick= {this.skipGymPressed}
                    className="button-teal button-skewed">No Thanks</button>
            </div>
        )
  }
}

export default ClubSelectorQuestion;
