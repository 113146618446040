import React, { Component } from 'react';
import { IconGoLogo, IconCheck } from '../Icons/Icons';

class ProcessingAnimation2 extends Component {
    render() {
        return (
            <div className="appLoader">
              <div className="appMagicSpinner">
                <div className="circle animate-circle"></div>
                <IconGoLogo nativeColor="#fff" id="icon" className="icon icon-gologo animate-iconEffect" />
                <IconCheck nativeColor="#fff" id="icon" className="icon icon-check animate-fadeInCheck" />
                <span className="loadingText">Creating personalized plan</span>
              </div>
            </div>
        )
  }
}

export default ProcessingAnimation2;
