import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class Frequency extends Component {

    handleChange = (e) => {
        var value = Number(e.target.value.replace(/\D/g,''));
        this.props.UserStore.setValue(e.target.name,value);
    }
    render(){
        const {frequency} = this.props.UserStore.currentUser
        var dataFreq = String(frequency)+' days'
        const daysArray = [
            "0 days",
            "1 days",
            "2 days",
            "3 days",
            "4 days",
            "5 days",
            "6 days",
            "7 days",
        ]
        const days= daysArray.map( (day, i ) => {
            return ( 
                <MenuItem
                    key={day} 
                    value={day}>
                        {day}
                    </MenuItem>
            ); 
        })
        return(
            <div className="stepContent animate-fadeInDown">
              <h1>How many times a week do you currently workout?</h1>
                <Select
                    label="Days"
                    name="frequency"
                    className="frequency"
                    value={dataFreq}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'frequency',
                        id: 'frequency',
                    }}
                >
                    {days}
                </Select> 
            </div>
        )
    }
}
Frequency = inject('UserStore')(observer(Frequency))    
export default Frequency;
