import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Moment from 'moment';
import { observer , inject } from 'mobx-react';

class ChangeTimeModal extends Component {

    //<ChangeTimeModal  index={planPreviewIndex} plan={plan.week_objects} closeModal={this.closeModal} week={currentWeek}/>
    constructor(props){
        super(props);
        this.modalClose = this.modalClose.bind(this)
        this.outsideClick = this.outsideClick.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }
    modalClose(e){
        e.preventDefault();
        e.stopPropagation();
        this.props.closeModal()
    };

    outsideClick(e){
        //close modal from oustide click
        if (e.target.className === 'appModal'){
          this.props.closeModal()
        }
    }
    handleChange(e){
        const {index, plan, week, handleChangeTime } = this.props
        this.props.ChangeDayStore.setValue('time', e.target.value)
        var day = plan[week].days[index] 
        if (plan[week] && plan[week].days && plan[week].days.length > 0  && plan[week].days[index]){
            if (day.tasks && day.tasks.length > 0 && day.tasks[0]) {
                var task = day.tasks[0]
                handleChangeTime(task,parseInt(index))
            }
        }
        this.props.closeModal()
    }
    componentWillMount(){
        const {plan, week, index } = this.props
        this.props.ChangeDayStore.clearDayValues()
        this.props.ChangeDayStore.populateChangeDayStoreWithItems(plan,week,index)
	}

    render() {
        const {index } = this.props
        const { dayValues } = this.props.ChangeDayStore

        var quarterHours = ["00", "15", "30", "45"];
        var timeArray = [];
        for(var i = 7; i < 24; i++){
          for(var j = 0; j < 4; j++){
            timeArray.push(i + ":" + quarterHours[j]);
          }
        }
        for(i = 0; i < 7; i++){
          for(j = 0; j < 4; j++){
            timeArray.push(i + ":" + quarterHours[j]);
          }
        }
        const time =
            timeArray.map( (value, index) => {
                var momentInstance = Moment
                var displayTime = momentInstance(value,'h:mm a').format('h:mm a');
                return (
                    <MenuItem
                        key={index}
                        value={value}
                     >{displayTime}</MenuItem>
                )
            })
        return (
            <div className="appModal"
                onClick = { this.outsideClick }
            >
                <div className="modalWrapper">
                    <button  className="closeButton"
                        onClick={this.modalClose}
                    >x</button>
                    <FormControl
                            fullWidth
                        >
                        <div className="inputBlockWrapper">
                          <InputLabel htmlFor="name-disabled">Time</InputLabel>
                          <Select
                              fullWidth
                              label="Time"
                              index={index}
                              value= {dayValues.time}
                              onChange={this.handleChange}
                              onOpen={this.handleOpen}
                              className="dayWhen"
                              inputProps={{
                                  name: "when"+index,
                                  id: 'when',
                              }}
                             
                           >
                              {time}
                          </Select>
                        </div>
                    </FormControl>
                </div>
            </div>
        )
    }
}

ChangeTimeModal = inject('ChangeDayStore')(observer(ChangeTimeModal))
export default ChangeTimeModal 
