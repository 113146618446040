import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import {  IconClose } from '../Icons/Icons';
import GymLocator from '../../shared-components/src/components/maps/GymLocator/GymLocator';


class GymLocatorModal extends Component {

    constructor(props){
        super(props);
        this.closeModal = this.closeModal.bind(this)
    }
    closeModal(){
      this.props.ClubDataStore.closeSelectClubModal()
    }

    selectionClick = (gym) => {
        let club_id = gym.club_number;
        this.props.UserStore.setValue('preferred_club_id', gym.id);
        this.props.UserStore.updateUserPassive();
        const {gymSelected} = this.props;

        //gymSelected()
        club_id = String(club_id)
        //
        //add 0's to front until length is 5 

        while (club_id.length < 5){
			club_id = '0' + club_id
        }
        this.props.ClubDataStore.getClubData(club_id) 
        .then( () => {
            gymSelected()     
        })
    }

    render() {
        //todo loading overlay 
        return (

            <div className="previewDayModal appFullModal animate-fadeIn" >
                <div className="modalHeader animate-fadeIn animate-delay-0-5" onClick= {this.closeModal}>
                    <span className="leftSide">
                    <strong>Select Club</strong>
                    </span>
                    <button className="rightSide" >
                    Done <IconClose nativeColor="#fff" id="icon" className="icon icon-close" />
                    </button>
                </div>

                <div className="modalWrapper animate-slideUp">
                    <div className="modalContent planPreviewModal">
                        <GymLocator selectionClick={this.selectionClick} />
                    </div>
                </div>
            </div>
        )
    }
}

GymLocatorModal = inject('ClubDataStore', 'UserStore')(observer(GymLocatorModal))
export default GymLocatorModal 
