import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ValidatorForm, TextValidator  } from 'react-material-ui-form-validator';
import { withRouter } from 'react-router-dom';
import ClubDataStore from '../../Stores/ClubDataStore';


class TrainerActiveSyncForm extends Component {
    handleSubmitForm = (e) => {
        e.preventDefault();
        this.props.TrainerSyncStore.trainerLookup()
            .then(() => {
                //
                if (this.props.TrainerSyncStore.values.id){

                    if ( window.analytics ) window.analytics.track( 'Trainer Identified', {
                        club_id: ClubDataStore.values.id,
                        club_number: ClubDataStore.club_number,
                        trainer_code: this.props.TrainerSyncStore.values.share_code
                    });

                    if (this.props.UsersRoutingStore.routeToLogin){
                        this.props.history.replace('/users/login');
                    }else{
                        this.props.history.replace('/users/create');
                    }
                }
            })
    };

    handleChange = (e) => {
        this.props.TrainerSyncStore.setValue(e.target.name,e.target.value);
    }

    render() {
        const { share_code } = this.props.TrainerSyncStore.values;


        return(
            <ValidatorForm
                className=""
                ref="form"
                autoComplete="off"
                instantValidate = {false}
                onSubmit={this.handleSubmitForm}
                onError={errors => console.log(errors)}
            >
                <TextValidator
                    key="0"
                    label="24GO Share Code"
                    value={share_code}
                    onChange={this.handleChange}
                    className="share_code"
                    placeholder="eg: G98R2K"
                    name="share_code"
                    validators={['required']}
                    errorMessages={['this field is required']}
                    margin="normal"
                />
                <button className="button-teal">Continue</button>
            </ValidatorForm>
        )

    }
}
TrainerActiveSyncForm = inject('UsersRoutingStore','TrainerSyncStore')(observer(TrainerActiveSyncForm ))
TrainerActiveSyncForm = withRouter(TrainerActiveSyncForm)
export default TrainerActiveSyncForm;
