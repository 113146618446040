import { observable, action,  decorate } from 'mobx';

class UsersRoutingStore {
    //observables
    routeToLogin = true;

    setRouteToLogin(){
        this.routeToLogin = true 
    }
    setRouteToCreate(){
        this.routeToLogin  = false 
    }

}

decorate( UsersRoutingStore, {
    setShowReturningProfile: action,
    showReturningProfile: observable, 
    routeToLogin:  observable,
    setRouteToLogin: action,
    setRouteToCreate: action,
});

export default new UsersRoutingStore();
