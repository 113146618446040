import { observable, action, runInAction,  decorate } from 'mobx';
import agent from '../../api/Agent';
import GymFinderStore from '../GymFinderStore'
class GymListingsStore { 
    //observables
    inProgress = false;
    gyms = []
    highlightedClubId = '' 
    allGyms = []
    //actions

    constructor() {
        this.getGyms();
    }

    getGyms(){
        this.inProgress = true;
        return agent.GymLocations.locations()
        .then( (gyms)  => {
            runInAction( () => {
              if (gyms.results){
                    this.gyms = gyms.results
                    this.allGyms = gyms.results;
                    this.getClosestGyms(30);
                }
            })
        })
        .catch(action((err) => {
            alert('Gyms could not be loaded at this time.')
            throw err;
        }))
        .finally(action(() => { 
            this.inProgress  = false; 
            this.sortGymsOnLocation()
        }))
    }

    getGymById(id) {
        return this.gyms.find((gym) => {
            return gym.id === id;
        });
    }

    getGymsOnMapBoundaries(neBound, swBound) {
        this.gyms = [];
        this.gyms = this.allGyms.filter((gym) => {
            return (gym.address_latitude >= swBound.lat && gym.address_latitude <= neBound.lat 
                && gym.address_longitude >= swBound.lng && gym.address_longitude <= neBound.lng);
        });
        this.sortGymsOnLocation();
    }

    /**
     * Show Just the Gyms that are within @maxDistance miles from the current map position.
     * If @maxDistance is not provided it will show all the loaded gyms.
     * @param {*} maxDistance 
     */
    getClosestGyms(maxDistance) {
        this.gyms = [];
        if (maxDistance) {
            this.gyms = this.allGyms.filter((gym) => {
                gym.distance = this.calculateDistance(
                    gym.address_latitude,
                    gym.address_longitude,
                    GymFinderStore.mapLocation.lat,
                    GymFinderStore.mapLocation.lon,
                    'K'
                );
                return gym.distance < maxDistance;
            });
        } else {
            this.gyms = this.allGyms;
        }
        this.sortGymsOnLocation();
    }

    sortGymsOnLocation(){
        if (this.gyms.length > 0 && GymFinderStore.mapLocation.lat){
            this.gyms.forEach( ( gym ) => {
                gym.distance = this.calculateDistance(
                    gym.address_latitude,
                    gym.address_longitude,
                    GymFinderStore.mapLocation.lat,
                    GymFinderStore.mapLocation.lon,
                    'K'
                )
                
            })
        }
        this.gyms = this.gyms.slice().sort( (a,b) => {
            return a.distance - b.distance
        })
    }
    
    //utils
    //
    calculateDistance(lat1, lon1, lat2, lon2, unit) {
        var radlat1 = Math.PI * lat1/180
        var radlat2 = Math.PI * lat2/180
        // var radlon1 = Math.PI * lon1/180
        // var radlon2 = Math.PI * lon2/180
        var theta = lon1-lon2
        var radtheta = Math.PI * theta/180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist)
        dist = dist * 180/Math.PI
        dist = dist * 60 * 1.1515
        if (unit==="K") { dist = dist * 1.609344 }
        if (unit==="N") { dist = dist * 0.8684 }
        dist = dist.toFixed(2);
        return dist
    }
    
}

decorate( GymListingsStore, {
    inProgress: observable,
    gyms:observable,
    highlightedClubId:observable,
    getGyms: action,
    getGymById: action,
    sortGymsOnLocation: action,
    getGymsOnMapBoundaries: action,
})

export default new GymListingsStore()
