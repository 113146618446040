import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';


class ActivityDropdown extends Component {
    
    render(){
        const activityObject = {
            'Basketball':'621',
            'Swim':'643',
            'Yoga':'650',
            'Walk':'647',
            'Hike':'629'
        }
        const activity =
            Object.keys(activityObject).map( (key, index) => {
                return (
                    <MenuItem
                        key={index}
                        value={activityObject[key]}
                     >{key}</MenuItem>
                )
        });
        const {handleChange, index, value, disabled } = this.props
        if (value && value.theme && value.theme === 'activity'){
            return (
            <FormControl
                    fullWidth
                    disabled={disabled}
                >
                <div className="inputBlockWrapper">
                  <InputLabel htmlFor="name-disabled">What Activity?</InputLabel>
                  <Select
                      fullWidth
                      label="(Select Type)"
                      index={index}
                      value= {value.stream_id}
                      onChange={handleChange}
                      className="dayWhat"
                      inputProps={{
                          name: "stream_id"+index,
                          id: 'stream_id',
                      }}
                  >
                      {activity}
                  </Select>
                </div>
            </FormControl>
            )
        }else{
			return null
        }
    }
}

export default ActivityDropdown;
