import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { withRouter } from 'react-router-dom';
import ScriptGenerator from '../Profile/ScriptGenerator.js';
//




class CreateEmail extends Component {

    handleChange = (e) => {
        this.props.UserStore.setValue(e.target.name,e.target.value);
    }
    handleSubmitForm = (e) => {
        e.preventDefault();
        this.props.UserStore.register()
            .then(() => {
                const { currentUser } = this.props.UserStore;
                const { class_name } = this.props.UserStore.currentUser;
                if (class_name === 'Member'){
                    this.props.history.replace('/users/confirmation');
                }else{
                    //dashboard
                    this.props.TrainerSyncStore.trainerLink()
                        .then( ()=> {
                            if (ScriptGenerator.userHasNoInfoCheck(currentUser)){
                                this.props.history.replace('/users/success');
                            }else{
                                this.props.history.replace('/dashboard');
                            }
                        })
                }
            })
    }
    render() {
        const { first_name, last_name, year, day, month, email, password  } = this.props.UserStore.currentUser;
        //fname
        //lname
        //dob
        //email
        //pass

        const fillRange = (start, end) => {
            return Array(end - start + 1).fill().map((item, index) => start + index);
        };
        const daysArray = fillRange(1, 31);
        const days = daysArray.map((day, i) => {
            return (
                <MenuItem
                    key={i}
                    value={day}
                >{day}</MenuItem>
            );
        })
        const monthsArray = fillRange(0, 11);
        const months = monthsArray.map((month, i) => {
            var momentInstance = Moment
            const monthName = momentInstance.months(i)
            return (
                <MenuItem
                    key={i}
                    value={i}
                >{monthName.substring(0, 3)}</MenuItem>
            )
        })
        var yearsArray = fillRange(1920, new Date().getFullYear() );
        yearsArray = yearsArray.reverse()
        const years = yearsArray.map((year, i) => {
            return (
                <MenuItem
                    className="year"
                    key={i} value={year}
                >{year}
                </MenuItem>
            );
        })
        return (

            <ValidatorForm
                className="inputFormWrapper"
                ref="form"
                autoComplete="off"
                instantValidate = {false}
                onSubmit={this.handleSubmitForm}
                onError={errors => console.log(errors)}
                >              
                <div className="inputInlineWrapper">
                  <TextValidator
                      autoComplete="off"
                      label="First Name"
                      onChange={this.handleChange}
                      name="first_name"
                      id="firstNameField"
                      value={first_name}
                      validators={['required', 'matchRegexp:^([^0-9]*)$']}
                      errorMessages={['This field is required', 'No numbers allowed']}
                      className=""
                      placeholder="eg: John"
                  />
                </div>
                <div className="inputInlineWrapper">
                  <TextValidator
                      autoComplete="off"
                      label="Last Name"
                      onChange={this.handleChange}
                      name="last_name"
                      id="lastNameField"
                      className=""
                      value={last_name}
                      validators={['required', 'matchRegexp:^([^0-9]*)$']}
                      errorMessages={['This field is required', 'No numbers allowed']}
                      placeholder="eg: Smith"
                  />
                </div>
                <div className="createEmailBirthday">
                    <div className="" >Date of Birth</div>
                    <div className="inputInlineWrapper">
                    <SelectValidator
                        name="month"
                        className=""
                        value={month}
                        onChange={this.handleChange}
                        InputProps={{
                            name: 'month',
                            id: 'month',
                        }}
                    >
                        {months}
                    </SelectValidator>
                    <SelectValidator
                        name="day"
                        className=""
                        value={day}
                        onChange={this.handleChange}
                        InputProps={{
                            name: 'day',
                            id: 'day',
                        }}
                    >
                        {days}
                    </SelectValidator>
                    <SelectValidator
                        name="year"
                        className=""
                        value={year}
                        onChange={this.handleChange}
                        InputProps={{
                            name: 'year',
                            id: 'year',
                        }}
                    >
                        {years}
                    </SelectValidator>
                    </div>
              </div>


              <div className="inputInlineWrapper">
                <TextValidator
                    key="0"
                    label="Email"
                    onChange={this.handleChange}
                    className="email"
                    id="emailField"
                    name="email"
                    value={email}
                    validators={['required', 'isEmail']}
                    errorMessages={['This field is required', 'email is not valid']}
                    placeholder="eg: name@domain.com"
                    margin="normal"
                />
              </div>
              <div className="inputInlineWrapper">
                <TextValidator
                    key="1"
                    label="Password"
                    onChange={this.handleChange}
                    className=""
                    placeholder=""
                    id="passwordField"
                    name="password"
                    type="password"
                    value={password}
                    validators={['required']}
                    errorMessages={['this field is required']}
                    margin="normal"
                />
              </div>
             <button id="register-button" className="button-teal loginButton">Register with Email</button>
            </ValidatorForm>
        )

    }

}
CreateEmail = inject('TrainerSyncStore', 'UserStore','UsersRoutingStore', 'CommonStore')(observer(CreateEmail  ))
CreateEmail  = withRouter(CreateEmail )
export default CreateEmail ;
