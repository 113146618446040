import { observable, action,  decorate } from 'mobx';
import agent from '../../api/Agent';
import PlanBuilderStore from '../PlanBuilderStore';
import ClubDataStore from '../ClubDataStore';
import Moment from 'moment';

class WeekClassesStore {
    weekClasses = Array(7) 
    inProgress = false

    getClasses(){
        if (!ClubDataStore.values.id || !PlanBuilderStore.values.start_date || !PlanBuilderStore.values.workout_days){
            return
        }

		//gen start and end date
        var momentInstance = Moment

        var promiseArray = []

        var per_page = 100
		var clubs=ClubDataStore.values.id 
        var onboarding_day = 0

        var workout_days = PlanBuilderStore.values.workout_days
        var workoutDaysArray = workout_days.split('|')

        workoutDaysArray.forEach( (value, index)=> {
            onboarding_day = value
            
            var start_int = momentInstance(PlanBuilderStore.values.start_date, "YYYY-MM-DD Z").add(value,'days').unix() * 1000

            var stop_int  =  start_int  + 86400000

            promiseArray.push( agent.WeekClasses.getClasses(
                    start_int, stop_int, per_page, clubs, onboarding_day
                )
            )
        })
        this.inProgress = true 

        //TODO - make sure IE11 is okay with this
        Promise.all(promiseArray)
        .then(dayClasses => { 
            //TODO - run in action
            workoutDaysArray.forEach( (value, index)=>{
                //TODO - make sure results exist
                this.weekClasses[value] = dayClasses[index].results
            })
            this.inProgress = false
        })
        .catch(function(err) {
            alert(err.message); // some coding error in handling happened
        });
    }
}

decorate(WeekClassesStore, {
    inProgress: observable,
    weekClasses: observable,
    getClasses: action,
});

export default new WeekClassesStore();
