import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import Select from '@material-ui/core/Select';
import ClubDataStore from '../../Stores/ClubDataStore';
import TrainerSyncStore from '../../Stores/TrainerSyncStore';

import MenuItem from '@material-ui/core/MenuItem';
import Moment from 'moment';

class StartDate extends Component {

    handleChange = (e) => {
        //var value = Number(e.target.value.replace(/\D/g,''));
        this.props.PlanBuilderStore.setValue(e.target.name,e.target.value);
        this.updateStartDate()
    }
    updateStartDate(){
        const {  year, day, month  } = this.props.PlanBuilderStore.values;
        var momentInstance = Moment
        const sd = momentInstance([year,month,day]).format("YYYY-MM-DD");
        this.props.PlanBuilderStore.setValue('start_date',sd);
    }
    componentWillMount(){
        //const {  year, day, month  } = this.props.PlanBuilderStore.values;
        var d = new Date();
        var year = d.getFullYear()
        this.props.PlanBuilderStore.setValue('year', year);
        var month = d.getMonth()
        this.props.PlanBuilderStore.setValue('month', month);
        var day = d.getDate()
        this.props.PlanBuilderStore.setValue('day', day);
        if ( window.analytics ) window.analytics.track( 'Plan Build', {
            club_id: ClubDataStore.values.id,
            club_number: ClubDataStore.club_number,
            trainer_code: TrainerSyncStore.values.share_code
        });
        //TODO set y m d from here
        //TODO there is a better way to do this - band aid - should make computed field in store

        var momentInstance = Moment
        const sd = momentInstance([year,month,day]).format("YYYY-MM-DD");
        this.props.PlanBuilderStore.setValue('start_date',sd);
    }
    render(){
        const {  year, day, month  } = this.props.PlanBuilderStore.values;

        //TODO make external function for this if ever used again...
        const fillRange = (start, end) => {
            return Array(end - start + 1).fill().map((item, index) => start + index);
        };
        const daysArray = fillRange(1, 31);
        const days = daysArray.map( (day, i ) => {
            return (
                <MenuItem
                    key={i}
                    value={day}
                 >{day}</MenuItem>
            );
        })
        const monthsArray = fillRange(0, 11);
        const months = monthsArray.map( (month, i) => {
            var momentInstance = Moment
            const monthName = momentInstance.months(i)
            return (
                <MenuItem
                    key={i}
                    value={i}
                 >{monthName}</MenuItem>
            )
        })
        var yearsArray = fillRange( new Date().getFullYear(), new Date().getFullYear()+1 );
        yearsArray = yearsArray.reverse()
        const years = yearsArray.map( (year, i ) => {
            return (
                <MenuItem
                    className="year"
                    key={i} value={year}
                    >{year}
                </MenuItem>
            );
        })

        return(
            <div className="stepContent animate-fadeInDown">
              <h1>What date would you like to start?</h1>

              <div className="inputInlineWrapper">
                  <Select
                  value={year}
                  name="year"
                  onChange={this.handleChange}
                  inputProps={{
                      name: 'year',
                      id: 'year',
                  }}
                  >
                      {years}
                  </Select>
                </div>

                <div className="inputInlineWrapper">
                  <Select
                      name="month"
                      className="birthdaySel"
                      value={month}
                      onChange={this.handleChange}
                      inputProps={{
                          name: 'month',
                          id: 'month',
                      }}
                  >
                      {months}
                  </Select>
                </div>

                <div className="inputInlineWrapper">
                  <Select
                      name="day"
                      className="birthdaySel"
                      value={day}
                      onChange={this.handleChange}
                      inputProps={{
                          name: 'day',
                          id: 'day',
                      }}
                  >
                      {days}
                  </Select>
                </div>

            </div>
        )
    }
}
StartDate = inject('PlanBuilderStore')(observer(StartDate))
export default StartDate;
