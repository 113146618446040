import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { IconTarget, IconLevel, IconCalendar, IconLocationIos, IconClasses } from '../Icons/Icons';
import ClubDataStore from '../../Stores/ClubDataStore';
import TrainerSyncStore from '../../Stores/TrainerSyncStore';

class Success extends Component {

    componentWillMount(){
        if ( window.analytics ) window.analytics.track( 'Profile Complete', {
            club_id: ClubDataStore.values.id,
            club_number: ClubDataStore.club_number,
            trainer_code: TrainerSyncStore.values.share_code
        });
    }

    render() {
        return (
              <div className="appCenterMessage animate-fadeInDown">
                <h1>Your profile and assessment are complete.</h1>
                <h2>Lets build a personalized Fit Plan for your:</h2>
                <div className="progress-Info-Wrapper planFeatures animate-fadeInDown">
                  <span className="planFeaturesTitle">MY PLAN</span>
                  <p><IconTarget nativeColor="#000" id="icon" className="icon icon-target" /> Goal</p>
                  <p><IconLevel nativeColor="#000" id="icon" className="icon icon-level" /> Fitness Level</p>
                  <p><IconCalendar nativeColor="#000" id="icon" className="icon icon-calendar" /> Schedule</p>
                  <p><IconLocationIos nativeColor="#000" id="icon" className="icon icon-location-ios" /> Location</p>
                  <p><IconClasses nativeColor="#000" id="icon" className="icon icon-classes" /> Classes</p>
                </div>
                <Link to="/plans" className="button-teal button-skewed">Get Started</Link>
                <Link to="/dashboard" className="bottomRightBtn">I'll Build My Plan Later</Link>
              </div>
        )
  }
}

export default Success;
