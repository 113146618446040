import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { IconPlan } from '../Icons/Icons';


class ShareActivity extends Component {
    shareActivity = (e) => {
            const { onboarders } = this.props.UserStore.currentUser

            this.props.TrainerSyncStore.trainerSync(onboarders[0].share_code)
            .then(() => {
                this.props.history.replace('/dashboard');
            })
    }


    constructor(props){
        super(props);
        this.shareActivity = this.shareActivity.bind(this)
    }

    render() {
        const { inProgress} = this.props.PlanBuilderStore.values
        const { onboarders } = this.props.UserStore.currentUser
        if (!inProgress){

            if (onboarders && onboarders[0]){
                var trainer = onboarders[0]
                return(

                    <div className="stepContent animate-fadeInDown">
                        <h1>Last Question!</h1>
                        <h2>Do you want to share your workout progress with {trainer.first_name} {trainer.last_name}</h2>
                        <button
                            onClick={this.shareActivity}
                            className="yesBtn button-skewed button-teal"
                        >
                            Yes, Share My Activity
                        </button><br/><br/>
                        <Link to="/dashboard" >Maybe Later</Link>
                    </div>
                );
                }else{
                    return(
                      <div>
                        <img alt='confetti' src="/fitplan/imgs/confetti.gif" className="confettiBlast animate-fadeOut" />
                        <div className="stepContent animate-fadeInDown">
                          <IconPlan nativeColor="#000" id="icon" className="icon topIcon animate-fadeInDown icon-plan" />
                          <h1>Congratulations</h1>
                          <h2>Your new plan has been created and saved on your dashboard.</h2>
                          <br/>
                          <Link to="/dashboard" className="button-teal button-skewed">Go To My Dashboard</Link>
                        </div>
                      </div>
                    )
                }
        }else{
            return(
              <div className="appLoader">
                <div className="appLoadingSpinner">
                  <div className="loader"></div>
                  <span className="loadingText"></span>
                </div>
              </div>
            )
        }
    }
}

ShareActivity= inject('UserStore', 'PlanBuilderStore', 'TrainerSyncStore')(ShareActivity)
export default ShareActivity;
