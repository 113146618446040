import { observable, decorate } from 'mobx';


class ReccomendationStore {
    recItems = []
}


decorate(ReccomendationStore, {
    recItems: observable
});
export default new ReccomendationStore();
