import { observable, action, runInAction, decorate, computed } from 'mobx';
import agent from '../../api/Agent';
import ClubDataStore from '../ClubDataStore';
import Moment from 'moment';
import moment from 'moment-timezone';
import ChangeDayStore from '../ChangeDayStore';
import ReccomendationStore from '../ReccomendationStore';



class PlanBuilderStore {

    d = new Date();
    ///
    //OBSERVABLES
    //
    //
    //TODO plan_id
    //default - 51
    //aging - 67
    //
    values = {
        custom_name: '',
        gts_score: '',
        cardio_score: '',
        plan_id: 51,
        current_step: 0,
        year: this.d.getFullYear(),
        month: this.d.getMonth(),
        day: this.d.getDate(),
        planIsReady: false, 
        start_date: '',
        workout_days: '',
        timezone: '',
        weeks: 4 
    }

    hasSeenClubSelectorPrompt = false

    inProgress = false;
    isReady = false
    
    planTemplate = {} 
    previewPlanItems = [] 
    recItems = []

    planDayModalIsOpen = false;
    changeDayModalIsOpen = false;
    changeTimeModalIsOpen = false;
    promptForGymModalIsOpen = false;

    get hasOnlyRestDays() {
        var allRestDays = true 
        if (this.planTemplate.workout_day_objects){
            this.planTemplate.workout_day_objects.forEach( (item)=> {
                if (item.is_rest_day === false) allRestDays = false 
            })
        }
        return allRestDays 
    }

    planPreviewIndex = '' 
    clearValues(){
        this.values = {
            custom_name: '',
            gts_score: '',
            cardio_score: '',
            plan_id: 51,
            current_step: 0,
            year: this.d.getFullYear(),
            month: this.d.getMonth(),
            day: this.d.getDate(),
            planIsReady: false, 
            start_date: '',
            workout_days: '',
            timezone: '',
            weeks: 4 
        }
        this.hasSeenClubSelectorPrompt = false
        this.nonClassPlan = false
    }
    ////
    ///

    /*
    "plan_id": "9",
    "goal": "lose_weight",
    "weeks": "1",
    "workout_days": "0|1|3|5|6",
    "workout_gym_days": "0|3|6",
    "workout_times": "8:00|6:00||12:30||17:45|14:15",
    "class_frequency": "sometimes", // all | none | always | sometimes
    "start_date": "2018-11-26"
     */

    setValue(key, value){
        if ( this.values[key] !== undefined ) {
            if (value !== null){
                this.values[key] = value
            }
        }
    }
    //setSection
    openChangeDayModal(){
        this.changeDayModalIsOpen  = true
    }
    openTimeChangeModal(){
        this.changeTimeModalIsOpen   = true
    }
    closeModals(){
        this.planDayModalIsOpen = false 
        this.changeDayModalIsOpen  = false 
        this.changeTimeModalIsOpen   = false 
    }
    setPlanPreviewIndex(index){
        this.planPreviewIndex = index
    }
    
    clearPlanTemplate(){
        if (this.planTemplate.workout_day_objects){
            for (var i=0;i<7;i++){
                this.planTemplate.workout_day_objects[i] = {
                    "is_rest_day": true
                }
            }
        }
    }

    generatePlanTemplate(){
        var momentInstance = moment
        momentInstance = Moment
        //this.planTemplate = {} 

        this.planTemplate.is_planbuilder = true
        this.planTemplate.start_date = this.values.start_date

        this.planTemplate.timezone = momentInstance.tz.guess()

        if (!this.planTemplate.workout_day_objects){
            this.planTemplate.workout_day_objects = []
        }

        var workoutDaysArray = this.values.workout_days.split('|')
        for (var i=0;i<7;i++){
            if (workoutDaysArray.indexOf(String(i)) !== -1){
                if (!this.planTemplate.workout_day_objects[i]){
                    this.planTemplate.workout_day_objects[i] = {
                      "is_rest_day": false,
                      "is_club": '',
                      "theme": '',
                      "time": '', 
                      "group_class_id":'',
                      "stream_id":''
                    }
                }else{
                    if (this.planTemplate.workout_day_objects[i].is_rest_day === true){
                        this.planTemplate.workout_day_objects[i] = {
                          "is_rest_day": false,
                          "is_club": '',
                          "theme": '',
                          "time": '', 
                          "group_class_id":'',
                          "stream_id":''
                        }
                    }
                }
            }else{
                this.planTemplate.workout_day_objects[i] = {
                    "is_rest_day": true
                }
            }
        }
    }
    cleanWorkoutCustomizations(){
        //a user has edited 
        var workoutDaysArray = this.values.workout_days.split('|')
        for (var i=0;i<7;i++){
            if (workoutDaysArray.indexOf(String(i)) !== -1){
                if ("stream_id" in this.planTemplate.workout_day_objects[i]){
                     delete this.planTemplate.workout_day_objects[i].stream_id 
                }
            }
        }
    }

    isPlanReady(){
        
        var workoutDaysArray = this.values.workout_days.split('|')
        var setReady = true
        for (var i=0;i<7;i++){
            if (workoutDaysArray.indexOf(String(i)) !== -1){
                var club_ready = true
                var theme_ready = true
                var time_ready = true
                //club
                var is_club = this.planTemplate.workout_day_objects[i].is_club
                if (is_club === true || is_club === false){
                    club_ready = true
                }else{
                    club_ready = false
                }
                //theme
                var theme = this.planTemplate.workout_day_objects[i].theme
                var stream_id = this.planTemplate.workout_day_objects[i].stream_id

                if (!theme){
                    theme_ready = false
                }else{
                    //make sure theme is a plan builder theme
                    var goodThemes = [ 
                        'full_body_strength',
                        'upper_body_strength',
                        'lower_body_strength',
                        'abs_core',
                        'cardio',
                        'combo_cardio_strength',  
                        'flexibility_recovery',
                        'gx_class',
                        'fit_appointment_workout',
                        'daily_challenge',
                        'pt_session',
                        'strength',
                        'yoga',
                        'cardio',
                        'functional_strength',
						'combo_cardio_strength',
                        'activity'
                    ] 
                    theme_ready = goodThemes.includes(theme)

                    if (theme === 'activity'){
                       if (!stream_id){
                        theme_ready = false              
                       }
                    }

                    if (!theme_ready){
                        //clean plan, a workout has been edited
                        //TODO - move this to day detail show/mount
                        //this.cleanWorkoutCustomizations() 
                    }
                }

                //time
                var time = this.planTemplate.workout_day_objects[i].time

                if (!time){
                    time_ready = false
                }

                var group_class_id = this.planTemplate.workout_day_objects[i].group_class_id
                if (group_class_id){
                    time_ready = true
                }
                
                if (!club_ready || !theme_ready || !time_ready ){
                    setReady = false
                }

            }
        }
        if (setReady){
            this.values.planIsReady = true
        }else{
            this.values.planIsReady = false 
        }
        
    }

    updatePlanTemplateWithTimeItems(index){
      this.updatePlanTemplateWithChangeDayItems(index, true)
    }
    updatePlanTemplateWithChangeDayItems(index, preserveTheme){
        //changeDay data replacement
        var changeObject = {}
        const {dayValues} = ChangeDayStore 
        //TODO - possibly remove hard coded ID from here for restDay
        if (dayValues.stream_id === 655){
            changeObject.is_rest_day = true 
        }else if (dayValues.theme === 'fit_appointment_workout'){
            changeObject.is_rest_day = false
            changeObject.time = dayValues.time
            changeObject.is_club = true 
            changeObject.theme = dayValues.theme
        }else if (dayValues.theme === 'pt_session'){
            changeObject.is_rest_day = false
            changeObject.time = dayValues.time
            changeObject.is_club = true 
            changeObject.theme = dayValues.theme
        }else{
            changeObject.is_rest_day = false 
            changeObject.is_club = dayValues.is_club 
            changeObject.club_id = ClubDataStore.values.id  
            changeObject.theme = dayValues.theme
            changeObject.time = dayValues.time
            changeObject.stream_id = dayValues.stream_id
            changeObject.group_class_id = dayValues.group_class_id
        }
        changeObject.time = dayValues.time
        changeObject.stream_id = dayValues.stream_id
        if (preserveTheme){
          changeObject.theme = dayValues.old_theme;
        }
        this.planTemplate.workout_day_objects[index] = changeObject 
    }

    updatePlanTemplateWorkoutDay(index,item,value){
        this.planTemplate.workout_day_objects[index][item]=value
    }

    previewOnboardingPlan(){
        //TODO - make sure we have goods
        this.inProgress = true;
        this.previewPlanItems.clear() 
        this.previewPlanLoaded = false;
        const { timezone, start_date, workout_day_objects } = this.planTemplate
        const { plan_id, gts_score,cardio_score } = this.values
        return agent.Plans.previewPlan(plan_id,gts_score,cardio_score, timezone, start_date,  workout_day_objects)
        .then( (response) => {
            runInAction( () => {
                if (response.week_objects){
                    this.previewPlanItems.replace(response.week_objects)    
                    this.previewPlanLoaded = true;
                }
            })
        })
		.catch(action((err) => {
            alert(err)
			throw err;
		}))
		.finally(action(() => { this.inProgress = false; }));
    }
    createOnboardingPlan(){
        //TODO - make sure we have goods
        this.inProgress = true;
        const { timezone, start_date, workout_day_objects } = this.planTemplate
        const {plan_id, gts_score,cardio_score,custom_name, weeks } = this.values
        return agent.Plans.createPlan(plan_id,  gts_score, cardio_score, weeks, timezone, start_date, custom_name, workout_day_objects)
        .then( (response) => {

            var obj = JSON.parse( JSON.stringify( response ) );
            if ( obj.plan ) var plan_obj = JSON.parse( JSON.stringify( obj.plan ) );
            if ( plan_obj ) {
                for ( var key in plan_obj ) {
                    // Exclude some fields in the plan object.
                    if ( [ 'equipment_required', 'tags', 'days', 'plan_task_ids' ].indexOf( key ) === -1 ) {
                        if ( !obj[key] ) obj[key] = plan_obj[key];
                    }
                }
            }
            delete obj.week_objects;
            delete obj.user_plan_task_ids;
            delete obj.plan;
            delete obj.templates;

            if ( window.analytics ) window.analytics.track( 'Plan Join', obj );
        })
		.catch(action((err) => {
            alert(err)
			throw err;
		}))
		.finally(action(() => { this.inProgress = false; }));
    }
    getRec(){
        var {plan_id, workout_days} = this.values
        if (workout_days.charAt(0) === '|'){
            workout_days = workout_days.substr(1)
        }
        var workout_days_uri = encodeURI(workout_days);
        this.inProgress = true;

        return agent.Plans.getRecs(workout_days_uri, plan_id)
        .then( (recs)  => {
            runInAction( () => {
                if (recs.error){
                    alert(recs.error)
                }
                if (recs.days){
                    var selectedDays = workout_days.split('|')
                    selectedDays = selectedDays.sort()
                    //assign days to specific day of the week 
                    selectedDays.forEach( (day, index)=> {
                        recs.days[index].dayOfTheWeek = parseInt(day)
                    })
                    ReccomendationStore.recItems.replace(recs.days)
                    //this.recItems.replace(recs.days)    
                }
            })
          })
        .catch(action((err) => {
            alert('Server could not be reached.')
            throw err;
        }))
        .finally(action(() => { this.inProgress  = false; }))
    }
}

decorate(PlanBuilderStore, {
    values: observable,
    isReady: observable,
    inProgress: observable,
    planTemplate: observable,
    previewPlanItems: observable,
    previewPlanLoaded: observable,
    generatePlanTemplate: action,
    updatePlanTemplateWorkoutDay: action,
	updatePlanTemplateWithChangeDayItems: action,
    updatePlanTemplateWithTimeItems: action,
    isPlanReady: action,
    previewOnboardingPlan: action,
    createOnboardingPlan: action,
    getRec: action,
    changeDayModalIsOpen : observable,
    changeTimeModalIsOpen : observable,
    promptForGymModalIsOpen : observable,
    openTimeChangeModal: action,
    closeModals: action,
    setPlanPreviewIndex: action,
    clearValues: action,
    clearPlanTemplate:action,
    hasOnlyRestDays: computed,
    hasSeenClubSelectorPrompt: observable
});

export default new PlanBuilderStore();
