import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Moment from 'moment';

class WhenTime extends Component {

    render() {
        const {handleChange, index, value, disabled } = this.props
        var quarterHours = ["00", "15", "30", "45"];
        var timeArray = [];
        for(var i = 7; i < 24; i++){
          for(var j = 0; j < 4; j++){
            timeArray.push(i + ":" + quarterHours[j]);
          }
        }
        for(i = 0; i < 7; i++){
          for(j = 0; j < 4; j++){
            timeArray.push(i + ":" + quarterHours[j]);
          }
        }
        const time =
            timeArray.map( (value, index) => {
                var momentInstance = Moment
                var displayTime = momentInstance(value,'h:mm a').format('h:mm a');
                return (
                    <MenuItem
                        key={index}
                        value={value}
                     >{displayTime}</MenuItem>
                )
            })
        return(
            <FormControl
                    disabled={disabled}
                    fullWidth
                >
                <div className="inputBlockWrapper">
                  <InputLabel htmlFor="name-disabled">When?</InputLabel>
                  <Select
                      fullWidth
                      label="When?"
                      index={index}
                      value= {value.time}
                      onChange={handleChange}
                      onOpen={this.handleOpen}
                      className="dayWhen"
                      inputProps={{
                          name: "when"+index,
                          id: 'when',
                      }}
                   >
                      {time}
                  </Select>
                </div>
            </FormControl>
        );
    }
}

export default WhenTime;
