import React, { Component } from 'react';

class AssessmentIntro extends Component {

    constructor(props){
        super(props);
        this.continuePressed = this.continuePressed.bind(this)
    }
    continuePressed(){
        this.props.goForward()
    }
    render(){
        return (
                    <div className="stepContent animate-fadeInDown progress-Info-Wrapper">
                        <i className="topIcon animate-zoomInYellow icon-sun"></i>
                        <h1>Now let's complete your fitness assessment.</h1>
                        <h2>Answer a few more questions about your fitness level to help us build the perfect plan for you.</h2>
                        <button onClick= {this.continuePressed} className="button-teal button-skewed">Sounds Good</button>
                    </div>

        )
    }
}
export default AssessmentIntro;
