import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core';
import NearMeIcon from '@material-ui/icons/NearMeOutlined';
import Button from '@material-ui/core/Button';

const styles = {
    gymMapHeader: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'space-between',
        padding: '0 2.25rem',
    },
    findButton: {
        border: 'solid 1px rgba(151, 151, 151, 0.47)',
        color: '#616365',
        fontSize: '0.9rem',
        fontWeight: '400',
    },
    nearIcon: {
        color: '#3cb6ce',
        fontSize: '1.5rem',
    },
    headerTitle: {
        color: '#37424a',
        fontSize: '1.6rem',
    },
    headerLocationName: {
        fontWeight: 'bold',
        paddingLeft: '7px',
    },
};

class GymMapHeader extends Component {

    getLocationTitle(){
        const {classes} = this.props;
        if (this.props.GymFinderStore.mapLocation.name){
            return (
                <div className={classes.headerTitle}>
                    Select a Club Near
                    <span className={classes.headerLocationName}>
                        {this.props.GymFinderStore.mapLocation.name}
                    </span>
                </div>
            )
        }
    }
    getUseMyLocationButton(){
        const {classes} = this.props;
        //if (window.location.protocol === 'https:'){
            return (
                <Button className={classes.findButton} onClick={this.props.GymFinderStore.findCurrentLocation}><NearMeIcon className={classes.nearIcon}></NearMeIcon> Use My Location</Button>
            )
       // }
    }

    render(){
        const { classes } = this.props;
        return (
            <div className={classes.gymMapHeader}>
                {this.getLocationTitle()}
                {this.getUseMyLocationButton()} 
            </div>
        )
    }

}


GymMapHeader = inject('GymFinderStore', 'GymListingsStore')(observer(GymMapHeader))
export default withStyles(styles)(GymMapHeader);
