import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import FetchOnScroll from '../Shared/FetchOnScroll';
//import './TaskResults.scss'


class TaskResults extends Component {
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this)
        this.handlePreview = this.handlePreview.bind(this)
    }

    handlePreview = (e) => (e) => {
        const {searchResults} = this.props.ChangeDayStore
        var workoutInt
        if (e.currentTarget.value){
            workoutInt = parseInt(e.currentTarget.value)
        }else if (e.currentTarget.getAttribute('value')){
            workoutInt = parseInt(e.currentTarget.getAttribute('value'))
        }
        var item = searchResults[workoutInt]
        var planItem = {}
        planItem.tasks = []
        planItem.tasks[0] = {}
        planItem.tasks[0].task = item
        this.props.PreviewModalStore.planItem = planItem
        this.props.PreviewModalStore.openModal()
    }
    handleChange = (e) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        const {searchResults} = this.props.ChangeDayStore
        const {changeDay} = this.props
        var workoutInt
        if (e.currentTarget.value){
            workoutInt = parseInt(e.currentTarget.value)
        }else if (e.currentTarget.getAttribute('value')){
            workoutInt = parseInt(e.currentTarget.getAttribute('value'))
        }
        var item = searchResults[workoutInt]
        changeDay(item)
    }
    render(){
        const {searchResultsPagesAvailable, searchResultsCurrentPage, dayValues, currentTask,  searchResults, updateInProgress} = this.props.ChangeDayStore
        const getClassResults = ()=> {
            var resultBoxes = []
            searchResults.forEach( (value, index) => {
                var instructor = ''
                if (value.instructors && value.instructors[0]){
                    instructor = value.instructors[0].FirstName
                }
                resultBoxes.push(
                        <div className="classCard" key={index}>
                            <div className='lCard'>
                                <div className="classTitle">{value.name}</div>
                                <div className="classData">
                                    <div> <span className='lightLabel'>with  </span> {instructor} </div>
                                    <div> <span className='lightLabel'>for  </span> {value.duration} min</div>
                                    <div> <span className='lightLabel'>at  </span> {value.formatted_time} at {value.club_name} </div>
                                </div>
                            </div>

                            <div className='rCard'>
                                <button
                                  value={index}
                                  className="classCardButton"
                                  onClick={this.handleChange(value)}
                                >
                                  SELECT
                                </button>
                            </div>
                        </div>
                )
            })
            return resultBoxes
        }

        //get intensity
        //get select button
        const getSelected = (index, value, selected)=> {
          if (selected){
            return (
                <div
                    onClick={this.handleChange(value)}
                    key={index}
                    value={index}
                    className="workoutSelected">
                  <div className="selectedButton">SELECTED</div>
                </div>
            )
          }else{
            return(
                <div className="ctas">
                <button
                    key={index+2000}
                    value={index}
                    className="workoutCardPreview"
                    onClick={this.handlePreview(value)}
                >PREVIEW
                </button>
                <button
                    key={index}
                    value={index}
                    className="workoutCardButton"
                    onClick={this.handleChange(value)}
                >SELECT
                </button>
                </div>
            )
          }

        }
        const getDuration = (value)=> {
            if (value.duration_seconds){
                  var seconds = parseInt(value.duration_seconds)
                  var minutes = Math.round(seconds/60)
                  var format = minutes + ' minutes'
                  if (format){
                      return (
                          <div className="streamDetails"> <strong>Duration:</strong> <span>{format}</span></div>
                      )
                  }
            }
        }
        const getIntensity = (value)=> {
             var intensityVal = value.applied_intensity
            if (intensityVal < 5){
                return (
                      <div className="streamDetails"><strong>Intensity:</strong> <img alt='low' src="/fitplan/imgs/intensity/low.png"  className="changeDayIntensityIcon" /> Low </div>
                )
            }else if (intensityVal < 8){
                return (
                      <div className="streamDetails"><strong>Intensity:</strong> <img alt='med' src="/fitplan/imgs/intensity/medium.png"  className="changeDayIntensityIcon" /> Moderate</div>
                )
            }else if (intensityVal > 7 ){
                return (
                      <div className="streamDetails"><strong>Intensity:</strong> <img alt='high' src="/fitplan/imgs/intensity/high.png"  className="changeDayIntensityIcon"  /> High</div>
                )
            }
        }

        const getResults = ()=> {
            var resultBoxes = []
            //change poster to thumbnail if avail
            searchResults.forEach( (value, index) => {
                var imageString = ''
                if (value.poster) imageString = value.poster
                if (value.thumbnail) imageString = value.thumbnail
                var selected = false
                if (value.id === currentTask.id) selected = true
                resultBoxes.push(
                        <div className="workoutCard" key={index}>
                            <img alt='' className="workoutImage" src={imageString} />
                            <div className="workoutBackground"></div>
                            <div className='workoutDetails'>
                               <div className="workoutTitle">{value.name}</div>
                               {getDuration(value)}
                               {getIntensity(value)}
                            </div>
                            {getSelected(index, value, selected)}
                        </div>
                )
            })
            return resultBoxes
        }
        const getUpdateLoader = () => {
            if (searchResultsCurrentPage === searchResultsPagesAvailable ){
                return (
                    <div className="noResultsText"> No Results </div>
                )
            }
            if ( updateInProgress) {
                return (
                    <div className="lds-dual-ring"></div>
                )
            }
        }
        if (dayValues.type === 'gx_class' && this.props.ClubDataStore.clubDataLoaded){
            return (
                <div className="classResultsBox">
                    <div className="spacer">
                      {getClassResults()}
                      <div className='updateState'>
                          {getUpdateLoader()}
                      </div>
                    </div>
                </div>
            )
        }else{
            return (
                <div className="resultsBox">
                  <div className="spacer">
                    {getResults()}
                    <div className='updateState'>
                        {getUpdateLoader()}
                    </div>
                  </div>
                </div>
            )
        }
    }
}

TaskResults = inject( 'ClubDataStore','PreviewModalStore','ChangeDayStore')(observer(TaskResults))
export default FetchOnScroll(TaskResults);
