import { observable, action,  decorate } from 'mobx';

class ModalStore {
    //observables
    isOpen = false;

    //setSection
    openModal(){
        this.isOpen = true
    }
    closeModal(){
        this.isOpen = false 
    }
}

decorate(ModalStore, {
    isOpen: observable,
    openModal: action,
    closeModal: action
});

export default new ModalStore();

