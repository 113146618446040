import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import TextField from '@material-ui/core/TextField';

class Weight extends Component {

    handleChange = (e) => {
        var value = Number(e.target.value.replace(/\D/g,''));
        this.props.UserStore.setValue(e.target.name,value);
    }
    render(){
        var {weight} = this.props.UserStore.currentUser
        return(
            <div className="stepContent animate-fadeInDown">
                        <h3>How much do you weigh?</h3>
                        <TextField
                            key="0"
                            label="Weight"
                            onChange={this.handleChange}
                            className="checkin_code"
                            placeholder="XXX lbs."
                            name="weight"
                            value={weight}
                            margin="normal"
                            type="tel"
                        />
            </div>
        )
    }
}
Weight = inject('UserStore')(observer(Weight))    
export default Weight;
