import { observable, action, runInAction, decorate  } from 'mobx';
import agent from '../../api/Agent';
import UserStore from '../UserStore';


class WorkoutHistoryStore{
    inProgress = false;
    history = []
    getWorkoutHistory() {
        this.inProgress = true;
        var user_id = UserStore.currentUser.id
        return agent.History.getWorkoutHistory(user_id)
        .then( (history)  => {
            runInAction( () => {
                this.history.replace(history.results)
            })
          })
		.catch(action((err) => {
			alert(err)
			//TODO error handling
			//TODO successfull errors too
			throw err;
		}))
        .finally(action(() => { this.inProgress  = false; }))
    }
}

decorate(WorkoutHistoryStore, {
    inProgress: observable,
    history: observable,
    getWorkoutHistory: action,
});

export default new WorkoutHistoryStore();
