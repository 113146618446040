import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Hidden } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const styles = theme => ({
    validationCode: {
        border: 'solid #000000 2px',
        fontSize: '3.2rem',
        fontWeight: 'bold',
        marginTop: '15px',
        padding: '10px 15px',
        width: 'max-content',
        textAlign: 'center',
        margin:'auto'
    }
});

class ReferralConfirmation extends Component {

    componentDidMount() {
        if (window.analytics) {
            window.analytics.page('guest_pass_referral_confirmation');
        }
    }

    constructor(props){
        super(props);
        this.closeModal = this.closeModal.bind(this)
    }

    closeModal(){
        this.props.ReferralStore.closeModal()
    }

    render() {
        const { classes } = this.props;
        const { club_id, email, pass_code } = this.props.ReferralStore.values;
        const { values } = this.props.ClubDataStore;
        return (
            <div className="stepContent animate-fadeInDown progress-Info-Wrapper">
                <br/>
                <h3>You’re all set.</h3>
                 
                <br/>
                <h4>See you at {values ? values.name : ''}!</h4>
                <h4><strong>Here’s your gym pass code</strong> to take to the club. Don’t worry we also sent an email to {email}</h4>
                <div className={classes.validationCode}>
                    {pass_code}
                </div>

                <br/>
                <div className='buttons'>
                    <button
                    onClick= {this.closeModal}
                    className="button-teal button-skewed">Continue</button>
                </div>
            </div>
        );
    }
}
ReferralConfirmation  = inject('ReferralStore', 'ClubDataStore')(observer(ReferralConfirmation ))
export default withStyles(styles)(ReferralConfirmation );
