import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Components/App/App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'mobx-react';
import { Route, BrowserRouter } from 'react-router-dom';
import * as stores from './Stores';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import 'normalize.css';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#3CB6CE",
            main: "#3CB6CE",
            dark: "#3CB6CE",
            contrastText: "#3CB6CE"
        },
    },
    typography : {
        fontFamily: ['Roboto Condensed','Roboto','Helvetica'].join(','),
        fontSize: "18",
    }
});


ReactDOM.render(
  <Provider { ...stores }>
    <MuiThemeProvider theme={theme}>
        <BrowserRouter basename="/fitplan/">
            <Route component={App} />
        </BrowserRouter>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
