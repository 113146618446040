import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = 'https://api.24go.co';
const STATIC_ROOT = window.location.protocol + '//' +  window.location.hostname + (window.location.port ? `:${window.location.port}` : '')

const handleErrors = err => {
    if (err && err.response && err.response.status === 401) {
        console.log(err.response)
        //alert('Service is unavailable')
    }
    return err;
};


const responseBody = res => res.body;
const responseHTML = res => res.text;

const requests = {
    static_html: url =>
        superagent
            .get(`${API_ROOT}${url}`)
            .accept('Accept', 'text/html')
            .end(handleErrors)
            .then(responseHTML),
    static_asset: url =>
        superagent
            .get(`${STATIC_ROOT}${url}`)
            .withCredentials()
            .set('Accept', 'application/json')
            .end(handleErrors)
            .then(responseBody),
    del: url =>
        superagent
            .del(`${API_ROOT}${url}`)
            .withCredentials()
            .end(handleErrors)
            .then(responseBody),
    get: url =>
        superagent
            .get(`${API_ROOT}${url}`)
            .withCredentials()
            .set('Accept', 'application/json')
            .end(handleErrors)
            .then(responseBody),
    put: (url, body) =>
        superagent
            .put(`${API_ROOT}${url}`, body)
            .type('json')
            .withCredentials()
            .end(handleErrors)
            .then(responseBody),
    post: (url, body) =>
        superagent
            .post(`${API_ROOT}${url}`, body)
            .type('json')
            .withCredentials()
            .end(handleErrors)
            .then(responseBody),
    postForm: (url, body) =>
        superagent
            .post(`${API_ROOT}${url}`)
            .type('form')
            .send(body)
            .withCredentials()
            .end(handleErrors)
            .then(responseBody),
};

const GymLocations = {
    /*
    locations: () =>
        requests.static_asset(`/r/staticApi/gyms.json`)
    */
    
    /*locations: () =>
        requests.static_asset(`/r/staticApi/real_gyms.json`)
    /*
    */
    locations: () =>
        requests.get('/clubs?per_page=500&simple=1')
}

export default {
   GymLocations
};
