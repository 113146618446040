import { observable, action, runInAction, decorate , computed} from 'mobx';
import agent from '../../api/Agent';
import Moment from 'moment';
import ClubDataStore from '../ClubDataStore';
import TrainerSyncStore from '../TrainerSyncStore';
import cookie from 'js-cookie'

import modalStore from '../ModalStore';

class UserStore {

    defaultUser() {
        return {
            id: null,
            available_products: [],
            class_name: '',
            first_name: '',
            last_name: '',
            email: '',
            timezone: '',
            gender: '',
            birthday: '',
            avatar: '',
            height: '',
            weight: '',
            workout_style: '',
            skip_reasons: '',
            motivate_me: '',
            training_types: '',
            club_amenities: '',
            club_group_classes: '',
            club_strength_equipment: '',
            club_cardio_equipment: '',
			pullup: '',
            pushup: '',
            bdw_squat: '',
            non_gym_activities: '',
            endurance_stairs: '',
            single_leg_balance: '',
            flexibility: '',
            improve_my: '',
            frequency: '',
            overall_fitness: '',
            intensity: '',
            primary_goal: '',
            display_goal: '',
            equipment: '',
            last_profile_step: '',
            is_set_up: false,
            is_24go: false,
            is_admin: false,
            is_premium: false,
            is_preview: false,
            is_preview_pt_agreement: false,
            is_near_pt_expiration_date: false,
            is_preview_first_30_days: false,
            first_30_days_expires_sec: null,
            registration_source: null,
            is_syncing_schedule: null,
            is_syncing_healthkit: false,
            is_syncing_googlefit: false,
            is_syncing_fitbit: false,
            is_support: false,
            is_support_admin: false,
            is_support_active: false,
            scheduled_count: 0,
            created_int: null,
            created_sec: null,
            member_id: null,
            member_fitperks_points: 0,
            member_fitperks_level: null,
            member: {},
            is_24hf_employee: false,
            share_code: null,
            splash_logins: 0,
            user_filters: [],
            scheduled_ids: [],
            trainers: [],
            trainer_ids: [],
            onboarders: [],
            onboarder_ids: [],
            // Values not in user object, but needed for interface
            year: 2000,
            month: 0,
            day: 1,
            inches: '',
            feet: '',
            checkin_code: '',
            password: '',
            employee_last_name: '',
            current_step: 0,
            goals: '',
            preferred_club_id: ''
        }
    }

    //observables
    currentUser = this.defaultUser();
    inProgress = false;
    errors = undefined;
    trackedLog = [];

    clearValues(){
        if ( window.analytics ) window.analytics.reset();
        this.currentUser = this.defaultUser();
    }    
    //setter
    setValue(key, value){
        if ( this.currentUser[key] !== undefined ) {
            if (value !== null){
                this.currentUser[key] = value
            }
        }
    }
    setHeight(){
        this.currentUser.height = this.currentUser.inches+this.currentUser.feet*12 
    }


    identifyUser() {
        if ( window.analytics ) window.analytics.identify( this.currentUser.id, this.flattenedMe() );
    }

    flattenedMe() {
        var obj = JSON.parse( JSON.stringify( this.currentUser ) );
        if ( obj.member ) {
            for ( var key in obj.member ) {
                if ( !obj[key] ) obj[key] = obj.member[key];
            }
        }
        obj.fp_24_kiosk_id = cookie.get('fp_24_kiosk_id')
        delete obj.member;
        delete obj.user_filters;
        delete obj.trainers;
        delete obj.onboarders;
        return obj;
    }

    setUser(user){
        for (var key in user) {
            if (user.hasOwnProperty(key)) {
                this.setValue(key, user[key])
            }
        }
        this.identifyUser();
    }

    get isLoggedIn() {
        if (this.currentUser.id){
            return true
        }else{ 
            return false}
    }

    get birthday(){
        const {year, month, day} = this.currentUser
        var momentInstance = Moment
        const bd = momentInstance([year,month,day]).format("YYYY-MM-DD");
        return bd
    }

    createFeetInchesFromHeight(){
        const dataHeight = this.currentUser.height
        if (dataHeight){
            this.currentUser.feet = Math.floor(dataHeight / 12)
            this.currentUser.inches = dataHeight % 12
        }
    }
    
    createYMDfromBirthday(){
        //TODO - is this still needed? 
        if (this.currentUser.birthday){
            var momentInstance = Moment
            var date = momentInstance(this.currentUser.birthday, "YYYY-MM-DD");
            this.currentUser.year = date.year()
            this.currentUser.day = date.date()
            this.currentUser.month = date.month()
        }
    }

    loginCheckIn() {
        this.inProgress = true;
        this.errors = undefined;
        const {checkin_code, employee_last_name } = this.currentUser
        const { birthday } = this
            return agent.Auth.loginCheckIn(birthday,checkin_code, employee_last_name)
            .then((response) => {
                if (response.class_name === "Member"){
                    if (response.first_name){
                        this.setValue('first_name',response.first_name)
                    }
                    if (response.last_name){
                        this.setValue('last_name',response.last_name)
                    }
                    if (response.email){
                        this.setValue('email',response.email)
                    }
                    if (response.class_name){
                        this.setValue('class_name',response.class_name)
                    }
                }else{
                    this.setUser(response)
                    var user_obj = this.flattenedMe();
                    user_obj.club_id = ClubDataStore.values.id;
                    user_obj.club_number = ClubDataStore.club_number;
                    user_obj.trainer_code = TrainerSyncStore.values.share_code;
                    if ( window.analytics ) window.analytics.track( 'Account Login', user_obj );
                }
            })
            .catch(action((err) => {
                if ( window.analytics ) window.analytics.track( 'Validation Error', err.response.body );
                alert(err.response.body.error)
                if (err.status === 401) {
                    //figure this out 
                    modalStore.setSection("employeeLogin") 
                    modalStore.isOpen = true 
                }
                throw err;
            }))
            .finally(action(() => { this.inProgress = false; }));
    }
    confirmationCheckIn() {
        this.inProgress = true;
        this.errors = undefined;
        const {first_name, last_name, email, checkin_code } = this.currentUser
        const { birthday } = this
        var registration_source = 'fitplan';
            return agent.Auth.regCheckIn(
                checkin_code,
                birthday,
                first_name,
                last_name,
                email,
                registration_source
            )
            .then( response => {
                this.setUser( response );
                var user_obj = this.flattenedMe();
                user_obj.club_id = ClubDataStore.values.id;
                user_obj.club_number = ClubDataStore.club_number;
                user_obj.trainer_code = TrainerSyncStore.values.share_code;
                if ( window.analytics ) window.analytics.track( 'Account Created', user_obj );
            })
            .catch(action((err) => {
                if ( window.analytics ) window.analytics.track( 'Validation Error', err.response.body );
                alert(err.response.body.error)
                //TODO error handling
                //TODO successfull errors too
                throw err;
            }))
            .finally(action(() => { this.inProgress = false; }));
    }
    register() {
        this.inProgress = true;
        this.errors = undefined;

        const {first_name, last_name, email, password } = this.currentUser
        const { birthday } = this
        var registration_source = 'fitplan';
        return agent.Auth.register(
            first_name, 
            last_name, 
            email,
            password,
            birthday,
            registration_source
        )
        .then( (response) => {
            this.setUser(response)  
            var user_obj = this.flattenedMe();
            user_obj.club_id = ClubDataStore.values.id;
            user_obj.club_number = ClubDataStore.club_number;
            user_obj.trainer_code = TrainerSyncStore.values.share_code;
            if ( window.analytics ) window.analytics.track( 'Account Created', user_obj );
        })
        .catch(action((err) => {
            //TODO - if we ever want errors to display react side
            //this.errors = err.response.body.error
            alert(err.response.body.error)
            throw err;
         }))
        .finally(action(() => { this.inProgress = false; }));
    }

    resetPassword() {
        this.inProgress = true
        this.errors = undefined;
        const {email } = this.currentUser
        return agent.Auth.reset(email)
        .then( (response) => {
            alert(response.message)
        })
        .catch(action((err) => {
            alert('Password could not be reset')
            throw err;
        }))
        .finally(action(() => {this.inProgress = false; }));
    }

    login() {
        this.inProgress = true;
        this.errors = undefined;
        const {email, password } = this.currentUser
        return agent.Auth.login(email, password)
        .then((response) => {
            this.setUser(response)  
        })
        .catch(action((err) => {
            //TODO - if we ever want errors to display react side
            //this.errors = err.response.body.error
            alert(err.response.body.error)
            throw err;
        }))
        .finally(action(() => { this.inProgress = false; }));
  }
    updateUserPassive(){
        agent.Auth.updateProfilePassive(
            this.currentUser.gender,
            this.currentUser.height,
            this.currentUser.weight,
            this.currentUser.frequency,
            this.currentUser.intensity,
            this.currentUser.goals,
            this.currentUser.primary_goal,
            this.currentUser.equipment,
            this.currentUser.club_amenities ,
            this.currentUser.club_group_classes ,
            this.currentUser.club_strength_equipment ,
            this.currentUser.club_cardio_equipment ,
			this.currentUser.pullup ,
            this.currentUser.pushup ,
            this.currentUser.bdw_squat ,
            this.currentUser.non_gym_activities ,
            this.currentUser.endurance_stairs ,
            this.currentUser.single_leg_balance ,
            this.currentUser.flexibility ,
            this.currentUser.improve_my ,
            this.currentUser.overall_fitness,
            this.currentUser.preferred_club_id
        )
    }
    updateUserAndSave(){
        this.inProgress = true;
        return agent.Auth.updateProfilePassive(
            this.currentUser.gender,
            this.currentUser.height,
            this.currentUser.weight,
            this.currentUser.frequency,
            this.currentUser.intensity,
            this.currentUser.goals,
            this.currentUser.primary_goal,
            this.currentUser.equipment,
            this.currentUser.club_amenities ,
            this.currentUser.club_group_classes ,
            this.currentUser.club_strength_equipment ,
            this.currentUser.club_cardio_equipment ,
			this.currentUser.pullup ,
            this.currentUser.pushup ,
            this.currentUser.bdw_squat ,
            this.currentUser.non_gym_activities ,
            this.currentUser.endurance_stairs ,
            this.currentUser.single_leg_balance ,
            this.currentUser.flexibility ,
            this.currentUser.improve_my ,
            this.currentUser.overall_fitness,
            this.currentUser.preferred_club_id
        )
        .then( (user)  => {
            runInAction( () => {
                if (user.id){
                    this.setUser(user)
                }
                //gen birthday
                this.createYMDfromBirthday()     
                //gen feet / inches 
                this.createFeetInchesFromHeight()
            })
          })
          .finally(action(() => { this.inProgress  = false; }))
    }
    
    logout() {
        this.inProgress = true;
        return agent.Auth.logout()
        .then( (response) => {

            if ( window.analytics ) window.analytics.track( 'Account Logout', {
                club_id: ClubDataStore.values.id,
                club_number: ClubDataStore.club_number,
                trainer_code: TrainerSyncStore.values.share_code
            });

            runInAction( () => {
                this.clearValues()
            })
        })
        .catch(action((err) => {
            //alert("Error logging out")
            //TODO error handling
            //TODO successfull errors too
            throw err;
        }))
        .finally(action(() => { this.inProgress = false; }));
    }
    pullUser() {
        this.inProgress = true;
        return agent.Auth.current()
        .then( (user)  => {
            runInAction( () => {
                if (user.id){
                    this.setUser(user)
                }
                
                //gen birthday
                this.createYMDfromBirthday()     
                //gen feet / inches 
                this.createFeetInchesFromHeight()
            })
          })
          .finally(action(() => { 
               this.inProgress  = false; 
          }))
    }

}

decorate(UserStore, {
    inProgress: observable,
    currentUser: observable,
    setTrainerLogin: action,
    isLoggedIn: computed,
    loadingUser: observable,
    logout: action,
    pullUser: action,
    resetPassword:action
});

export default new UserStore();
