import React, { Component } from 'react';
import LoginForm from './LoginForm.js';
import LoginFormEmail from './LoginFormEmail.js';
import TrainerSyncNotice from './TrainerSyncNotice.js';
import Grid from '@material-ui/core/Grid';


class Login extends Component {
    render() {
        return (

          <div className="appUsers">

            <Grid container spacing={0} className="gc gc-Users">
              <Grid item sm={5} xs={12} className="gi gi-Users-Club">
                <h1>Club Member Sign In</h1>
                <TrainerSyncNotice />
                <h2>For current 24 Hour Fitness Club members please enter your gym membership info.</h2>
                <LoginForm/>
              </Grid>
              <Grid item sm={2} xs={12} className="gi gi-Users-Or or">
                <p><span>OR</span></p>
              </Grid>
              <Grid item sm={5} xs={12} className="gi gi-Users-NonClub">
                <h1>Email Sign In</h1>
                <h2>If you registered with 24GO and you are not a 24 Hour Fitness Club member.</h2>
                <LoginFormEmail/>
              </Grid>
            </Grid>

            {/*
            <div className="login">
                <div className="appUsers">
                    <h1>Login to your 24GO account</h1>
                    <TrainerSyncNotice />
                    <h2>Please login with your 24GO account info:</h2>
                    <LoginForm/>
                    <LoginFormEmail/>
                    <div className="subText">
                      <Link to="/users/create" className="subLink">I don't have a 24GO Account</Link>
                    </div>
                </div>
            </div>
            */}


          </div>


        );
    }
}

export default Login
