import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';

import ProfileInfo from './ProfileInfo.js';
import CurrentPlan from './CurrentPlan.js';
import AllPlans from './AllPlans.js';
import WorkoutHistory from './WorkoutHistory.js';
import CurrentPlanDayInfo from './CurrentPlanDayInfo.js';
import ChangeDayModal from '../ModalsChangePreview/ChangeDayModal.js';
import ChangeTimeModal from '../ModalsChangePreview/ChangeTimeModal.js';
import './Dashboard.scss'


class Dashboard extends Component {

    //if profile is not complete
    //is_set_up !== true , go to profile

    //if user is a trainer
    //if starting profile for first time

    componentWillMount(){
        // is profile complete?
        //make sure we have fresh user
        this.props.UserStore.pullUser()
        .finally(() => {
            if ( window.analytics ) window.analytics.track( 'view_member_dash', {
                club_id: this.props.ClubDataStore.values.id,
                club_number: this.props.ClubDataStore.club_number,
                trainer_code: this.props.TrainerSyncStore.values.share_code
            });
        })
    }

    closeModal(e){
        this.props.CurrentPlanStore.closeModals()
    }

    constructor(props){
        super(props);
        this.closeModal = this.closeModal.bind(this)
        this.handleChangeDay = this.handleChangeDay.bind(this)
        this.handleTimeChange = this.handleTimeChange.bind(this)
    }

    showModal(){
        const {  changeTimeModalIsOpen, changeDayModalIsOpen, planPreviewIndex, plan, currentWeek,  } = this.props.CurrentPlanStore
        const {isOpen} = this.props.PreviewModalStore
        if (isOpen && changeDayModalIsOpen ){
            return (
                <div>
                  <ChangeDayModal all_plan={plan} index={planPreviewIndex} plan={plan.week_objects} closeModal={this.closeModal} week={currentWeek} handleChangeDay={this.handleChangeDay}/>
                  <style>.printFix .appUserDashboard {'{ display:none; }'}</style>
                </div>
            )
        } else if (changeDayModalIsOpen){
            return (
                <div>
                  <ChangeDayModal all_plan={plan} index={planPreviewIndex} plan={plan.week_objects} closeModal={this.closeModal} week={currentWeek} handleChangeDay={this.handleChangeDay}/>
                  <style>.printFix .appUserDashboard {'{ display:none; }'}</style>
                </div>
            )
        }else if (changeTimeModalIsOpen) {
            return (
                  <ChangeTimeModal handleChangeTime={this.handleTimeChange} index={planPreviewIndex} plan={plan.week_objects} closeModal={this.closeModal} week={currentWeek}/>
            )
        }
    }

    handleTimeChange(item){
        var {dayValues} = this.props.ChangeDayStore
        var mi = Moment
        var timeAndDate = mi(dayValues.start_date +' '+ dayValues.time , 'YYYY-M-D H:mm');
        this.props.ChangeDayStore.setValue("task_int", timeAndDate.format('x'))
        this.props.ChangeDayStore.setValue('task_type', "Stream")
        this.props.ChangeDayStore.updateDay()
        .then( () => {
            this.closeModal()
            this.props.CurrentPlanStore.getCurrentPlan(true)
        })
    }

    handleChangeDay(item, index){
        //called from changeDaymodal
        //
        var {dayValues} = this.props.ChangeDayStore
        var mi = Moment
        var timeAndDate = mi(dayValues.start_date +' '+ dayValues.time , 'YYYY-M-D H:mm');
        //
        if (this.props.ChangeDayStore.dayValues.type && this.props.ChangeDayStore.dayValues.type === 'gx_class'){
	        this.props.ChangeDayStore.setValue('task_type', "GroupClass")
            this.props.ChangeDayStore.setValue("task_int", item.start_int)
            //TODO - this probably isn't a real class - mark as stream
            if (!item.start_int){
	            this.props.ChangeDayStore.setValue('task_type', "Stream")
            }
		}else{
            this.props.ChangeDayStore.setValue("task_int", timeAndDate.format('x'))
	        this.props.ChangeDayStore.setValue('task_type', "Stream")
		}
        this.props.ChangeDayStore.setValue('task_id', item.id)
        this.props.ChangeDayStore.setValue('user_plan_id', this.props.CurrentPlanStore.plan.id)

        if ( window.analytics ) {
            var filters_obj = JSON.parse( JSON.stringify( this.props.ChangeDayStore.dayValues ) );
            delete filters_obj.club_id;
            delete filters_obj.group_class_id;
            delete filters_obj.stream_id;
            delete filters_obj.is_rest_day;
            delete filters_obj.start_date;
            delete filters_obj.task_date;
            delete filters_obj.user_plan_task_id;
            window.analytics.track( 'fitplan_workout_selected', filters_obj );
        }

        if (this.props.ChangeDayStore.mode === 'edit'){
            this.props.ChangeDayStore.updateDay()
            .then( () => {
                this.closeModal()
                this.props.CurrentPlanStore.getCurrentPlan(true)
            })
        }else{
            this.props.ChangeDayStore.createDay()
            .then( () => {
                this.closeModal()
                this.props.CurrentPlanStore.getCurrentPlan(true)
            })

        }

    }

    render() {
        const { inProgress } = this.props.UserStore
        var planStoreInProgress = this.props.CurrentPlanStore.inProgress
        var changeStoreInProgress = this.props.ChangeDayStore.inProgress
        const showPlanLoading = ()=> {
            if (planStoreInProgress || changeStoreInProgress){
            return (
              <div className="appLoader">
                <div className="appLoadingSpinner">
                  <div className="loader"></div>
                  <span className="loadingText"></span>
                </div>
              </div>
            )
            }
        }
        
        if (!inProgress ) {
            return (
                <div className="printFix">
                  <div className="appOverflowContent appUserDashboard">
                    <ProfileInfo />
                    <div className="fatSep fatSepFull"></div>
                    <CurrentPlan />
                    <CurrentPlanDayInfo />
                    <div className="fatSep fatSepFull"></div>
                    <AllPlans />
                    <div className="fatSep fatSepFull"></div>
                    <WorkoutHistory />
                  </div>
                  {showPlanLoading()}
                  {this.showModal()}
                </div>
            );
        }else{
            return (
                  <div className="appLoader">
                    <div className="appLoadingSpinner">
                      <div className="loader"></div>
                      <span className="loadingText"></span>
                    </div>
                  </div>
            )
        }
    }
}

Dashboard = inject('CurrentPlanStore','UserStore', 'ClubDataStore','ChangeDayStore','TrainerSyncStore', 'PreviewModalStore')(observer(Dashboard))
Dashboard = withRouter(Dashboard)
export default Dashboard
