import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import ConfirmationForm from './ConfirmationForm.js';

class Confirmation extends Component {
    showTerms(){
            this.props.ModalStore.setSection('terms')
            this.props.ModalStore.setCloseVisible()
            this.props.ModalStore.openModal()
    }
    constructor(props){
        super(props);
        this.showTerms = this.showTerms.bind(this)
    }
    render() {
        return (
              <div className="appUsers">
                <h1>Confirm your personal info</h1>
                <h2>Please verify the following details we have for you are accurate:</h2>
                <ConfirmationForm/>

                <div className="subText">By continuing I agree to the 
                    <button
                        className="subLink"
                        onClick={this.showTerms}
                    >Terms Of Service
                    </button>
                </div>
              </div>
        );
    }
}

Confirmation = inject('ModalStore')(observer(Confirmation))
export default Confirmation;
