import React, { Component } from 'react';

class WelcomeBack extends Component {

    constructor(props){
        super(props);
        this.continuePressed = this.continuePressed.bind(this)
    }
    continuePressed(){
        this.props.goForward()
    }
    render(){
        const {first_name} = this.props
        return (

                    <div className="stepContent animate-fadeInDown progress-Info-Wrapper">
                
                        <i className="topIcon animate-zoomInYellow icon-sun"></i>
                        <h1>Welcome back, {first_name}! Ready to complete your profile?</h1>
                        <h2>Take a few minutes to tell us about yourself so we can personalize a Fit Plan for you.</h2>
                        <button onClick= {this.continuePressed} className="button-teal button-skewed">Let's Do It</button>
                      </div>

        )
    }
}
export default WelcomeBack;
