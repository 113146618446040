import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import classNames from 'classnames/bind'
import { Grid } from '@material-ui/core';
import IconsFactory from '../Icons/IconsFactory';
import './ClubGroupClasses.scss';
class ClubGroupClasses extends Component {

    state = {
        checked: [],
    };

    data =  [
            {
                "name": "dance",
                "label": "Dance Classes",
                "description": "Zumba, U-Jam Fitness, Cardio Dance Party",
            },
            {
                "name": "combat",
                "label": "Martial Arts Classes",
                "description": "BODYCOMBAT, Turbo Kick LIVE, STRONG by Zumba, Kickboxing",
            },
            {
                "name": "yoga",
                "label": "Yoga Classes",
                "description": "Yoga, BODYFLOW, Yoga-lates, Silver Sneakers Yoga",
            },
            {
                "name": "strength",
                "label": "Strength Classes",
                "description": "BODYPUMP, Strength Training, LES MILLS GRIT",
            },
            {
                "name": "cardio",
                "label": "Cardio Classes",
                "description": "Cardio Step, Core Cardio & More, BODYATTACK",
            },
            {
                "name": "cycle",
                "label": "Cycle Classes",
                "description": "RPM, Les Mills Sprint, Cycle",
            },
            {
                "name": "pool",
                "label": "Pool Classes",
                "description": "Aqua, Aqua Zumba, Aqua Bootcamp",
            },
            {
                "name": "active_aging",
                "label": "Active Aging Classes",
                "description": "Silver Sneakers Classes, Silver Sneakers Yoga, Senior Fitness, Zumba Gold",
            },
            {
                "name": "pilates_barre",
                "label": "Pilates & Barre Classes",
                "description": "POP Pilates, Barre, PiYo LIVE, Pilates, bootybarre",
            },
            {
                "name": "athletic_training",
                "label": "Athletic & HIIT Training Classes",
                "description": "Bootcamp, ATC (Athletic Training Club), INSANITY LIVE, HIIT",
            }
    ]

    constructor(props){
        super(props);
        this.handleToggle = this.handleToggle.bind(this)
    }
    handleToggle = (value) => () => {
        const {ident} = this.props
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({
            checked: newChecked,
        });


        var correctData = []
        newChecked.forEach( ( value ) => {
            correctData.push(this.data[value].name)
        })
        var pipeString = correctData.join("|") 
        this.props.UserStore.setValue(ident,pipeString)
    };
    componentWillMount(){
        const {ident} = this.props
        //set state from user store
        //TODO copy pasta from render
        var selectedItems = '' 
        if (this.props.UserStore.currentUser[ident]){
            selectedItems  = this.props.UserStore.currentUser[ident] 
        }
        selectedItems = selectedItems.split('|')
        var selectedIndexes = []
        selectedItems.forEach( (selectedValue)=>{
            this.data.forEach( (dataValue, index )=> {
                if(selectedValue === dataValue.name){
                   selectedIndexes.push(index) 
                }
            })
        })
        this.setState({
            checked: selectedIndexes,
        });
    }

    render(){
        const {ident} = this.props
		const checkboxes = ()=> { 
			var components = [] 
            var selectedItems = '' 
            if (this.props.UserStore.currentUser[ident]){
                selectedItems  = this.props.UserStore.currentUser[ident] 
            }
            selectedItems = selectedItems.split('|')
            var selectedIndexes = []
            selectedItems.forEach( (selectedValue)=>{
                this.data.forEach( (dataValue, index )=> {
                    if(selectedValue === dataValue.name){
                       selectedIndexes.push(index) 
                    }
                })
            })
			this.data.forEach( (value, index)=> {

                var cardClassNames = classNames(
                    'group-item-card', {
                    'group-item-card-selected': (selectedIndexes.indexOf(index) !== -1)
                });
				components.push(
                    <Grid 
                        item xs={12} sm={6} md={4} className="group-item"
                        onClick={this.handleToggle(index)}
                        key={index}
                    >
                        <Grid container direction='column' alignItems='center' className={cardClassNames}>
                            {IconsFactory.build(value.name, {nativeColor: '#000', className:'icon'})}
                            <h5>{value.label}</h5>
                            <p>{value.description}</p>
                            <input
                                type='checkbox'
                                checked={selectedIndexes.indexOf(index) !== -1}
                                className="card-checkbox"
                                readOnly
                            />
                        </Grid>
                    </Grid>
 				)
			})	
			return components
		}

        return (
            <div className="planMarginTopFix stepContent animate-fadeInDown">
            <h3>What types of group classes, if any, are you interested in?</h3>
            <h4>Select all that apply</h4>
            <Grid container spacing={24} justify="center" className="group-class">
                {checkboxes()}	
            </Grid>
            </div>
        )
    }
}

ClubGroupClasses = inject('UserStore')(observer(ClubGroupClasses))    
export default ClubGroupClasses;
