import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import Moment from 'moment';
import ActiveDay from '../CalendarItems/ActiveDay'
import RestDay from '../CalendarItems/RestDay'
import classNames from 'classnames/bind';
import { IconBackCircle} from '../Icons/Icons';

class CurrentPlanDayInfo extends Component {

    showPreview(e){
        const { plan, currentWeek } =this.props.CurrentPlanStore
        var index = Number(e.target.value)
        var previewPlanItem = plan.week_objects[currentWeek].days[index]
        this.props.PreviewModalStore.planItem = previewPlanItem  
        this.props.PreviewModalStore.openModal()
    }
    showCreate(e){
        //change mode to create
        //could possibly cut the mode stuff, and move everything to componentDidMount on Modal , if there are no tasks in the day , go create, otherwise we edit
        this.props.ChangeDayStore.setMode("create")
        this.props.ChangeDayStore.currentTask = {}
        this.props.CurrentPlanStore.setPlanPreviewIndex(e.currentTarget.value)
        this.props.CurrentPlanStore.openChangeDayModal()
    }
    showChange(e){
        //change mode to edit 
        this.props.ChangeDayStore.setMode("edit")
        if (e.currentTarget.value) this.props.CurrentPlanStore.setPlanPreviewIndex(e.currentTarget.value) 
        if (e.target.value) this.props.CurrentPlanStore.setPlanPreviewIndex(e.target.value)
        this.props.CurrentPlanStore.openChangeDayModal()
    }
    showTimeChange(e){
        //change mode to edit 
        this.props.ChangeDayStore.setMode("edit")
        if (e.currentTarget.value) this.props.CurrentPlanStore.setPlanPreviewIndex(e.currentTarget.value) 
        if (e.target.value) this.props.CurrentPlanStore.setPlanPreviewIndex(e.target.value)
        this.props.CurrentPlanStore.openTimeChangeModal()
    }
    constructor(props){
        super(props);
        this.showPreview = this.showPreview.bind(this)
        this.showChange = this.showChange.bind(this)
        this.showTimeChange = this.showTimeChange.bind(this)
        this.showCreate = this.showCreate.bind(this)
        this.goBack = this.goBack.bind(this)
        this.goForward = this.goForward.bind(this)
    }

    goBack(){
        this.props.CurrentPlanStore.currentWeek = this.props.CurrentPlanStore.currentWeek-1    
    }

    goForward(){
        this.props.CurrentPlanStore.currentWeek = this.props.CurrentPlanStore.currentWeek+1    
    }

    render(){
        const {currentWeek,  plan } = this.props.CurrentPlanStore
        var momentInstance = Moment

        const getPreviousButton = ()=> {
            const {currentWeek, weeksCount  } = this.props.CurrentPlanStore
            if (plan && plan.week_objects && currentWeek > 0 && weeksCount-1 > 0  ){
                    return (
                        <button
                            onClick={this.goBack}
                            className="calendarNavPrev"
                        >
                            <IconBackCircle nativeColor="#000" id="icon" className="icon icon-back-circle" />
                        </button>
                    )
            }else{
                return (
                        <button
                            disabled={true}
                            className="calendarNavPrev"
                        >
                            <IconBackCircle nativeColor="#CCC" id="iconDisabled" className="iconDisabled icon-back-circle" />
                        </button>
                )
            }
        }
        const getWeekTitle = () => {
            if (plan && plan.week_objects && plan.week_objects[currentWeek] && plan.week_objects[currentWeek].days){
                var fDay = plan.week_objects[currentWeek].days[0]
                var lDay = plan.week_objects[currentWeek].days[6]
                var momentFDay = momentInstance(fDay.day_str).startOf('day')
                var momentLDay = momentInstance(lDay.day_str).startOf('day')
                return (
                    <div className="weekTitle"> Week {currentWeek+1} : {momentFDay.format('MMMM Do')} - {momentLDay.format('MMMM Do')} </div> 
                )
            }else{
                return (
                    <div></div>
                )
            }
        }
        const getNextButton = () => {
            const {currentWeek, weeksCount  } = this.props.CurrentPlanStore
            if (plan && plan.week_objects && currentWeek < weeksCount-1){
                return (
                    <button
                        onClick={this.goForward}
                        className="calendarNavNext"
                    >
                        <IconBackCircle nativeColor="#000" id="icon" className="icon icon-back-circle" />
                    </button>
                )
            }else{
                return (
                    <button
                        disabled={true}
                        className="calendarNavNext"
                    >
                        <IconBackCircle nativeColor="#CCC" id="iconDisabled" className="iconDisabled icon-back-circle" />
                    </button>
                )
            }
        }

        const getDays = ()=> {
            var dayBoxes = []
            if (plan && plan.week_objects && plan.week_objects[currentWeek] && plan.week_objects[currentWeek].days){
                plan.week_objects[currentWeek].days.forEach( ( day, index ) => {
                   var momentDay = momentInstance(day.day_str).startOf('day')
                    if (day.tasks && day.tasks.length > 0){
                        if (day.tasks && day.tasks[0] && day.tasks[0].task){
                            if (day.tasks[0].task.name === "Rest Day"){
                                day.is_rest_day = true
                            }
                        }
                    }
                   if  (day.is_rest_day){
                        var isToday = false
                        var today = momentInstance().startOf('day')
                        if (today.diff(momentDay, 'days') === 0){
                           isToday = true
                        }

                        var dayStyles = classNames({
                            day: true,
                            dayRest: true ,
                            dayCurrent: isToday
                        })

                        //figure out if this is a rest day with a task or just a rest day
                        //if so, show change is not show create
                        if (day.tasks && day.tasks.length > 0){
                            dayBoxes.push(
                                <RestDay replaceActive={true} styles={dayStyles} key={index} showChange={this.showChange} index={index} day={day}/>
                            )
                        }else{
                            dayBoxes.push(
                                <RestDay replaceActive={true} styles={dayStyles} key={index} showChange={this.showCreate} index={index} day={day}/>
                            )
                        }
                    }else{
                        dayBoxes.push(
                            <ActiveDay key={index} showChange={this.showChange} showTimeChange={this.showTimeChange} showPreview={this.showPreview} index={index} day={day}/>
                        )
                    }
                    return
                })
            }
            return dayBoxes
        }

       const getCalendarNav = ()=> {
            if (plan && plan.week_objects && plan.week_objects[currentWeek] && plan.week_objects[currentWeek].days){

                return (
              <div className="calendarNav">
                    {getPreviousButton()}
                    {getWeekTitle()}
                    {getNextButton()}
              </div>
                )
            }
       }

        return (
            //switch on data
            <div className="planCalendarWeek">
            
                {getCalendarNav()}
                <div className="calendarContent">
                    {getDays()}
                </div>
            </div>
        )
    }
}
CurrentPlanDayInfo = inject('PreviewModalStore','CurrentPlanStore','ChangeDayStore')(observer(CurrentPlanDayInfo))
export default CurrentPlanDayInfo ;
