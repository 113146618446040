import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Moment from 'moment';

class ActiveDay extends Component {
    render(){
        const { index, day, showPreview, showChange, showTimeChange  } = this.props
        var momentInstance = Moment
        const task = day.tasks[0]

        var taskDay = momentInstance(day.day_str).startOf('day')
        var today = momentInstance().startOf('day')
        var isToday = false

        if (today.diff(taskDay, 'days') === 0){
           isToday = true
        }

        var dayStyles = classNames({
            day: true,
            dayCompleted: day.tasks[0].is_completed ,
            dayCurrent: isToday
        })

        const duration = ()=> {
            if (task.task.class_name === "GroupClass"){
               return(
                  <div className="dayDuration">for {task.task.duration} minutes</div>
               )
            }else{
                var seconds = parseInt(task.task.duration_seconds)
                if (!isNaN(seconds)){
                    var format =  momentInstance.duration(seconds,'seconds').minutes() + ' minutes'
                    return (
                          <div className="dayDuration">for {format}</div>
                    )
                }else{
                    return null
                }
            }
        }
        const time = ()=> {
            //console.log(task)
            var start = momentInstance(task.task_int, "x")
            if (isNaN(start)){
                start = ''
            }else{
                start = start.format('h:mm A')
            }


            if  (task.task && task.task.formatted_time){
                start = task.task.formatted_time
            }

            var location = ''
            if (task.task.class_name === "GroupClass"){
                location =  task.task.club_name
            } else if (task.task.class_name === "Stream"){
                if (task.is_club === true){
                    location = 'club'
                }else{
                    location = 'home'
                }
            }
            if (start && location){
                if (task.task.class_name === "GroupClass"){
                   return (
                      <div className="dayTimeLocation">
                       <strong>{start}</strong> at <strong>{location}</strong>
                      </div>
                   )
                }else{
                   return (
                      <div className="dayTimeLocation">
                       <strong>
                       <button className='timeButton'
                                value={index}
                                onClick={showTimeChange}
                        >{start}</button></strong> at <strong>{location}</strong>
                      </div>
                   )
                }
            }
        }
        const type = ()=> {
            if (task.task.name === "Fit Appointment Workout"){
                return (
                      <div className="dayType">Fit Appointment Workout</div>
                )
            }
            if (task.task.name === "Personal Training Session"){
                return (
                      <div className="dayType">Personal Training Session</div>
                )
            }
            if (task.task.is_manual_log && task.task.id === 627 ){
                return (
                      <div className="dayType">Class</div>
                )
            }
            if (task.task.is_manual_log && task.task.id !== 627 ){
                return (
                      <div className="dayType">Activity</div>
                )
            }
            if (task.theme === "activity"){
                return (
                      <div className="dayType">Activity</div>
                )
            }
            if (task.task.class_name === "Stream" ){
                return (
                      <div className="dayType">Digital Workout</div>
                )
            }else{
                return (
                      <div className="dayType">Class</div>
                )
            }
        }

        const themeObject = {
            'Full Body Strength':'full_body_strength',
            'Upper Body Strength':'upper_body_strength',
            'Lower Body Strength':'lower_body_strength',
            'Abs/Core':'abs_core',
            'Cardio': 'cardio',
            'Combo - Cardio/Strength' : 'combo_cardio_strength',
            'Flexibility/Recovery':'flexibility_recovery',
            'Class':'gx_class',
            'Fit Appointment Workout':'fit_appointment_workout',
            'PT Session':'pt_session',
            'Strength':'strength',
            'Core' : 'core',
            'Daily Challenge' : 'daily_challenge',
            'Stretch':'stretch',
            'Recovery':'recovery',
            'Combo':'combo',
            'Activity':'activity'
        }
        const getTheme = () => {
            for (var key in themeObject) {
                if ( themeObject.hasOwnProperty(key)) {
                    //console.log(key + " -> " +  themeObject[key]);
                    if (task.theme === themeObject[key]){
                        return (
                            <span className="themeTitle">{key}</span>
                        )
                    }
                }
            }
        }
        const intensity = ()=> {
             var intensityVal = task.task.applied_intensity
            if (intensityVal < 5){
                return (
                      <div className="dayIntensity"><img alt='low' src="/fitplan/imgs/intensity/low.png" id="icon" className="icon icon-intensity-low" /> Intensity</div>
                )
            }else if (intensityVal < 8){
                return (
                      <div className="dayIntensity"><img alt='med' src="/fitplan/imgs/intensity/medium.png" id="icon" className="icon icon-intensity-medium" /> Intensity</div>
                )
            }else if (intensityVal > 7 ){
                return (
                      <div className="dayIntensity"><img alt='high' src="/fitplan/imgs/intensity/high.png" id="icon" className="icon icon-intensity-high" /> Intensity</div>
                )
            }
        }
        const getButton = ()=> {
            if (task.task.class_name !== 'Tag'){
                return (
                    <div className="dayBottomAction">
                        <div className='dayButton preview'>
                        <button
                            onClick={showPreview}
                            className=""
                            value={index}
                        >PREVIEW</button>
                        </div>
                        <div className='dayButton edit'>
                            <button
                                onClick={showChange}
                                className=""
                                value={index}
                            >CHANGE</button>
                        </div>
                    </div>
                )
            }
            if (task.task.class_name === 'Tag'){
                return (
                    <div className="dayBottomAction">
                        <div className='dayButton edit'>
                            <button
                                onClick={showChange}
                                className=""
                                value={index}
                            >CHANGE</button>
                        </div>
                    </div>
                )
            }
        }
        return (
                <div className={dayStyles} value={index} >
                    <div className="dayNumber">{taskDay.format('DD').replace(/^0+/, '')}</div>
                    <div className="dayName">{taskDay.format('ddd')}</div>
                    <div className="dayTheme">{getTheme()}</div>
                    <div className="dayImage">
                        <img alt='' src={task.task.poster} />
                    </div>
                    <div className="dayTopActions">

                    </div>
                    <div className="dayTitle">{task.task.name}</div>
                    {time()}
                    {duration()}
                    {intensity()}
                    {type()}
                    {getButton()}
                </div>
        )
    }
}


export default ActiveDay;
