import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

class PrivateRoute extends React.Component {

    render() {

        const { UserStore, CommonStore,  path, ...restProps } = this.props;
        
        //proceed to route if logged in, otherwise store destination and login

        if (UserStore.isLoggedIn) {
            CommonStore.afterLoginRoute = ''
            return <Route {...restProps} />;
        } else {
            CommonStore.afterLoginRoute = path 
            return <Redirect to="/" />;
        }

    }
}

PrivateRoute = inject('UserStore', 'CommonStore')(observer(PrivateRoute))     
export default PrivateRoute
