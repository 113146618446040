import { observable, action, decorate } from 'mobx';
import GymListingsStore from '../GymListingsStore';
import ModalStore from '../ModalStore';

class GymFinderStore {
    MAPBOX_TOKEN ='pk.eyJ1IjoiZHJld2RvZHNvbiIsImEiOiJjajZncTEwbXEwYnd2Mnhwa2NhOWlwcHk4In0.ye0gOtLymf1jAYhruU9dxg'; 
    //observables
    inProgress = false;
    showFailure = false;
    mapLocation = {
        lat: 37.7577,
        lon: -122.4376,
        name: 'San Francisco'
    }
    popupInfo = ''
    viewport = {
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 11,
        width: 400,
        height: 800
    }

    //actions
    locationUpdated(result) {
        if (result.geometry) {
            this.mapLocation.lon = result.geometry.coordinates[0];
            this.mapLocation.lat = result.geometry.coordinates[1];
            this.mapLocation.name = result.text;
        }
        GymListingsStore.getClosestGyms(30);
    }

    findCurrentLocation = () => {
        ModalStore.openModal();
        navigator.geolocation.getCurrentPosition(this.getLocationByLatLon, this.geoError);
    }
    geoSuccess = (position) => {
        ModalStore.closeModal();
        const lon = position.coords.longitude;
        const lat = position.coords.latitude;
        const new_viewport = {
            latitude: lat,
            longitude: lon
        }
        this.viewport = { ...this.viewport, ...new_viewport };

        const result = {};
        result.geometry = {};
        result.geometry.coordinates = [];
        result.geometry.coordinates[0] = lon;
        result.geometry.coordinates[1] = lat;
        result.geometry.text = '';
        result.text = position.text ? position.text : '';
        this.locationUpdated(result);

    }
    geoError = (error) => {
        console.debug(error);
        this.showFailure = true;
        GymListingsStore.getClosestGyms(30);
    }
    
    finishShowingError = () => {
        this.showFailure = false;
        ModalStore.closeModal();
    }

    getLocationByZipCode = (zipcode) => {
        let filter = 'country=us&';
        this.getLocationFromApi(zipcode, filter);
    }
    getLocationByLatLon = (position) => {
        const lon = position.coords.longitude;
        const lat = position.coords.latitude;
        const param = lon + ', '.toString(16) + lat;
        this.getLocationFromApi(param);
    }

    /**
     * Common call to the mapbox API to get a specific place info, it will return the first occurrence that matches the parameters.
     * @param the place name - zip code - lat and lon - to be found
     * @filter mapbox filters
     */
    getLocationFromApi = (param, filters) => {
        const url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/' + param + '.json?' + (filters ? filters : '') + 'access_token=' + this.MAPBOX_TOKEN;
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
                // If the request has no results then keep the current position
                if (data.features.length > 0) {
                    const location = data.features[0];
                    const position = {
                        coords: {
                            longitude: location.geometry.coordinates[0],
                            latitude: location.geometry.coordinates[1]
                        },
                        text: location.context[0].text
                    }
                    this.geoSuccess(position);
                } else {
                    window.alert('Location services failed');
                    ModalStore.closeModal();
                }
            });
    }
}


decorate( GymFinderStore , {
    viewport: observable,
    inProgress: observable,
    popupInfo: observable,
    gyms:observable,
    mapLocation: observable,
    showFailure: observable,
    getGyms: action,
    sortGymsOnLocation: action,
    locationUpdated: action ,
    findCurrentLocation: action
})

export default new GymFinderStore()
