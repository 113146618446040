import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);


//var API_ROOT = window.location.protocol + '//api.' + window.location.hostname
const API_ROOT = 'https://api.24go.co';
const STATIC_ROOT = window.location.protocol + '//' +  window.location.hostname

//API_ROOT = 'http://api.staging.24go.io';
//API_ROOT = 'https://api.24go.co';
//
//
const handleErrors = err => {
    if (err && err.response && err.response.status === 401) {
        console.log(err.response)
        //alert('Service is unavailable')
    }
    return err;
};

const responseBody = res => res.body;
const responseHTML = res => res.text;

const requests = {
    static_html: url =>
        superagent
            .get(`${API_ROOT}${url}`)
            .accept('Accept', 'text/html')
            .end(handleErrors)
            .then(responseHTML),
    static_asset: url =>
        superagent
            .get(`${STATIC_ROOT}${url}`)
            .withCredentials()
            .set('Accept', 'application/json')
            .end(handleErrors)
            .then(responseBody),
    del: url =>
        superagent
            .del(`${API_ROOT}${url}`)
            .withCredentials()
            .end(handleErrors)
            .then(responseBody),
    get: url =>
        superagent
            .get(`${API_ROOT}${url}`)
            .withCredentials()
            .set('Accept', 'application/json')
            .end(handleErrors)
            .then(responseBody),
    put: (url, body) =>
        superagent
            .put(`${API_ROOT}${url}`, body)
            .type('json')
            .withCredentials()
            .end(handleErrors)
            .then(responseBody),
    post: (url, body) =>
        superagent
            .post(`${API_ROOT}${url}`, body)
            .type('json')
            .withCredentials()
            .end(handleErrors)
            .then(responseBody),
    postForm: (url, body) =>
        superagent
            .post(`${API_ROOT}${url}`)
            .type('form')
            .send(body)
            .withCredentials()
            .end(handleErrors)
            .then(responseBody),
};

const GO24 = {
    getRegistrationData: () =>
        requests.get(`/registration/`)
}

const Auth = {
    current: () => {
        var random = new Date().getTime();
        return requests.get(`/users/me?ran=${random}`)
    },
    login: (email, password) => 
        requests.post('/users/login', {  email, password } ),
    logout: (email, password) => 
        requests.post('/users/logout', { } ),
    loginCheckIn: (birthday, checkin_code, last_name) => {
        return requests.post('/users/auth24', {  birthday, checkin_code , last_name } )
    },
    regCheckIn: (checkin_code, birthday, first_name, last_name, email, registration_source) =>
        requests.post('/users/auth24', {  checkin_code, birthday, first_name, last_name, email, registration_source } ),
    register: (first_name, last_name, email, password, birthday, registration_source) =>
        requests.post('/users/register', {  first_name, last_name, email, password, birthday, registration_source } ),
    reset: (email) =>
        requests.post('/reset/', {  email } ),
    updateProfilePassive: (
            gender,
            height,
            weight,
            frequency,
            intensity,
            goals,
            primary_goal,
            equipment,
            club_amenities ,
            club_group_classes ,
            club_strength_equipment ,
            club_cardio_equipment ,
			pullup ,
            pushup ,
            bdw_squat ,
            non_gym_activities ,
            endurance_stairs ,
            single_leg_balance ,
            flexibility ,
            improve_my ,
            overall_fitness,
            preferred_club_id
        ) => {
        return requests.put('/users/me', { 
            gender,
            height,
            weight,
            frequency,
            intensity,
            goals,
            primary_goal,
            equipment,
            club_amenities ,
            club_group_classes ,
            club_strength_equipment ,
            club_cardio_equipment ,
			pullup ,
            pushup ,
            bdw_squat ,
            non_gym_activities ,
            endurance_stairs ,
            single_leg_balance ,
            flexibility ,
            improve_my ,
            overall_fitness,
            preferred_club_id
        })
    }
};
const Club = {
    getClubData: (club_number) =>
        requests.get(`/clubs/number/${club_number}`),
}
const TrainerSync = {
    sync: (share_code, is_editor) =>
        requests.post('/user_clients', {share_code, is_editor}),
    //link: (share_code) =>
    //    requests.static_asset(`/staticApi/trainer.json`),
    link: (share_code) =>
        requests.post('/user_onboarders', {share_code}),
    lookup: (share_code) => {
        var random = new Date().getTime();
        return requests.get(`/user_clients/share_code/${share_code}?ran=${random}`)
    }
}
const WeekClasses = {
    getClasses: (start_int, stop_int, per_page, clubs, onboarding_day) => {
        var random = new Date().getTime();
        return requests.get(`/group_classes?start_int=${start_int}&stop_int=${stop_int}&per_page=100&clubs=${clubs}&onboarding_day=${onboarding_day}&ran=${random}`)
    }
}

const Plans = {
    previewPlan: (plan_id, gts_score, cardio_score, timezone, start_date,  workout_day_objects)  => {
        return requests.post('/user_plans/preview', {plan_id, gts_score, cardio_score, timezone, start_date,  workout_day_objects} )
    },
    createPlan: (plan_id, gts_score, cardio_score, weeks, timezone, start_date, custom_name, workout_day_objects)  => {
        return requests.post('/user_plans', {plan_id, gts_score, cardio_score, weeks, timezone, start_date, custom_name, workout_day_objects} )
    },
    getRecs: (workout_days, plan_id) => {
        //return requests.static_asset(`/staticApi/rec.json`)
        return requests.get(`/plans/${plan_id}/recommended_what?workout_days=${workout_days}`)
    },
    getCurrentPlan: (user_id) => {
        var random = new Date().getTime();
        return requests.get(`/user_plans?user_id=${user_id}&ran=${random}`)
        //return requests.static_asset(`/staticApi/other_kind.json`)
    },
    getAllPlans: (user_id) => {
        var random = new Date().getTime();
        return requests.get(`/user_plans?user_id=${user_id}&all=1&ran=${random}`)
    },
	quitPlan: (id) => {
        return requests.del(`/user_plans/${id}`)
	},
    changeDay:(task_int, task_type, task_id, user_plan_task_id, theme, is_club) => {
        return requests.put(`/user_plan_tasks/${user_plan_task_id}`,{task_int, task_type, task_id, theme, is_club})
    },
    createDay:(user_plan_id, task_int, task_type, task_id, theme, is_club) => {
        return requests.post(`/user_plan_tasks`,{user_plan_id, task_int, task_type, task_id, theme, is_club})
    }
}

const History = {
    getWorkoutHistory: (user_id) => {
        var random = new Date().getTime();
        return requests.get(`/workout_history?user_id=${user_id}&page=1&per_page=50&completed_stop=${random}&ran=${random}`)
    }
}
const Stream = {
    getStream: (id) => {
        return requests.get(`/streams/${id}?full=1`)
    },
    getStreams: (url) => {
        return requests.get(`/streams/${url}`)
    }
}
const App = {
    getVersion: ()=> {
        var random = new Date().getTime();
        return requests.static_asset(`/fitplan/version.json?ran=${random}`) 
    }
}
const GymLocations = {
    locations: () =>
        requests.get('/clubs?per_page=500&simple=1')
}
const Ref = {
    create: (
            club_id,
            first_name,
            last_name,
            zipcode,
            email,
            phone,
            birthday,
            is_sms_allowed,
            is_test,
            source
    ) =>
        requests.post('/leads/web_pass', {
            club_id,
            first_name,
            last_name,
            zipcode,
            email,
            phone,
            birthday,
            is_sms_allowed,
            is_test,
            source
    }) 
}


export default {
   GO24, TrainerSync, Auth, Club, WeekClasses, Plans, History, Stream, App, GymLocations, Ref
};


