import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import Moment from 'moment';
import { IconProfile } from '../Icons/Icons';

class ProfileInfo extends Component {


    componentWillMount(){
        //does trainer exist?  if so fetch him
    }

    render() {
        const {currentUser} = this.props.UserStore


        const name = ()=> {
            return (
              <li className="detailsName">{currentUser.first_name +' '+ currentUser.last_name}</li>
            )
        }

        const age = ()=> {
            if (currentUser.birthday){
                var years = Moment().diff(currentUser.birthday, 'years');
                return (
                    <li><strong>Age:</strong> {years}</li>
                )
            }
        }

        const gender = ()=>{
           switch (currentUser.gender){
            case 'm':
                   return (
                    "Male"
                   )
            case 'f':
                   return (
                    "Female"
                   )
            case 'u':
                   return (
                    "Unspecified"
                   )
            default:
                   return (
                    "Unspecified"
                   )
           }
        }

        const height = ()=> {
            if (currentUser.inches && currentUser.feet){
                return(
                  <li><strong>Height:</strong> {currentUser.feet}'{currentUser.inches}" </li>
                )
            }
        }

        const primaryGoal = ()=> {
            var label = data.find( (dataValue, index )=> {
                if(currentUser.primary_goal === dataValue.name){
                    return dataValue.name === currentUser.primary_goal
                }
                return ''
            })
            if (label){
                label = label.label
            }
            return (
                <span>{label}</span>
            )
        }
        const joinDate = ()=> {
            if (currentUser.member){
                if(currentUser.member.join_date){
                return (
                  <li><strong>Member Since:</strong> {currentUser.member.join_date} </li>
                )
                }
            }
        }
        const poster = ()=> {
            if (currentUser.avatar){
                return (
                    <img alt='avatar' src={currentUser.avatar} />
                )
            } else {
                return (
                    <div>
                      <IconProfile nativeColor="#fff" id="icon" className="icon icon-profile" />
                    </div>
                )
            }
        }
        const trainer = ()=> {
            if (currentUser.trainers && currentUser.trainers.length > 0 && currentUser.trainers[0]){
            var myTrainer = currentUser.trainers[0]
            return (
                <li><strong>My Trainer:</strong> {myTrainer.first_name} {myTrainer.last_name} </li>
            )
            }
        }
        const data =  [
                {
                    "name": "lose_weight",
                    "label": "Lose Weight"
                },
                {
                    "name": "tone_up",
                    "label": "Tone Up"
                },
                {
                    "name": "build_endurance",
                    "label": "Build Endurance"
                },
                {
                    "name": "increase_flexibility",
                    "label": "Increase Flexibility"
                },
                {
                    "name": "gain_muscle",
                    "label": "Gain Muscle"
                },
                {
                    "name": "strengthen_and_stabilize",
                    "label": "Strengthen and Stabilize"
                }
        ]

        return(
            <div className="appProfileDetails">
              <div className="profilePic">
                {poster()}
              </div>
              <div className="detailsList">
                <ul>
                    {name()}
                    {age()}
                  <li><strong>Gender:</strong> {gender()} </li>
                    {trainer()}
                </ul>
                <ul>
                    {height()}
                  <li><strong>Weight:</strong> {currentUser.weight}lbs </li>
                  <li><strong>Primary Fitness Goal:</strong> {primaryGoal()} </li>
                    {joinDate()}
                </ul>
              </div>
              <div className="installGO"><img alt='app_store_link' src="/fitplan/imgs/install.png" /></div>
            </div>
        );
    }
}

ProfileInfo  = inject('UserStore')(observer(ProfileInfo))
export default ProfileInfo;
