import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';


class Gts extends Component {

	radioSelected = undefined

    data = [
        {
            "name": "0-5.5",
            "label": "0 - 5.5"
        },
        {
            "name": "6.0-7.5",
            "label": "6.0 - 7.5"
        },
        {
            "name": "8.0-10.0",
            "label": "8.0 - 10.0"
        }
    ]

    handleToggle = e => (e) => {
        const {ident} = this.props
        if (e.target.value){
            this.radioSelected = Number(e.target.value);
        }
        if (e.currentTarget.getAttribute('value')) {
            this.radioSelected = Number(e.currentTarget.getAttribute('value'));
        }
        this.props.PlanBuilderStore.setValue(ident,this.data[this.radioSelected].name)

    };


    render(){
        const {ident} = this.props
        var selected = undefined
        if (this.props.PlanBuilderStore.values[ident]){
            selected = this.props.PlanBuilderStore.values[ident]
        }
        if (selected){
            this.data.forEach( (value, index) => {
                if (selected === value.name){
                    this.radioSelected = index
                }
            })
        }
		const radios = ()=> {
			var components = []
			this.data.forEach( (value, index)=> {
				components.push(

                    <ListItem
                        value= {String(index)}
                        key={index}
                        role={undefined}
                        dense
                        className="listItem"
                        disableGutters={true}
                        button
                        divider={true}
                        onClick={this.handleToggle(index)}
                    >
                        <FormControlLabel
                            labelPlacement="start"
                            value= {String(index)}
                            checked={index === this.radioSelected}
                            control={<Radio
                                style={{position:'absolute',right:0,bottom:-3}}
                                color='primary'
                            />}
                            style={{paddingLeft:'15px',paddingRight:'15px'}}
                            label={this.data[index].label} />
                    </ListItem>
 				)
			})
			return components
		}

        return (
            <div className="stepContent animate-fadeInDown">
              <h1>What was your Sitting-Standing Assessment score?</h1>
                <List
                    dense={true}
                    disablePadding={true}
                >
						{radios()}
                </List>
            </div>
        )
    }
}

Gts = inject('PlanBuilderStore')(observer(Gts))
export default Gts;
