import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
//import { Link } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Moment from 'moment';

const styles = theme => ({
    birthdayTitle: {
        color: '#616365',
    },
    claimButton: {
        borderRadius: 0,
        margin: '14px 0',
        [theme.breakpoints.down('xs')]: {
            borderRadius: 0,
            bottom: 0,
            height: '3rem',
            left: 0,
            margin: 0,
            position: 'fixed',
        },
    },
    claimContainer: {
        marginLeft: 0,
        [theme.breakpoints.down('xs')]: {
            marginBottom: '2.5rem',
        },
    },
    commonInput: {
        width: '100%'
    },
    datePickerPiece: {
        width: '33%'
    },
    disclaimerLink: {
        color: 'inherit',
        fontSize: 'inherit',
        padding: ' 0 0.2rem 0 0.2rem',
        display: 'inherit',
    },
    disclaimerText: {
        fontSize: '0.75rem',
        opacity: '0.7',
        display: 'inline'
    },
    imageContainer: {
        backgroundImage: 'url(/r/imgs/step1/pass-background.png)',
        backgroundPosition: '60%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    infoContainer: {
        color: '#000000',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-start',
        },
    },
    infoDetails: {
        padding: '0 2.5rem',
        [theme.breakpoints.down('xs')]: {
            padding: '0 20px 20px 20px'
        }
    },
    infoTitle: {
        color: '#37424a',
        fontWeight: 'lighter',
        fontSize: '2.7rem',
        padding: '0 2.5rem',
        [theme.breakpoints.down('xs')]: {
            boxShadow: '0px 6px 15px 3px rgba(0, 0, 0, 0.097)',
            fontSize: '1.6rem',
            padding: '20px',
            marginBottom: '20px',
        }

    },
    freePassDescription: {
        color: '#37424a',
        fontSize: '1rem',
        fontWeight: 'lighter',
        lineHeight: '1.5rem',
        opacity: 0.64,
        padding: '0 0 1rem 2.5rem'
    },
    smsAccept: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        margin: '14px 0',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-start'
        }
    },
    smsAcceptCheckbox: {
        paddingLeft: 0,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
        },
    },
});


class ReferralForm extends Component {

        componentWillMount(){
            //TODO temp user sync 
            //delete later
            this.props.ReferralStore.syncUserData()
        }

        constructor(props) {
            super(props);
            this.handleChange = this.handleChange.bind(this);
            this.handleSMSChange = this.handleSMSChange.bind(this);
        }
        handleChange(e) {
            this.props.ReferralStore.setValue(e.target.name,e.target.value);
        }
        handleSMSChange = (e) => {
            this.props.ReferralStore.setValue(e.target.name,e.target.checked);
        }
        handleSubmitForm = (e) => {
            this.props.ReferralStore.createRef()
            .then( ()=> {
                this.props.ReferralStore.section = "confirmation"
            })
        }
        render(){
            const { classes } = this.props;
            const { first_name, last_name, day, month, year, email, phone, zipcode, is_sms_allowed } = this.props.ReferralStore.values;
            const fillRange = (start, end) => {
                return Array(end - start + 1).fill().map((item, index) => start + index);
            };
            const daysArray = fillRange(1, 31);
            const days = daysArray.map((day, i) => {
                return (
                    <MenuItem
                        key={i}
                        value={day}
                    >{day}</MenuItem>
                );
            })
            const monthsArray = fillRange(0, 11);
            const months = monthsArray.map((month, i) => {
                var momentInstance = Moment
                const monthName = momentInstance.months(i)
                return (
                    <MenuItem
                        key={i}
                        value={i}
                    >{monthName.substring(0, 3)}</MenuItem>
                )
            })
            var yearsArray = fillRange(1920, new Date().getFullYear() );
            yearsArray = yearsArray.reverse()
            const years = yearsArray.map((year, i) => {
                return (
                    <MenuItem
                        className="year"
                        key={i} value={year}
                    >{year}
                    </MenuItem>
                );
            })
        return (
            <div>
            <br/>
            <h3>Please Enter Additional Information</h3>
            <h4>Your gym pass is valid for 3 days after your visit to the club.</h4>  
            <ValidatorForm
                className="flexWrap" 
                ref="form"
                autoComplete="off"
                instantValidate={false}
                onSubmit={this.handleSubmitForm}
                onError={errors => console.log(errors)}
            >

                <Grid container spacing={16}>
                    <Grid item xs={12} sm={5} lg={4}>
                        <TextValidator
                            autoComplete="off"
                            label="First Name"
                            onChange={this.handleChange}
                            name="first_name"
                            id="firstNameField"
                            value={first_name}
                            validators={['required', 'matchRegexp:^([^0-9]*)$']}
                            errorMessages={['This field is required', 'No numbers allowed']}
                            //className={classes.commonInput}
                            placeholder="eg: John"
                        />
                    </Grid>
                    <Grid item xs={12} sm={7} md={6} lg={5}>
                        <TextValidator
                            autoComplete="off"
                            label="Last Name"
                            onChange={this.handleChange}
                            name="last_name"
                            id="lastNameField"
                            //className={classes.commonInput}
                            value={last_name}
                            validators={['required', 'matchRegexp:^([^0-9]*)$']}
                            errorMessages={['This field is required', 'No numbers allowed']}
                            placeholder="eg: Smith"
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={3} className={classes.commonInput}>
                        <div className={classes.birthdayTitle} >Date of Birth</div>
                        <SelectValidator
                            name="month"
                            className={classes.datePickerPiece}
                            value={month}
                            onChange={this.handleChange}
                            InputProps={{
                                name: 'month',
                                id: 'month',
                            }}
                        >
                            {months}
                        </SelectValidator>
                        <SelectValidator
                            name="day"
                            className={classes.datePickerPiece}
                            value={day}
                            onChange={this.handleChange}
                            InputProps={{
                                name: 'day',
                                id: 'day',
                            }}
                        >
                            {days}
                        </SelectValidator>
                        <SelectValidator
                            name="year"
                            className={classes.datePickerPiece}
                            value={year}
                            onChange={this.handleChange}
                            InputProps={{
                                name: 'year',
                                id: 'year',
                            }}
                        >
                            {years}
                        </SelectValidator>
                    </Grid>
                    <Grid item xs={12} sm={7} md={6} lg={5}>
                        <TextValidator
                            label="Email"
                            autoComplete="off"
                            onChange={this.handleChange}
                            name="email"
                            id="emailField"
                            className={classes.commonInput}
                            value={email}
                            validators={['required', 'isEmail']}
                            errorMessages={['This field is required', 'email is not valid']}
                            placeholder="eg: name@domain.com"
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={4}>
                        <TextValidator
                            label="Phone"
                            autoComplete="off"
                            onChange={this.handleChange}
                            name="phone"
                            id="phoneField"
                            className={classes.commonInput}
                            value={phone}
                            validators={['required', 'matchRegexp:^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$']}
                            errorMessages={['This field is required', 'Enter a valid 10-digit phone number']}
                            placeholder="eg: 123-456-3828"
                            type="tel"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <TextValidator
                            label="Zip"
                            autoComplete="off"
                            onChange={this.handleChange}
                            name="zipcode"
                            id="zipcodeField"
                            className={classes.commonInput}
                            value={zipcode}
                            validators={['required', 'matchRegexp:^[0-9]{5}(-[0-9]{4})?$']}
                            errorMessages={['This field is required', 'Enter a valid US zipcode']}
                            placeholder="eg: 12345"
                            type="tel"
                        />
                    </Grid>
                    <Grid item>
                        <div className={classes.smsAccept}>
                            <Checkbox
                                name="is_sms_allowed"
                                checked={is_sms_allowed}
                                onChange={this.handleSMSChange}
                                value={String(is_sms_allowed)}
                                className={classes.smsAcceptCheckbox}
                            />
                            <Grid >
                                <span className="smsBold">
                                    <strong>Yes!</strong> I'd like to receive automated marketing text messages and workouts from 24 Hour Fitness to the mobile number provided.
                                </span>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item >
                        <div className={classes.disclaimerText}>
                            Consent is not required to purchase goods or services.
                            You further understand that your information will be used as described here
                            and according to the 24 Hour Fitness In-Club Privacy Policy:
                            <Link
                            className={classes.disclaimerLink}
                            target='_blank'
                            href="https://www.24hourfitness.com/membership/inclub_privacy_policy.html"
                            >
                            Click here
                            </Link>
                            Website Privacy Policy:
                            <Link
                            className={classes.disclaimerLink}
                            target='_blank'
                            href='https://www.24hourfitness.com/company/policies/privacy/privacy_policy.html'
                            >
                            Click here
                            </Link>
                            SMS Terms & Conditions:&nbsp;
                            <Link
                            className={classes.disclaimerLink}
                            target='_blank'
                            href='https://www.24hourfitness.com/company/policies/sms/terms.html'
                            >
                            Click here
                            </Link>
                            Up to 3msg/wk. First text sent within two days after opt-in. Message and data rates may apply.
                            Reply STOP to 24HOUR (244687) to end. Reply HELP to 24HOUR (244687) for help. Not all carriers covered.
                        </div>
                    </Grid>
                    <Grid container alignItems='center' item spacing={16} className={classes.claimContainer}>
                        <button type="submit" variant="contained" color="primary" className="button-teal" >
                        Claim My Pass
                        </button>
                    </Grid>

                </Grid>

            </ValidatorForm>
            </div>
        )}
}

ReferralForm = inject('ReferralStore')(observer(ReferralForm))
export default withWidth()(withStyles(styles)(ReferralForm))
