import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';


class QuitPlan extends Component {

    quitPlan = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.CurrentPlanStore.quitCurrentPlan()
		this.props.ModalStore.setSection('nothing')
		this.props.ModalStore.closeModal()
    }
    closeModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
		this.props.ModalStore.setSection('nothing')
		this.props.ModalStore.closeModal()
	}
    render() {
        return(
            <div className="modalContent">
                <div className="quitPlanModal modalSubTitle">Are you sure you would like to quit your plan?</div>
                <button 
                    className="button-blue" 
                    type="submit"
                    onClick={this.closeModal}
                >No</button>
                <button className="button-teal" 
                    type="submit"
                    onClick={this.quitPlan}
                >Yes, quit my plan</button>
            </div>
        )
    }
}

QuitPlan = inject('ModalStore','CurrentPlanStore')(observer(QuitPlan))
export default QuitPlan;
