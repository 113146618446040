import React, { Component } from 'react';


class Timeout extends Component {

    render() {
        return(
            <div className="modalContent">
                <div className="modalSubTitle">Your session will expire soon and you will be logged out.</div>
                <button className="button-teal" type="submit">I'm Still Here</button>
            </div>
        );
    }
}

export default Timeout;

