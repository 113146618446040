import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';

class NonGymActivities extends Component {

    state = {
        checked: [],
    };

    data =  [
            {
                "name": "walking",
                "label": "Walking"
            },
            {
                "name": "hiking",
                "label": "Hiking"
            },
            {
                "name": "running",
                "label": "Running"
            },
            {
                "name": "swimming",
                "label": "Swimming"
            },
            {
                "name": "biking",
                "label": "Biking"
            },
            {
                "name": "e-gaming",
                "label": "E-gaming"
            },
            {
                "name": "yoga",
                "label": "Yoga"
            },
            {
                "name": "team_sports",
                "label": "Team Sports (Basketball, Soccer, etc)"
            },
            {
                "name": "golfing",
                "label": "Golfing"
            },
            {
                "name": "adventure_racing",
                "label": "Adventure Racing"
            },
            {
                "name": "gardening",
                "label": "Gardening"
            },
            {
                "name": "skiing",
                "label": "Skiing"
            },
            {
                "name": "kayaking",
                "label": "Kayaking"
            },
            {
                "name": "meditation",
                "label": "Meditation"
            },
            {
                "name": "tennis",
                "label": "Tennis"
            }
    ]

    constructor(props){
        super(props);
        this.handleToggle = this.handleToggle.bind(this)
    }
    handleToggle = (value) => () => {
        const {ident} = this.props
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({
            checked: newChecked,
        });


        var correctData = []
        newChecked.forEach( ( value ) => {
            correctData.push(this.data[value].name)
        })
        var pipeString = correctData.join("|") 
        this.props.UserStore.setValue(ident,pipeString)
    };
    componentWillMount(){
        const {ident} = this.props
        //set state from user store
        //TODO copy pasta from render
        var selectedItems = '' 
        if (this.props.UserStore.currentUser[ident]){
            selectedItems  = this.props.UserStore.currentUser[ident] 
        }
        selectedItems = selectedItems.split('|')
        var selectedIndexes = []
        selectedItems.forEach( (selectedValue)=>{
            this.data.forEach( (dataValue, index )=> {
                if(selectedValue === dataValue.name){
                   selectedIndexes.push(index) 
                }
            })
        })
        this.setState({
            checked: selectedIndexes,
        });
    }

    render(){
        const {ident} = this.props
		const checkboxes = ()=> { 
			var components = [] 
            var selectedItems = '' 
            if (this.props.UserStore.currentUser[ident]){
                selectedItems  = this.props.UserStore.currentUser[ident] 
            }
            selectedItems = selectedItems.split('|')
            var selectedIndexes = []
            selectedItems.forEach( (selectedValue)=>{
                this.data.forEach( (dataValue, index )=> {
                    if(selectedValue === dataValue.name){
                       selectedIndexes.push(index) 
                    }
                })
            })
			this.data.forEach( (value, index)=> {
				components.push( 
                    <ListItem
                        key={value.name}
                        role={undefined}
                        dense
                        className="listItem"
                        disableGutters={true}
                        button
                        divider={true}
                        onClick={this.handleToggle(index)}
                    >
                        <div className=''> {` ${value.label}`} </div>
                        <Checkbox
                            checked={selectedIndexes.indexOf(index) !== -1}
                            tabIndex={-1}
                            style={{ color: '#3CB6CE',position:'absolute',right:0, bottom:-5 }}
                            disableRipple
                        />
                    </ListItem>
 				)
			})	
			return components
		}

        return (
            <div className="stepContent animate-fadeInDown">
              <h3>What activities do you like doing outside of the club?</h3>
              <h4>Select all that apply.</h4>
            <List 
                dense={true}
                disablePadding={true}
            >
                {checkboxes()}	
            </List> 
            </div>
        )
    }
}

NonGymActivities  = inject('UserStore')(observer(NonGymActivities ))    
export default NonGymActivities ;
