import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

class Location extends Component {

    render() {
        //console.log("##### Where Render #####")
        const {handleChange, index, value } = this.props
        
        const locationObject = {
            'At Home':false,
            'In Club':true
        }
        //TODO if IE11 doesn't like this - for(var i in obj){
        const location =
            Object.keys(locationObject).map( (key, index) => {
                return (
                    <MenuItem
                        key={index}
                        value={locationObject[key]}
                     >{key}</MenuItem>
                )
            });
        return(
            <FormControl
                    fullWidth
                >
                <div className="inputBlockWrapper">
                  <InputLabel htmlFor="name-disabled">Location</InputLabel>
                  <Select
                      fullWidth
                      label="Location"
                      test={index}
                      value= {value.is_club}
                      onChange={handleChange}
                      className="dayWhere"
                      inputProps={{
                          name: "location"+index,
                          id: '',
                      }}
                  >
                      {location}

                  </Select>
                </div>
            </FormControl>
        );
    }
}

export default Location;
