import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ExpansionPanelDetails, ExpansionPanelSummary,ExpansionPanel} from '../Shared/ExpansionPanel'

import List from '@material-ui/core/List';
import { observer , inject } from 'mobx-react';


class Focus extends Component {

    handleToggle(e){
        const { handleChange } = this.props
        const { checked } = this.state;

        const newChecked = [...checked];

        var item = e.currentTarget.getAttribute('name')

        const currentIndex = checked.indexOf(item);
        if (currentIndex === -1) {
            newChecked.push(item);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked,
        });
        //create string
        e.target.name = "focus"
        var newStoreValue = []
        newChecked.forEach( (tag, index)=> {
            var cleanTag = this.data[tag].tag.replaceAll(",","-")
           newStoreValue.push( cleanTag )
        })
        e.target.name = "focus"
        e.target.value = newStoreValue.toString()
        handleChange(e)
    };

    state = {
        checked: [],
    };

    constructor(props){
        super(props);
        this.handleToggle = this.handleToggle.bind(this)
    }
    componentWillMount(){
        const {tagfilters} = this.props.ChangeDayStore.dayValues
        var tagArray = tagfilters.split(',');
        tagArray.forEach( (tag, index)=> {
            this.data.forEach( (dataItem,dataIndex)=> {
                if (tag === dataItem.tag){
                    this.state.checked.push(dataIndex.toString())
                }
            })
        })
    }

    data =  [
            {
                "tag": "179",
                "label": "Full Body"
            },
            {
                "tag": "47",
                "label": "Upper Body"
            },
            {
                "tag": "49",
                "label": "Lower Body"
            },
            {
                "tag": "48",
                "label": "Core"
            },
            {
                "tag": "52",
                "label": "Back"
            },
            {
                "tag": "51",
                "label": "Shoulders"
            },
            {
                "tag": "50",
                "label": "Arms"
            },
            {
                "tag": "49",
                "label": "Legs"
            },
            {
                "tag": "239",
                "label": "Bicep"
            },
            {
                "tag": "170",
                "label": "Calves"
            },
            {
                "tag": "206",
                "label": "Chest"
            },
            {
                "tag": "165",
                "label": "Glutes"
            },
            {
                "tag": "259",
                "label": "Hamstrings"
            },
            {
                "tag": "210",
                "label": "Quads"
            },
            {
                "tag": "57",
                "label": "Tricep"
            }
    ]
    render() {
        const { checked } = this.state;
        const theme =
            Object.keys(this.data).map( (key, index) => {
                var val = this.data[key]
                var isChecked = false
				if (checked.indexOf(String(index)) !== -1){
					isChecked = true
				}
                return (
                    <ListItem
                        key={index}
                        role={undefined}
                        dense
                        className="listItem"
                        disableGutters={true}
                        button
                        name={String(index)}
                        divider={true}
                        onClick={this.handleToggle}
                    >
                        <div className=''> {` ${val.label}`} </div>
                        <Checkbox
                            checked = {isChecked}
                            tabIndex={-1}
                            name={String(index)}
                            style={{ color: '#3CB6CE',position:'absolute',right:0, bottom:-5 }}
                            disableRipple
                        />
                    </ListItem>
                )
            });
        return(
                <div>
                  <div className="optionalSection">Optional</div>
                <ExpansionPanel
                    square
                >
                        <ExpansionPanelSummary className='expandTitle' expandIcon={<ExpandMoreIcon  />}>
                           Body Part
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>

                        <div className="inputBlockWrapper">
                        <List
                            dense={true}
                            disablePadding={true}
                        >
                            {theme}
                        </List>
                        </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
        );
    }
}

Focus = inject('ChangeDayStore')(observer(Focus))
export default Focus;
