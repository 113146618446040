import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import {  Switch, Route  } from 'react-router-dom';


import Users from '../Users/Users.js';

import Mock from '../Mock/Mock.js';

import Dashboard from '../Dashboard/Dashboard.js';
import Profile from '../Profile/Profile.js';
import Plans from '../Plans/Plans.js';
import Header from '../Header/Header.js';
import Background from '../Background/Background.js';
import Modal from '../Modal/Modal.js';
import VideoModal from '../VideoModal/VideoModal.js';
import PreviewDayModal from '../ModalsChangePreview/PreviewDayModal.js';
import Debug from '../Debug/Debug.js';

import PrivateRoute from '../../Utilities/PrivateRoute.js';
import IdleTimer from '../../Utilities/IdleTimer.js';
import CookieID  from '../../Utilities/CookieID.js';
import {parseSearchParams} from '../../Utilities/SearchParams.js';

import cookie from 'js-cookie'

import './App.scss';

class App extends Component {

    componentDidMount(){
        this.onIdle = this._onIdle.bind(this)
        this.earlyIdle = this._earlyIdle.bind(this)
        this.onActive = this._onActive.bind(this)
        var searchParams = parseSearchParams(this.props.location.search)
        if (searchParams.club_number){
            this.isKiosk = true 
        }

        this.props.UserStore.pullUser()
        .then( () => {
            var clubID = '' 
            if (this.props.UserStore.preferred_club_id){
                clubID=this.props.UserStore.preferred_club_id
            } else if (searchParams.club_number){
                clubID=searchParams.club_number 
            }else if (cookie.get('club_number')){
                clubID=cookie.get('club_number')
            }
            if (clubID){
                if (clubID.length === 3){
                    clubID = '00' + clubID
                }
                this.props.ClubDataStore.getClubData(clubID)
                .then( ()=> {
                    this.props.CommonStore.setAppLoaded()
                })
            }else{
                this.props.CommonStore.setAppLoaded()
            }
        })
        
        //if kiosk
        if (this.isKiosk){
            var cookieID = CookieID
            cookieID.identifyMe()
        }


        this.props.CommonStore.getVersion()
        .finally(() => {;
            if ( window.analytics ) window.analytics.track( 'app_started', this.props.CommonStore.version );
        })
    }
    _onActive(e) {
        if (this.props.ModalStore.section === 'timeout'){
            this.props.ModalStore.closeModal()
        }
    }
    _onIdle(e) {
        const isLoggedIn = this.props.UserStore.isLoggedIn
        if (this.isKiosk){
            if (isLoggedIn){
                if ( window.analytics ) window.analytics.track( 'Account Timeout', {
                    club_id: this.props.ClubDataStore.values.id,
                    club_number: this.props.ClubDataStore.club_number,
                    trainer_code: this.props.TrainerSyncStore.values.share_code
                });
                this.props.UserStore.logout()
                .then(() => {
                    //TODO create close out script for this - look at how logout button fires off
                    this.props.TrainerSyncStore.clearValues()
                    this.props.PlanBuilderStore.clearValues()
                    this.props.PlanBuilderStore.closeModals()
                    this.props.CurrentPlanStore.closeModals()
                    this.props.CurrentPlanStore.clearValues()
                    this.props.PreviewModalStore.closeModal()
                    this.props.ModalStore.closeModal()
                    this.props.history.replace('/users');
                })
            }else{
                this.props.ModalStore.closeModal()
                this.props.history.replace('/users');
            }
            //
            //version checker
            var currentVersion = this.props.CommonStore.version
            this.props.CommonStore.getVersion()
            .then(()=> {
                var version = this.props.CommonStore.version
                if (currentVersion.version){
                    if (version.version !== currentVersion.version){
                        window.location.href = window.location.protocol + '//' + window.location.hostname + '/fitplan/?club_number=' + this.props.ClubDataStore.club_number + '&ran=' + new Date().getTime();
                    }
                }
            })

            this.props.PlanBuilderStore.closeModals()
            this.props.CurrentPlanStore.closeModals()
            this.props.ModalStore.closeModal()
        }
    }


    _earlyIdle(e) {
        if (this.isKiosk){
            const isLoggedIn = this.props.UserStore.isLoggedIn
            if (isLoggedIn){
                this.props.ModalStore.setSection('timeout')
                this.props.ModalStore.setCloseHidden()
                this.props.ModalStore.openModal()
            }
        }
    }

    render() {
        const pathname = this.props.location.pathname
        if (pathname === '/mock'){
            return (
                <div>
                <Mock/>
                {/* <Debug/> */}
                </div>
            )
        }
        if (this.props.CommonStore.appLoaded) {
        return (
            <div className="app loggedOut">
              <Header/>
              <Debug/>
              <VideoModal history={this.props.history}/>
              <PreviewDayModal class='previewDayModal'/>
              <Modal history={this.props.history}/>
              <Switch basename="/fitplan/" >
                  <Route path="/users" component={Users} />
                  <Route path="/mock" component={Mock} />
                  <PrivateRoute path="/profile" component={Profile} />
                  <PrivateRoute path="/dashboard" component={Dashboard} />
                  <PrivateRoute path="/plans" component={Plans} />
                  <Route path="" component={Users} />
              </Switch>
              <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    earlyIdle={this.earlyIdle}
                    timeout={1000 * 60 * 3}
                    reducedTimeout={1000 * 60 * 2}
               >
              </IdleTimer>
              <Background/>
            </div>
        );

        }else{

        return (
            <div>
              <div className="appLoader">
                <div className="appLoadingSpinner">
                  <div className="loader"></div>
                  <span className="loadingText">Warming Up</span>
                </div>
              </div>
              <Modal history={this.props.history}/>
              <Background/>
            </div>
        )
        }
    }
}

App = inject('PreviewModalStore', 'CurrentPlanStore', 'PlanBuilderStore', 'ModalStore', 'ClubDataStore', 'TrainerSyncStore', 'UserStore', 'CommonStore')(observer(App))
export default App;
