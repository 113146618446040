import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { withRouter } from 'react-router-dom';


class ConfirmationForm extends Component {

    handleSubmitForm = (e) => {
        e.preventDefault();
        this.props.UserStore.confirmationCheckIn()
            .then(() => {
                    this.props.TrainerSyncStore.trainerLink()
                        .then( ()=> {
                            this.props.history.replace('/users/success');
                        })
                })
    };

    handleChange = (e) => {
        this.props.UserStore.setValue(e.target.name,e.target.value);
    }

    render() {
        const { first_name, last_name, email, year, day, month  } = this.props.UserStore.currentUser;

        //TODO make external function for this if ever used again...
        const fillRange = (start, end) => {
            return Array(end - start + 1).fill().map((item, index) => start + index);
        };


        const daysArray = fillRange(1, 31);
        const days = daysArray.map( (day, i ) => {
            return (
                <MenuItem
                    key={i}
                    value={day}
                 >{day}</MenuItem>
            );
        })
        const monthsArray = fillRange(0, 11);
        const months = monthsArray.map( (month, i) => {
            var momentInstance = Moment
            const monthName = momentInstance.months(i)
            return (
                <MenuItem
                    key={i}
                    value={i}
                 >{monthName}</MenuItem>
            )
        })
        var yearsArray = fillRange(1920, new Date().getFullYear() );
        yearsArray = yearsArray.reverse()
        const years = yearsArray.map( (year, i ) => {
            return (
                <MenuItem
                    className="year"
                    key={i} value={year}
                    >{year}
                </MenuItem>
            );
        })


        return(

            <ValidatorForm
                className=""
                ref="form"
                autoComplete="off"
                instantValidate = {false}
                onSubmit={this.handleSubmitForm}
                onError={errors => console.log(errors)}
            >
              <div className="inputInlineWrapper">
                <TextValidator
                    key="0"
                    label="First Name"
                    onChange={this.handleChange}
                    className="inline"
                    placeholder="First Name"
                    name="first_name"
                    InputProps={{
                    }}
                    value={first_name}
                    validators={['required']}
                    errorMessages={['this field is required']}
                    margin="normal"
                />
              </div>
              <div className="inputInlineWrapper">
                <TextValidator
                    key="1"
                    label="Last Name"
                    onChange={this.handleChange}
                    className="inline"
                    placeholder="Last Name"
                    name="last_name"
                    value={last_name}
                    validators={['required']}
                    errorMessages={['this field is required']}
                    margin="normal"
                />
              </div>
              <div className="inputInlineWrapper">
                <TextValidator
                    key="2"
                    label="Email"
                    onChange={this.handleChange}
                    className="inline"
                    placeholder="Email"
                    name="email"
                    value={email}
                    validators={['required']}
                    errorMessages={['this field is required']}
                    margin="normal"
                />
              </div>
              <div className="inputInlineWrapper">

                  <div className='birthday inline' >Date of Birth</div>

                  <div className="inputBlockWrapper">

                    <div className="inputInlineWrapper">
                    <SelectValidator
                        name="month"
                        className="birthdaySel"
                        value={month}
                        onChange={this.handleChange}
                        InputProps={{
                            name: 'month',
                            id: 'month',
                        }}
                    >
                        {months}
                    </SelectValidator>
                    </div>
                    <div className="inputInlineWrapper">
                    <SelectValidator
                        name="day"
                        className="birthdaySel"
                        value={day}
                        onChange={this.handleChange}
                        InputProps={{
                            name: 'day',
                            id: 'day',
                        }}
                    >
                        {days}
                    </SelectValidator>
                    </div>

                    <div className="inputInlineWrapper">
                    <SelectValidator
                        name="year"
                        className="birthdaySel"
                        value={year}
                        onChange={this.handleChange}
                        InputProps={{
                            name: 'year',
                            id: 'year',
                        }}
                    >
                        {years}
                    </SelectValidator>
                    </div>


                    </div>
                </div>

                <button className="button-teal">Continue</button>
            </ValidatorForm>
        )
    }
}

ConfirmationForm = inject('TrainerSyncStore','UserStore', 'CommonStore')(observer(ConfirmationForm ))
ConfirmationForm = withRouter(ConfirmationForm)
export default ConfirmationForm;
