import React, { Component } from 'react';
import { inject } from 'mobx-react';
import WhenTime from './WhenTime'
import WhenClasses from './WhenClasses'
import Where from './Where'
import What from './What'
import WhatHome from './WhatHome'
import WhatAging from './WhatAging'
import WhatAgingHome from './WhatAgingHome'

class ActiveDay extends Component {

    /*
    "is_rest_day": false,
    "is_club": '',
    "theme": '',
    "time": '', 
    */

    render(){
        console.log("##### ActiveDay Render #####")
        const {planId, handleClassChange, handleChange, index, value } = this.props
        const where = ()=> { 
			return (
                <Where handleChange={handleChange} index={index} value={value} />
			)
		}

        const whatDefault = ()=> {
            if (value.is_club === ''){
                return (
                    <What disabled={true} handleChange={handleChange} index={index} value={value}/>
                )
            }else{
                if(value.is_club === true){
                    return (
                    <What handleChange={handleChange} index={index} value={value} />
                    )
                }else{
                    return (
                    <WhatHome handleChange={handleChange} index={index} value={value} />
                    )
                }
            }
        }
        const whatActiveAging = ()=> {
            if (value.is_club === ''){
                return (
                    <WhatAging disabled={true} handleChange={handleChange} index={index} value={value} />
                )
            }else{
                if(value.is_club === true){
                    return (
                    <WhatAging handleChange={handleChange} index={index} value={value} />
                    )
                }else{
                    return (
                    <WhatAgingHome handleChange={handleChange} index={index} value={value} />
                    )
                }
            }
        }
        
        const what = ()=> {
            //TODO refactor all the whats to one what
            //and send a theme object as a prop
            if (planId === 67){
                return (whatActiveAging())
            }else{
                return (whatDefault())
            }
        }
        const when = ()=> {
            //if not gx class and 
            if (value.theme === ''){
                return (
                    <WhenTime disabled={true} handleChange={handleChange} index={index} value={value} />
                )
            }else{
                //and non class plan isnt true 
                if(value.theme === 'gx_class' && this.props.ClubDataStore.clubDataLoaded){
                    return (
                        <WhenClasses handleChange={handleClassChange} index={index} value={value} />
                    )
                }else{
                    return (
                        <WhenTime handleChange={handleChange} index={index} value={value} />
                    )
                }
            }
        }
        return(
            <div>
                {where()}
                {what()}
                {when()}
            </div>
        )
    }

}

ActiveDay = inject('ClubDataStore')(ActiveDay)
export default ActiveDay;
