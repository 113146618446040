import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { IconCheckCircle } from '../Icons/Icons';

class Success extends Component {
    //
    //TODO should only be here with valid user
    render() {
        const {first_name} = this.props.UserStore.currentUser
        return (
              <div className="appCenterMessage animate-fadeInDown">
                

                <IconCheckCircle nativeColor="#000" id="icon" className="icon topIcon animate-zoomInGreen icon-check-circle" />
                <h1>Congrats, {first_name}! You're registered with 24GO</h1>
                <h2>Now take a few minutes to complete your profile so we can personalize a Fit Plan for you.</h2>
                <Link to="/profile" id="lets-do-it-link" className="button-teal button-skewed">Let's Do It</Link>
              </div>
        );
    }
}

Success = inject('UserStore')(observer(Success))
export default Success;
