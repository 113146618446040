import { observable, action, computed, runInAction, decorate } from 'mobx';
import agent from '../../api/Agent';
import Moment from 'moment';
import ClubDataStore from '../ClubDataStore';
import UserStore from '../UserStore';

class ReferralStore {
    //observables
    referralModalisOpen = false;
    section = 'question'
    values = this.defaultValues();

    defaultValues() {
        return {
            club_id: '',
            first_name: '',
            last_name:'',
            zipcode:'',
            email:'',
            phone:'',
            is_sms_allowed:'',
            is_test: false,
            year: 2000,
            month: 0,
            day: 1,
            pass_code: ''
        }
    }
    
    //computed
    get birthday(){
        const {year, month, day} = this.values
        var momentInstance = Moment
        const bd = momentInstance([year,month,day]).format("YYYY-MM-DD");
        return bd
    }
    //actions
    setValue(key, value){
        if ( this.values[key] !== undefined ) {
            if (value !== null){
                this.values[key] = value
            }
        }
    }

    syncUserData(){
        //set user biz and club biz
        //this.values.pass_code 
        this.values.club_id = ClubDataStore.values.id;
        this.values.first_name = UserStore.currentUser.first_name 
        this.values.last_name = UserStore.currentUser.last_name 
        this.values.year = UserStore.currentUser.year 
        this.values.month = UserStore.currentUser.month 
        this.values.day = UserStore.currentUser.day 
        this.values.email = UserStore.currentUser.email 

    }

    openModal(){
        this.section = 'question'
        this.syncUserData()
        this.referralModalisOpen  = true
    }
    closeModal(){
        this.section = 'question'
        this.referralModalisOpen = false 
    }

    createRef(){
        this.loaderText = 'Submitting'
        this.inProgress = true;
        this.errors = undefined;
        // TODO map the value
        // eslint-disable-next-line
        const { club_id,  first_name, last_name, phone, zipcode, email, is_sms_allowed, is_test  } = this.values
        var birthday = this.birthday
        return agent.Ref.create(
            club_id,
            first_name,
            last_name,
            zipcode,
            email,
            phone,
            birthday,
            is_sms_allowed,
            is_test,
            "ref_web_fitplan"
        ).then( (response) => {
            runInAction( () => {
                if ( response.error ) return alert( response.message );
                for (var key in response) {
                    if (response.hasOwnProperty(key)) {
                        this.setValue(key, response[key])
                    }
                }
                this.createYMDfromBirthday()
                return response
            })
        })
        .catch(action( (err) => {
            this.errors = err.message
            //throw err;
        }))
        .finally(action(() => { this.inProgress = false; }));
    }
}

decorate(ReferralStore, {
    values: observable,
    birthday: computed,
    setValue: action,
    syncUserData: action,
    createRef: action,
    openModal: action,
    closeModal: action,
    referralModalisOpen : observable,
    section: observable
});

export default new ReferralStore();
