import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import StartDate from './StartDate.js';
import SelectDays from './SelectDays.js';
import DayDetails from './DayDetails.js';
import Duration from './Duration.js';
import ProcessingAnimation from './ProcessingAnimation.js';
import ProcessingAnimation2 from './ProcessingAnimation2.js';
import ReviewPlan from './ReviewPlan.js';
import FinishPlan from './FinishPlan.js';
import ShareActivity from './ShareActivity.js';
import Mission from './Mission.js';
import Cardio from './Aging/Cardio.js';
import Gts from './Aging/Gts.js';
import ChangeDayModal from '../ModalsChangePreview/ChangeDayModal.js';
import ChangeTimeModal from '../ModalsChangePreview/ChangeTimeModal.js';
import { IconArrowLeft, IconArrowRight, IconCheck } from '../Icons/Icons';
import ScriptGenerator from '../Profile/ScriptGenerator.js';
import Moment from 'moment';

import './Plans.scss'

class Plans extends Component {
    screens = []
    defaultScreens(){ 
        return [
            "start_date",
            "workout_days",
            "dayDetails",
            "processingAnimation",
            "reviewPlan",
            "duration",
            "finishPlan",
            "processingAnimation2",
            "shareActivity"
        ]
    }
    constructor(props){
        super(props);
        this.activateModal = this.activateModal.bind(this)
        this.goForward = this.goForward.bind(this)
        this.goBack = this.goBack.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.handleChangeDay = this.handleChangeDay.bind(this)
        this.handleTimeChange = this.handleTimeChange.bind(this)
        this.selectMission = this.selectMission.bind(this)
    }
    selectMission(e){
        const { current_step } = this.props.PlanBuilderStore.values
        this.screens = this.screens.filter(item => item !== 'gts_score')
        this.screens = this.screens.filter(item => item !== 'cardio_score')
        this.props.PlanBuilderStore.setValue('plan_id', 51)
        if (e.currentTarget.name === 'y'){
           this.screens.splice(1, 0, "cardio_score"); 
           this.screens.splice(1, 0, "gts_score"); 
            //TODO - hard code for now
           this.props.PlanBuilderStore.setValue('plan_id', 67)
        }
        this.props.PlanBuilderStore.setValue('current_step', current_step+1)
    }

    goForward(){
        const { current_step } = this.props.PlanBuilderStore.values

        switch (this.screens[current_step]){
            case "finishPlan":
				this.props.PlanBuilderStore.createOnboardingPlan()
                .then(() => {
                    this.props.PlanBuilderStore.setValue('current_step', current_step+1)
                })
                break;

            case "workout_days":
                this.props.PlanBuilderStore.getRec()
                .then( ()=> {
                    this.props.PlanBuilderStore.generatePlanTemplate()
                    this.props.PlanBuilderStore.setValue('current_step', current_step+1)
                })
                break;
            case "duration":
            case "dayDetails":
            case "processingAnimation":
            case "processingAnimation2":
            case "cardio_score":
            case "gts_score":
            case "reviewPlan":
            case "shareActivity":
                //fallthrough
                this.props.PlanBuilderStore.setValue('current_step', current_step+1)
                break;
            case "start_date":
                var momentInstance = Moment
                var good_day = true
                var startDay = momentInstance(this.props.PlanBuilderStore.values.start_date).startOf('day')
                var today = momentInstance().startOf('day')
                if (isNaN(startDay)){
                    good_day = false
                    alert('Please enter a valid date.')
                }else if (today.diff(startDay, 'days') < 1){
                    good_day = true
                }else{
                    good_day = false
                    alert('Please enter a date today or later.  A start date cannot be in the past.')
                }
                if (good_day){
                    this.props.PlanBuilderStore.setValue('current_step', current_step+1)
                }
                break;
            default:
               break 
            }

        //load preview plan on processing
        //works for now -
        //sloppy - move this somewhere
        if (this.screens[ current_step ] === 'processingAnimation'){
            this.props.PlanBuilderStore.previewOnboardingPlan()
        }
    }
    goBack(){
        var { current_step } = this.props.PlanBuilderStore.values
        current_step = current_step-1
        if (this.screens[ current_step ] === 'processingAnimation'){
            current_step = current_step-1
        }
        this.props.PlanBuilderStore.setValue('current_step', current_step)
    }

    componentWillMount(){
        //get all plans to ensure no current plan exists
        //check user against 
        this.props.PlanBuilderStore.changeTimeModalIsOpen = false
        this.props.PlanBuilderStore.changeDayModalIsOpen = false
        this.props.PlanBuilderStore.isReady = false 
        const {currentUser} = this.props.UserStore
        this.props.UserStore.pullUser()
        .then( ()=> {
            if (!ScriptGenerator.userHasAllInfoCheck(currentUser)){
                this.props.history.replace('/profile');
            }else{
                this.props.CurrentPlanStore.getAllPlans()
                this.props.PlanBuilderStore.clearValues()
                this.props.PlanBuilderStore.clearPlanTemplate()
                this.props.PlanBuilderStore.setValue('current_step', 0)
                //see if user needs extra screens, add aging
                this.screens = this.defaultScreens()


                //this.props.UserStore.currentUser.available_products.push("pt_mission_all")
                if (this.props.UserStore.currentUser.available_products.indexOf("pt_mission_all") > -1){
                    this.screens.unshift('mission') 
                }

                //ERIC DEBUG
                if (this.props.CommonStore.ericDebugModalOverlay){
                    this.props.PlanBuilderStore.promptForGymModalIsOpen = true
                    var workout_days_index = this.screens.indexOf('dayDetails')
                    this.props.PlanBuilderStore.setValue('current_step', workout_days_index)
                     
                }
                if (this.props.CommonStore.ericDebugReccomended){
                    this.props.PlanBuilderStore.promptForGymModalIsOpen = false 
                    // eslint-disable-next-line
                    var workout_days_index = this.screens.indexOf('dayDetails')
                    this.props.PlanBuilderStore.values.workout_days = "5|6"
                    this.props.PlanBuilderStore.hasSeenClubSelectorPrompt = true
                    this.props.PlanBuilderStore.generatePlanTemplate()
                    this.props.PlanBuilderStore.updatePlanTemplateWorkoutDay(
                        6, 'is_club', true 
                    )
                    this.props.PlanBuilderStore.setValue('current_step', workout_days_index)
                     
                }

                this.props.PlanBuilderStore.isReady = true
            }
        })
    }
    componentDidUpdate(){
        //check to make sure our planTemplate.workout_days_objects has some tasks
        //goto workout_days if we are on reviewPlan with all rest days 
        var { current_step } = this.props.PlanBuilderStore.values
        if (this.screens[ current_step ] === 'reviewPlan'){
            if (this.props.PlanBuilderStore.hasOnlyRestDays){
                var workout_days_index = this.screens.indexOf('workout_days')
                this.props.PlanBuilderStore.setValue('current_step', workout_days_index)
            }
        }
    }
    activateModal(){
        this.props.PlanBuilderStore.openPlanDayModal()
    }
    closeModal(e){
        this.props.PlanBuilderStore.closeModals()
    }




    showModal(){
        const { changeTimeModalIsOpen, changeDayModalIsOpen, planPreviewIndex, previewPlanItems } = this.props.PlanBuilderStore
        if (changeDayModalIsOpen){
            return (
                <ChangeDayModal index={planPreviewIndex} plan={previewPlanItems}  closeModal={this.closeModal} week={0} handleChangeDay={this.handleChangeDay}/>
            )
        }else if (changeTimeModalIsOpen) {
            return (
                  <ChangeTimeModal handleChangeTime={this.handleTimeChange} index={planPreviewIndex} plan={previewPlanItems} closeModal={this.closeModal} week={0}/>
            )
        }
    }

    handleTimeChange(item, index){
        this.closeModal()
        this.props.ChangeDayStore.setValue('stream_id', item.id)
        this.props.PlanBuilderStore.updatePlanTemplateWithTimeItems(index)
        this.props.PlanBuilderStore.previewOnboardingPlan()
    }


    handleChangeDay(item, index){
        //called from changeDaymodal
        if (this.props.ChangeDayStore.dayValues.type && this.props.ChangeDayStore.dayValues.type === 'gx_class'){
            this.props.ChangeDayStore.setValue('group_class_id', item.id)
            this.props.ChangeDayStore.setValue('theme', 'gx_class')
            this.props.ChangeDayStore.setValue('stream_id', '')
            if (item.formatted_time){
                this.props.ChangeDayStore.setValue('time', item.formatted_time)
            }
            //assume class is a stream because there is no start_int
            if (!item.start_int){
                this.props.ChangeDayStore.setValue('stream_id', item.id)
                this.props.ChangeDayStore.setValue('group_class_id', '')
            }


        }else if (this.props.ChangeDayStore.dayValues.type && this.props.ChangeDayStore.dayValues.type === 'fit_appointment_workout'){
            this.props.ChangeDayStore.setValue('group_class_id', '' )
            this.props.ChangeDayStore.setValue('stream_id', '')
            this.props.ChangeDayStore.setValue('theme', 'fit_appointment_workout')
         //pt_session will now have a stream ID
        }else if (this.props.ChangeDayStore.dayValues.type && this.props.ChangeDayStore.dayValues.type === 'pt_session'){
            this.props.ChangeDayStore.setValue('group_class_id', '' )
            //this.props.ChangeDayStore.setValue('stream_id', '')
            this.props.ChangeDayStore.setValue('stream_id', item.id)
            this.props.ChangeDayStore.setValue('theme', 'pt_session')
        
        }else{
            this.props.ChangeDayStore.setValue('group_class_id', '' )
            this.props.ChangeDayStore.setValue('stream_id', item.id)
        }
        this.props.PlanBuilderStore.updatePlanTemplateWithChangeDayItems(index)
        if ( window.analytics ) {
            var filters_obj = JSON.parse( JSON.stringify( this.props.ChangeDayStore.dayValues ) );
            delete filters_obj.club_id;
            delete filters_obj.is_rest_day;
            delete filters_obj.start_date;
            delete filters_obj.task_date;
            delete filters_obj.task_id;
            delete filters_obj.task_int;
            delete filters_obj.task_type;
            delete filters_obj.user_plan_id;
            delete filters_obj.user_plan_task_id;
            window.analytics.track( 'fitplan_workout_selected', filters_obj );
        }

        this.closeModal()
        this.props.PlanBuilderStore.previewOnboardingPlan()
    }

    showScreen(){
        //switch on current screen possibly welcome back logic
        const { current_step} = this.props.PlanBuilderStore.values

        if ( window.analytics && this.props.UserStore.trackedLog.indexOf( this.screens[current_step] ) === -1 ) {
            this.props.UserStore.trackedLog.push( this.screens[current_step] );
            window.analytics.track( 'fitplan_wizard', {
                plan_build_step: this.screens[current_step],
                club_id: this.props.ClubDataStore.values.id,
                club_number: this.props.ClubDataStore.club_number,
                trainer_code: this.props.TrainerSyncStore.values.share_code,
                plan_choices: this.props.PlanBuilderStore.planTemplate
            });
        }

        switch(this.screens[ current_step ]){
            case 'start_date':
               	return (<StartDate ident="start_date" />)
            case 'duration':
                return (<Duration ident="weeks" />)
            case 'workout_days':
                return (<SelectDays ident="workout_days" />)
            case 'dayDetails':
                return (<DayDetails />)
            case 'processingAnimation':
                setTimeout(this.goForward,2000)
                return (<ProcessingAnimation />)
            case 'processingAnimation2':
                setTimeout(this.goForward,2000)
                return (<ProcessingAnimation2 />)
            case 'reviewPlan':
                return(<ReviewPlan />)
            case 'finishPlan':
				return(<FinishPlan />)
            case 'shareActivity':
				return(<ShareActivity history={this.props.history} />)
            case 'mission':
				return(<Mission selectMission={this.selectMission} />)
            case 'gts_score':
				return(<Gts ident="gts_score"/>)
            case 'cardio_score':
				return(<Cardio ident="cardio_score"/>)
            default:
                break
        }
    }
    showBack(){
        const { current_step } = this.props.PlanBuilderStore.values
        if (current_step > 0 && this.screens[current_step]!== "" &&
            this.screens[current_step] !== 'shareActivity'){
            return (
                <button
                    onClick={this.goBack}
                    className="previousBtn"
                >
                    <IconArrowLeft nativeColor="#000" id="icon" className="icon icon-arrow-left" />
                </button>
            )
        }
    }

    showForward(){
        //if current ident has value in current user
        const { current_step} = this.props.PlanBuilderStore.values
        var showForward = false
        switch (this.screens[current_step]){
            case "start_date":
            case "workout_days":
            case "processingAnimation":
            case "processingAnimation2":
            case "gts_score":
            case "cardio_score":
                if (this.props.PlanBuilderStore.values[this.screens[current_step]]){
                    showForward = true
                }
                break;
            case "duration":
                if (this.props.PlanBuilderStore.values['weeks']){
                    showForward = true
                }
                break;
            case "finishPlan":
				if (this.props.PlanBuilderStore.values['custom_name']){
					showForward = true
				}
				break;
            case "dayDetails":
                //this.props.PlanBuilderStore.isPlanReady()
				if (this.props.PlanBuilderStore.values['planIsReady']){
					showForward = true
                }
                break;
            case "reviewPlan":
                showForward = true
                break;
            case "shareActivity":
                showForward = false
                break;
            default:
                break;
        }

        if ( showForward === true){
            if (this.screens[ current_step ] === 'finishPlan'){
                if (this.props.PlanBuilderStore.inProgress === false){
                  return (
                      <button onClick={this.goForward} className="nextBtn nextBtnText">
                        <IconCheck nativeColor="#fff" id="icon" className="icon icon-check" />
                        <span className="nextText">SAVE</span>
                      </button>
                  )
                }else{
                    return (
                        <div></div>
                    )                          
                }
              
            } else {
              return (
                  <button onClick={this.goForward} className="nextBtn">
                    <IconArrowRight nativeColor="#fff" id="icon" className="icon icon-arrow-right" />
                  </button>
              )
            }
        }
    }

    showProgress(){
        const { current_step} = this.props.PlanBuilderStore.values
        var width =  (current_step+1) /this.screens.length *100
        var divStyle = {
            width:width+'%'
        };
        if (this.screens[current_step]!== "success"){
            return (
                <div className="progressBar">
                  <div className="progress" style={divStyle}></div>
                </div>
            )
        }
    }

    render(){
        const {plans} = this.props.CurrentPlanStore
        const {changeDayModalIsOpen, isReady} = this.props.PlanBuilderStore
        const escapeIfActivePlan = ()=> {
            var hasActive = false 
            if (plans.length > 0){
                plans.forEach( ( plan, index ) => {
                    if (plan.is_active){
                         hasActive = true 
                    }
                })
            }
            if (hasActive){
                this.props.history.replace('/dashboard');
            }
        }
        if (changeDayModalIsOpen){
            //testing performance
            return (
                <div className="printFix">
                  <div className="appProgressSteps">
                  </div>
                  {this.showModal()}
                </div>
            );
        }else{
            if (isReady){
                return (
                    <div className="printFix">
                      <div className="appProgressSteps planSteps">
                        {this.showProgress()}
                        {this.showScreen()}
                        {this.showBack()}
                        {this.showForward()}
                        {escapeIfActivePlan()}
                      </div>
                      {this.showModal()}
                    </div>
                );
            }else{
                return (
                    <div></div>
                )
            }
        }
    }
}


Plans = inject('CurrentPlanStore','ChangeDayStore', 'PlanBuilderStore', 'UserStore', 'ClubDataStore', 'TrainerSyncStore','PreviewModalStore', 'CommonStore')(observer(Plans))
export default Plans
