import { observable, action, runInAction, decorate  } from 'mobx';
import agent from '../../api/Agent';
import cookie from 'js-cookie'

class ClubDataStore {
    clubDataLoaded = false
    club_number = ''
    
    clubDataModalIsOpen = false;
    openSelectClubModal(){
        this.clubDataModalIsOpen  = true
    }
    closeSelectClubModal(){
        this.clubDataModalIsOpen = false 
    }

    values = {
        id: '',
        name: '',
        full_address: '',
        address_state: ''
    }

    inProgress = false;
    loaderText = '';
    errors = undefined;

    clearClubData(){
        //TODO lame, clear this up
        this.values = {
            id: '',
            name: '',
            full_address: '',
            address_state: ''
        }
        this.club_number = ''
        this.clubDataLoaded = false
        cookie.remove('club_number')
    }
    setGymValues(key, value){
        if ( this.values[key] !== undefined ) {
            if (value){
                 this.values[key] = value
            }
        }
    }

    getClubData(club_number){
        this.loaderText = 'Loading'
        this.inProgress = true;
        return agent.Club.getClubData(
            club_number
        )
        .then( (response) => {
            runInAction( () => {
                for (var key in response) {
                    if (response.hasOwnProperty(key)) {
                        this.setGymValues(key, response[key])
                    }
                }
                if (this.values.name){
                    this.clubDataLoaded = true
                    this.club_number = club_number
                    cookie.set('club_number', club_number, {expires:1500})
                }
                if (response.error){
                    alert(`Data for club number ${club_number} could not be loaded at this time`)
                    console.log(response.error)
                    this.clubDataLoaded = false
                    this.club_number = ''
                    cookie.remove('club_number', club_number)
                }
            })
        })
        .catch(action((err) => {
            alert(`Data for club number ${club_number} could not be loaded at this time`)
            this.errors = err.response && err.response.body && err.response.body.errors;
            cookie.remove('club_number', club_number)
        }))
        .finally( action(()=> {
            this.inProgress = false
        }));
    }
}
decorate(ClubDataStore, {
    inProgress: observable,
    getClubData: action,
    clearClubData: action,
    clubDataLoaded: observable,
    haveClubDataID: observable,
    club_number: observable,
    clubDataModalIsOpen: observable,
    openSelectClubModal: action, 
    closeSelectClubModal: action
});

export default new ClubDataStore();
