import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import ActiveDay from '../CalendarItems/ActiveDay'
import RestDay from '../CalendarItems/RestDay'
import ClubDataStore from '../../Stores/ClubDataStore';
import TrainerSyncStore from '../../Stores/TrainerSyncStore';
import classNames from 'classnames/bind';

class ReviewPlan extends Component {

    showPreview(e){
        const { previewPlanItems } = this.props.PlanBuilderStore
        var planPreviewIndex = e.target.value
        var planItem = previewPlanItems[0].days[planPreviewIndex]
        this.props.PreviewModalStore.planItem = planItem
        this.props.PreviewModalStore.openModal()
    }
    showChange(e){
        this.props.PlanBuilderStore.setPlanPreviewIndex(e.target.value)
        this.props.PlanBuilderStore.openChangeDayModal()
    }
    showTimeChange(e){
        this.props.PlanBuilderStore.setPlanPreviewIndex(e.target.value)
        this.props.PlanBuilderStore.openTimeChangeModal()
    }
    constructor(props){
        super(props);
        this.showPreview = this.showPreview.bind(this)
        this.showChange = this.showChange.bind(this)
        this.showTimeChange = this.showTimeChange.bind(this)
    }

    componentWillMount(){
        if ( window.analytics ) window.analytics.track( 'Plan Preview', {
            club_id: ClubDataStore.values.id,
            club_number: ClubDataStore.club_number,
            trainer_code: TrainerSyncStore.values.share_code
        });
    }

    render() {
        const { previewPlanItems } = this.props.PlanBuilderStore
        const getDays = ()=> {
            var dayBoxes = []
            if (previewPlanItems && previewPlanItems[0] && previewPlanItems[0].days){
                previewPlanItems[0].days.forEach( ( day, index ) => {
                    if (day.tasks && day.tasks[0] && day.tasks[0].task){
                        if (day.tasks[0].task.name === "Rest Day"){
                            day.is_rest_day = true
                        }
                    }
                    var dayStyles = classNames({
                        day: true,
                        dayRest: true
                    })
                   if  (day.is_rest_day){
                        dayBoxes.push(
                            <RestDay styles={dayStyles} key={index} showChange={this.showChange} index={index} day={day}/>
                        )
                   }else{
                        dayBoxes.push(
                            <ActiveDay key={index} showChange={this.showChange} showPreview={this.showPreview} showTimeChange={this.showTimeChange} index={index} day={day}/>
                        )
                   }
                })
            }
            return dayBoxes
        }
        if (this.props.PlanBuilderStore.previewPlanLoaded ) {
            return(
                    <div className="stepContent animate-fadeInDown">
                        <h1>Review your first week.</h1>
                        <h2>You can make changes now or later.</h2>
                        <div className="planCalendarWeek">
                        <div className="calendarContent">
                        {getDays()}
                        </div>
                        </div>
                    </div>
            )
        }else{
            return(
              <div className="appLoader">
                <div className="appLoadingSpinner">
                  <div className="loader"></div>
                  <span className="loadingText"></span>
                </div>
              </div>
            )
        }
    }
}


ReviewPlan = inject( 'PreviewModalStore','PlanBuilderStore')(observer(ReviewPlan))
export default ReviewPlan;

//<ActiveDay handleClassChange={this.handleClassChange} handleChange={this.handleChange} index={index} value={value} />
