import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { IconGoLogo } from '../Icons/Icons';

import './Background.scss'


//TODO map to if HOME is showing
class Background extends Component {
    render() {
        const pathname = this.props.location.pathname
        //quick hack
        const paths = [
            '',
            '/',
            '/users',
            '/users/'
        ]
        if (!this.props.CommonStore.appLoaded) {
            return(
                <div className="appBackground">
                  <div className="bgImage"></div>
                </div>
            )
        }else if (pathname === '/' ){
            return (
                <div className="appBackground">
                  {/*
                  <div className="bgImage"></div>
                  <video poster="/fitplan/imgs/splash.jpg" autoPlay loop className="vidIntro">
                    <source src="/fitplan/vids/intro.mp4" type="video/mp4" />
                  </video>
                  */}
                </div>
            )
        }else if (paths.indexOf(pathname) > -1 ){
            return (
                <div className="appBackground">
                  <div className="bgImage"></div>
                </div>
            )
        }else{
            return (
                <div className="appBackground">
                    <IconGoLogo
                        nativeColor='#fff' id="icon" className="icon icon-gologo"
                    />
                </div>
            )
        }
  }
}

Background = inject('CommonStore')(observer(Background ))
export default withRouter( Background );
