import { observable, action, runInAction,  decorate } from 'mobx';
import agent from '../../api/Agent';

class CommonStore {
    afterLoginRoute = ''
    debugMode = false 
    appLoaded = false
    version = {} 

    ericDebugModalOverlay = false 
    ericDebugProfileGym = false 
    ericDebugReccomended = false
    ericDebugLoginEmail = false 
    ericDebugCreateEmail = false
    
    clearEricDebug() {
        this.ericDebugModalOverlay = false 
        this.ericDebugProfileGym = false 
        this.ericDebugReccomended = false
        this.ericDebugLoginEmail = false 
        this.ericDebugCreateEmail = false
    }

    setAppLoaded() {
        this.appLoaded = true;
    }

    getVersion() {
        return agent.App.getVersion()
        .then( (versionData)  => {
            runInAction( () => {
                if (versionData && versionData.version){
                    this.version = versionData
                }
            })
          })
        .catch(action((err) => {
            //alert('Version info not found')
            throw err;
        }))
        .finally(action(() => { 
        }))
    }
}

decorate( CommonStore , {
    appLoaded: observable,
    debugMode: observable,
    isExpanded: observable,
    getVersion: action,
    setAppLoaded: action,
    version: observable,
    clearEricDebug: action,
    ericDebugReccomended: observable,
    ericDebugProfileGym: observable,
    ericDebugModalOverlay: observable
})

export default new CommonStore()
