import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import Moment from 'moment';
import { IconClose } from '../Icons/Icons';

import './ChangeDayModal.scss'

import WhenTime from '../Plans/DayDetails/WhenTime'
import Location from './Location'
import Duration from './Duration'
import Intensity from './Intensity'
import Focus from './Focus'
import Type from './Type'
import Theme from './Theme'
import TaskResults from './TaskResults'
import SearchResultsHeader from './SearchResultsHeader'
import { Grid, Hidden } from '@material-ui/core';

class ChangeDayModal extends Component {

    state = {showMobileModal: false};

    constructor(props){
        super(props);
        this.changeDayData = this.changeDayData.bind(this)
        this.changeDay = this.changeDay.bind(this)
        this.fetchMoreResults = this.fetchMoreResults.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.someRefName = React.createRef();

    }

    componentWillMount(){
        const { plan, week, index } = this.props
        //recap this and ensure values
        
        this.props.ChangeDayStore.clearDayValues()
        this.props.ChangeDayStore.populateChangeDayStoreWithItems(plan,week,index)

        var viewTracked = false;
        if (plan[week] && plan[week].days && plan[week].days.length > 0  && plan[week].days[index]){
            var day = plan[week].days[index] 
            if (day.tasks && day.tasks.length > 0 && day.tasks[0]) {
                var task = day.tasks[0]
                this.props.ChangeDayStore.fetchResults()
                if ( window.analytics && !viewTracked ) {
                    viewTracked = true;
                    window.analytics.track( 'edit_plan_day', {
                        user_plan_task: task
                    });
                    var filters_obj = JSON.parse( JSON.stringify( this.props.ChangeDayStore.dayValues ) );
                    delete filters_obj.club_id;
                    delete filters_obj.group_class_id;
                    delete filters_obj.stream_id;
                    delete filters_obj.is_rest_day;
                    delete filters_obj.start_date;
                    delete filters_obj.task_date;
                    delete filters_obj.task_id;
                    delete filters_obj.task_int;
                    delete filters_obj.task_type;
                    delete filters_obj.user_plan_id;
                    delete filters_obj.user_plan_task_id;
                    window.analytics.track( 'view_workout_selector', filters_obj );
                }
            }else{
                //no task
                if ( window.analytics && !viewTracked ) {
                    viewTracked = true;
                    window.analytics.track( 'edit_plan_day', {
                        user_plan_task: {}
                    });
                }
                this.props.ChangeDayStore.setValue('task_date', day.day_str)
            }
        }
    }

    fetchMoreResults(){
        this.props.ChangeDayStore.updateResults()
    }
    closeModal(){
        this.props.ChangeDayStore.previewDayModalIsOpen = false 
    }
    changeDay(item){
        const {handleChangeDay, index} = this.props
		handleChangeDay(item, index)
    }

    changeDayData(e){
        const {dayValues} = this.props.ChangeDayStore
        var name = e.target.name
        var value = e.target.value
        //dayValues
        if (name.includes('location')){
           this.props.ChangeDayStore.setValue('is_club',e.target.value)
           if (dayValues.is_club === false && dayValues.type === 'class'){
                dayValues.type = '' 
           }
           if (dayValues.is_club === false && dayValues.type === 'fit_appointment_workout'){
                dayValues.type = '' 
           }
        }
        if (name.includes('type')){
           this.props.ChangeDayStore.setValue('type',e.target.value)
        }
        if (name.includes('theme')){
           this.props.ChangeDayStore.setValue('theme',e.target.value)
        }
        if (name.includes('when')){
            this.props.ChangeDayStore.setValue('time', e.target.value)
        } 
        if (name.includes('duration')){
            this.props.ChangeDayStore.setValue(value, !dayValues[e.target.value])
        } 
        if (name.includes('intensity')){
            this.props.ChangeDayStore.setValue(value, !dayValues[e.target.value])
        } 
        if (name.includes('focus')){
            this.props.ChangeDayStore.setValue('tagfilters', e.target.value)
        } 
        //super annoying, but again have to force update 
        //look into how mobx handles children and parents overruling who gets to be the listener when observing

        if ( window.analytics ) {
            var filters_obj = JSON.parse( JSON.stringify( this.props.ChangeDayStore.dayValues ) );
            delete filters_obj.club_id;
            delete filters_obj.group_class_id;
            delete filters_obj.stream_id;
            delete filters_obj.is_rest_day;
            delete filters_obj.start_date;
            delete filters_obj.task_date;
            delete filters_obj.task_id;
            delete filters_obj.task_int;
            delete filters_obj.task_type;
            delete filters_obj.user_plan_id;
            delete filters_obj.user_plan_task_id;
            window.analytics.track( 'view_workout_selector', filters_obj );
        }

        this.props.ChangeDayStore.fetchResults()

        this.forceUpdate()

    }
    debugView(){
        const { debugMode } = this.props.CommonStore
        const { dayValues  } = this.props.ChangeDayStore
        if (debugMode){
            return (
                <div className="planDebugger">
                <pre>
                {JSON.stringify(dayValues, null, 4)}
                </pre>
                </div>
            )
        }
    }
    
    render() {
        const {  index, plan, closeModal, week } = this.props
        const { dayValues, canSearchWorkouts } = this.props.ChangeDayStore
        var planIndex = Number(index)
        var currentWeek = 0 
        if (week){
            currentWeek = week
        }
        var previewPlanItem = plan[currentWeek].days[planIndex]
        var momentInstance = Moment
        var momentDay = momentInstance(previewPlanItem.day_str, "YYYY-MM-DD Z")

        const locationC = () => { 
			return (
                <Location handleChange={this.changeDayData} index={index} value={dayValues} />
			)
		}
        const type = () => {
            return (
                <Type  handleChange={this.changeDayData} index={index} value={dayValues} />
            )
        }
        const when = ()=> {
            //TODO - make this one return and an combined if
            //if not gx class and not rest day 
            if (dayValues.type === 'gx_class' && !this.props.ClubDataStore.clubDataLoaded ){
                return (
                    <WhenTime handleChange={this.changeDayData} index={index} value={dayValues} />
                )
            }
            if (dayValues.type !== 'gx_class' && dayValues.type !== 'rest_day'){
                return (
                    <WhenTime handleChange={this.changeDayData} index={index} value={dayValues} />
                )
            }
        }

        const theme = ()=> {
            if (dayValues.type === 'workout'){
                return (
                    <Theme  handleChange={this.changeDayData} index={index} value={dayValues} />
                )
            }
        }

        const focus = ()=> {
            if (dayValues.type !== 'gx_class' && dayValues.type !== 'activity' && dayValues.type !== 'rest_day' && dayValues.type !== 'fit_appointment_workout' && dayValues.type !== 'pt_session'&& dayValues.type !== 'daily_challenge'){
				if (canSearchWorkouts){
					return (
                        <Focus handleChange={this.changeDayData} />
					)
				}
            }
        }

        const duration = ()=> {
            if (dayValues.type !== 'gx_class' && dayValues.type !== 'activity' && dayValues.type !== 'rest_day' && dayValues.type !== 'fit_appointment_workout' && dayValues.type !== 'pt_session'&& dayValues.type !== 'daily_challenge'){
				if (canSearchWorkouts){
					return (
						<Duration handleChange={this.changeDayData} />
					)
				}
            }
        }
        const intensity = ()=> {
            if (dayValues.type !== 'gx_class' && dayValues.type !== 'activity' && dayValues.type !== 'rest_day' && dayValues.type !== 'fit_appointment_workout' && dayValues.type !== 'pt_session'&& dayValues.type !== 'daily_challenge'){
				if (canSearchWorkouts){
					return (
						<Intensity handleChange={this.changeDayData} />
					)
				}
            }
        }

        const searchResultsHeader = ()=> {
            //show hide logic
            return (
                <SearchResultsHeader/>
            )
        }
        const results = ()=> {
            return (
                <TaskResults ref={this.someRefName} scrollFunction={this.fetchMoreResults} changeDay={this.changeDay} />
            )
        }

        const trackAndClose = ()=> {
            if ( window.analytics ) {
                var filters_obj = JSON.parse( JSON.stringify( this.props.ChangeDayStore.dayValues ) );
                delete filters_obj.club_id;
                delete filters_obj.group_class_id;
                delete filters_obj.stream_id;
                delete filters_obj.is_rest_day;
                delete filters_obj.start_date;
                delete filters_obj.task_date;
                delete filters_obj.task_id;
                delete filters_obj.task_int;
                delete filters_obj.task_type;
                delete filters_obj.user_plan_id;
                delete filters_obj.user_plan_task_id;
                window.analytics.track( 'close_workout_selector', filters_obj );
            }
            closeModal();
        }

        const showLoading = ()=> {
            if (this.props.ChangeDayStore.inProgress){
                return (
                    <div className="modalContentLoadingOverlay">
                      <div className="appLoader">
                        <div className="appLoadingSpinner">
                          <div className="loader"></div>
                          <span className="loadingText"></span>
                        </div>
                      </div>
                    </div>
                )
            }
        }
    
        const toggleMobileFilters = () => {
            const {showMobileModal} = this.state;
            this.setState({
                showMobileModal: !showMobileModal
            });
        }

        const mobileFilters = () => {
            return (
                <div className="mobileFilter">
                    <Grid item xs={12} sm={4} className="previewLeft">
                        {locationC()}
                        {type()}
                        {when()}
                        {theme()}
                        {focus()}
                        {duration()}
                        {intensity()}
                        
                    </Grid>
                    <Hidden smUp>
                        <button onClick={toggleMobileFilters} className="showResultsButton">
                            Show Results
                        </button>
                    </Hidden>
                </div>
            );
        }

        return (
        <div className="printFix">
        <div className="appFullModal animate-fadeIn">

            <div className="modalHeader animate-fadeIn animate-delay-0-5" onClick={ trackAndClose }>
                <span className="leftSide">
                <strong>Edit {momentDay.format("dddd, MM/DD")}</strong>
                </span>
                <button className="rightSide" >
                Done <IconClose nativeColor="#fff" id="icon" className="icon icon-close" />
                </button>
            </div>
            <div className="modalWrapper animate-slideUp mobileModalWrapper">
                <Grid container className="modalContent changeDayModal">
                    <Hidden xsDown>
                        <Grid item xs={12} sm={4} className="previewLeft">
                            {locationC()}
                            {type()}
                            {when()}
                            {theme()}
                            {focus()}
                            {duration()}
                            {intensity()}
                        </Grid>
                    </Hidden>
                    <Hidden smUp>
                        {this.state.showMobileModal && mobileFilters()}
                    </Hidden>
                    <Grid item xs={12} sm={8} className="resultsRight">
                        <Hidden smUp>
                            <Grid container alignItems="center" className="headerButtonContainer">
                                <button onClick={toggleMobileFilters}>Filters</button>
                            </Grid>
                        </Hidden>
                        {searchResultsHeader()}
                        {results()}
                    </Grid>
                </Grid>
                {showLoading()}
            </div>
        </div>
        </div>
        )
    }
}

ChangeDayModal = inject( 'CommonStore', 'ClubDataStore', 'PreviewModalStore', 'CurrentPlanStore','StreamStore','ChangeDayStore')(observer(ChangeDayModal))
export default ChangeDayModal
