import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import Moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';

class SelectDays extends Component {
    state = {
        days: [],
    };
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this)
    }
    componentWillMount(){
        const {  start_date  } = this.props.PlanBuilderStore.values;
        //TODO bail if no startdate
        //TODO find better way to fire up moment instance.. this is weird
        var momentInstance = Moment
        var newDays = []
        for (var i=0;i<7;i++){
            var newDay= momentInstance(start_date).add(i,'days')
            newDays.push(newDay)
        }

        this.setState({
            days: newDays,
        });

    }
    handleChange = (e) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        const {ident} = this.props
        var value = e.currentTarget.value
        const {workout_days} = this.props.PlanBuilderStore.values
        var workoutDaysArray = workout_days.split('|')
        const currentIndex = workoutDaysArray.indexOf(value);

        if (currentIndex === -1) {
            workoutDaysArray.push(value);
        } else {
            workoutDaysArray.splice(currentIndex, 1);
        }
        var pipeString = workoutDaysArray.join("|")
        this.props.PlanBuilderStore.setValue(ident,pipeString)
    }

    render(){
        const {days} = this.state
        const {workout_days} = this.props.PlanBuilderStore.values
        const getDays = ()=> {
            var workoutDaysArray = workout_days.split('|')
            var dayBoxes = []
            days.forEach( ( value, index ) => {
               dayBoxes.push(
                    <button className="day"
                        key= {index}
                        value={index}
                        onClick={this.handleChange(index)}
                        >

                        <Checkbox
                            checked={workoutDaysArray.indexOf(String(index)) !== -1}
                            tabIndex={-1}
                            style={{ color: '#3CB6CE' }}
                            disableRipple
                        />
                        <div className="dayNumber">{days[index].format('DD').replace(/^0+/, '')}</div>
                        <div className="dayName">{days[index].format('ddd')}</div>
                    </button>
               )
            })
            return dayBoxes
        }
        return(
            <div className="stepContent animate-fadeInDown">
                <h1>What days are you available to workout?</h1>
                <form className="form formDaysSelect">
                {getDays()}
                </form>
            </div>
        )
    }
}

SelectDays = inject('PlanBuilderStore')(observer(SelectDays))
export default SelectDays;
