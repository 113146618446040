
function findReccomend(recObj, dayIndex, themeObject, workoutLocation ){
    var newThemeObj = {}
    var recIndex = ''
    recObj.forEach( (recItem, index) => {
        if (dayIndex === recItem.dayOfTheWeek ){
            recIndex = index 
        }
    })
    if (recObj[recIndex]){
        var dayObj = recObj[recIndex]     
        var recTheme  = '' 
        if (workoutLocation==='home'){
            recTheme = dayObj.home 
        }else{
            recTheme = dayObj.club
        }
        var sortable = []
        for (var theme in themeObject) {
            sortable.push([theme, themeObject[theme]]);
        }
      
        var indexForTopItem = 0
        sortable.forEach( (themeItem, index) => {
            if (recTheme === themeItem[1]){
                indexForTopItem = index 
            } 
        })
        var item = sortable.splice(indexForTopItem,1) 
		sortable.unshift(item[0])
        sortable.forEach( (themeItem, index) => {
			newThemeObj[themeItem[0]] = themeItem[1] 	
		})
        return  newThemeObj
    }
}

export {findReccomend}
