import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

class Type extends Component {

    render() {
        const {is_club} = this.props.ChangeDayStore.dayValues
        
        //console.log("##### Type Render #####")
        const {handleChange, index, value, disabled } = this.props
        const homeTheme = {
            'Workout':'workout',
            'Daily Challenge':'daily_challenge',
            'Rest Day':'rest_day',
            'Activity':'activity'
        }
        const clubTheme = {
            'Workout':'workout',
            'Rest Day':'rest_day',
            'Class':'gx_class',
            'Fit Appointment Workout':'fit_appointment_workout',
            'PT Session':'pt_session',
            'Daily Challenge':'daily_challenge',
            'Activity':'activity'
        }
        const non24ClubTheme = {
            'Workout':'workout',
            'Rest Day':'rest_day',
            'Daily Challenge':'daily_challenge',
            'Class':'gx_class',
            'Activity':'activity'
        }

        const theme = () => {
            var homeOrClubTheme = {}
            if (is_club){
                homeOrClubTheme = clubTheme 
            }else{
                homeOrClubTheme  = homeTheme 
            }
            if (!this.props.ClubDataStore.clubDataLoaded && is_club){
                homeOrClubTheme = non24ClubTheme
            }


            return Object.keys(homeOrClubTheme).map( (key, index) => {
                return (
                    <MenuItem
                        key={index}
                        value={homeOrClubTheme[key]}
                     >{key}</MenuItem>
                )
            });
        }
        return(
            <FormControl
                    fullWidth
                    disabled={disabled}
                >
                <div className="inputBlockWrapper">
                  <InputLabel htmlFor="name-disabled">Type</InputLabel>
                  <Select
                      fullWidth
                      label="Type"
                      index={index}
                      value= {value.type}
                      onChange={handleChange}
                      className="dayType"
                      inputProps={{
                          name: "type"+index,
                          id: 'type',
                      }}
                  >
                       {theme()}

                  </Select>
                </div>
            </FormControl>
        );
    }
}

Type = inject('ClubDataStore', 'ChangeDayStore')(observer(Type))
export default Type;
