import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import LoginForm from './LoginForm.js';
import CreateEmail from './CreateEmail.js';
import TrainerSyncNotice from './TrainerSyncNotice.js';
import Grid from '@material-ui/core/Grid';


class Create extends Component {
    showTerms(){
            this.props.ModalStore.setSection('terms')
            this.props.ModalStore.setCloseVisible()
            this.props.ModalStore.openModal()
    }
    constructor(props){
        super(props);
        this.showTerms = this.showTerms.bind(this)
    }
    render() {
        return (

            <div className="appUsers">

              <Grid container spacing={0} className="gc gc-Users">
                <Grid item sm={5} xs={12} className="gi gi-Users-Club">
                  <h1>Club Member Registration</h1>
                  <TrainerSyncNotice />
                  <h2>For current 24 Hour Fitness Club members only</h2>
                  <LoginForm/>
                </Grid>
                <Grid item sm={2} xs={12} className="gi gi-Users-Or or">
                  <p><span>OR</span></p>
                </Grid>
                <Grid item sm={5} xs={12} className="gi gi-Users-NonClub">
                  <h1>Email Registration</h1>
                  <h2>Register for 24GO without a club membership.</h2>
                  <CreateEmail/>
                  <div className="subText">
                    By continuing, you agree to the<button onClick={this.showTerms}> Terms of Service </button>which include an assumption of risk.
                  </div>
                </Grid>
              </Grid>

            </div>

        );
    }
}

Create = inject('ModalStore')(observer(Create))
export default Create;
