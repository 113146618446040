import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import ActiveDay from './DayDetails/ActiveDay'
import GymLocatorModal from '../GymLocator/GymLocatorModal.js'
import ModalPromptForGym from '../ModalPromptForGym/ModalPromptForGym.js'
import ModalReferral  from '../ModalReferral/ModalReferral.js'

import Moment from 'moment';


class DayDetails extends Component {
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this)
        this.handleClassChange = this.handleClassChange.bind(this)
        this.gymSelected = this.gymSelected.bind(this)
    }
    componentWillMount(){
        //TODO bail if no startdate
        //TODO - make more advanced function that doesn't overwrite days
        if (this.props.ClubDataStore.clubDataLoaded){
            this.props.WeekClassesStore.getClasses()
        }
    }


    gymSelected(){
        this.props.WeekClassesStore.getClasses()
		this.props.ClubDataStore.closeSelectClubModal()
        if (!this.props.UserStore.member){
            this.props.ReferralStore.openModal()
        }
    }
    handleClassChange(e){
        /////
        /////set data
        ////
        var name = e.target.name
        var dayIndex = Number(name[name.length -1]);


        var classId = e.target.value
        /*var momentInstance = Moment
        //var start = momentInstance(start_time, "x").format('H:mm');
        this.props.PlanBuilderStore.updatePlanTemplateWorkoutDay(
            dayIndex, 'time', start
        )
        */
        this.props.PlanBuilderStore.updatePlanTemplateWorkoutDay(
            dayIndex, 'time', '' 
        )
        this.props.PlanBuilderStore.updatePlanTemplateWorkoutDay(
            dayIndex, 'group_class_id', classId
        )

        this.props.PlanBuilderStore.isPlanReady()
        this.forceUpdate()
    }
    handleChange(e){
        /////
        /////set data
        ////
        var name = e.target.name
        var dayIndex = Number(name[name.length -1]);

        if (name.includes('where')){
            console.log(e.target.value)
            //if true, at club, see if we have club_data

            if (e.target.value) {
                if (!this.props.PlanBuilderStore.hasSeenClubSelectorPrompt && !this.props.ClubDataStore.clubDataLoaded){
                	this.props.PlanBuilderStore.promptForGymModalIsOpen = true

                }
            }
            
            //hasSeenClubSelectorPrompt
            //nonClassPlan

            this.props.PlanBuilderStore.updatePlanTemplateWorkoutDay(
                dayIndex, 'is_club', e.target.value
            )
            this.props.PlanBuilderStore.updatePlanTemplateWorkoutDay(
                dayIndex, 'theme', ''
            )
            this.props.PlanBuilderStore.updatePlanTemplateWorkoutDay(
                dayIndex, 'time', ''
            )
        }
        if (name.includes('theme')){
            this.props.PlanBuilderStore.updatePlanTemplateWorkoutDay(
                dayIndex, 'theme', e.target.value
            )
            this.props.PlanBuilderStore.updatePlanTemplateWorkoutDay(
                dayIndex, 'time', ''
            )
            this.props.PlanBuilderStore.updatePlanTemplateWorkoutDay(
                dayIndex, 'group_class_id', ''
            )
            this.props.PlanBuilderStore.updatePlanTemplateWorkoutDay(
                dayIndex, 'stream_id', '' 
            )
        }
        if (name.includes('when')){
            this.props.PlanBuilderStore.updatePlanTemplateWorkoutDay(
                dayIndex, 'time', e.target.value
            )
            this.props.PlanBuilderStore.updatePlanTemplateWorkoutDay(
                dayIndex, 'group_class_id', ''
            )
        }
        if (name.includes('stream_id')){
            this.props.PlanBuilderStore.updatePlanTemplateWorkoutDay(
                dayIndex, 'stream_id', e.target.value
            )
        }

        //TODO - kind of annoying - figure out why/how to make subobjects observable/trigger render
        this.props.PlanBuilderStore.isPlanReady()
        this.forceUpdate()

    }


    debugView(){
        const { debugMode } = this.props.CommonStore
        const { planTemplate } = this.props.PlanBuilderStore
        if (debugMode){
            return (
                <div className="planDebugger">
                <pre>
                {JSON.stringify(planTemplate, null, 4)}
                </pre>
                </div>
            )
        }
    }
    
    showModal(){
                
        const {  clubDataModalIsOpen  } = this.props.ClubDataStore
        const {  promptForGymModalIsOpen  } = this.props.PlanBuilderStore
        const {  referralModalisOpen  } = this.props.ReferralStore
        if (referralModalisOpen){
            return (
                <ModalReferral />
            )
        }
        if (clubDataModalIsOpen){
            return (
                <GymLocatorModal gymSelected={this.gymSelected}/>
            )
        }else if(promptForGymModalIsOpen)  {
            return (
                <ModalPromptForGym/>
            )
        }
    }

    render() {
        const { planTemplate } = this.props.PlanBuilderStore
        const {  start_date, plan_id  } = this.props.PlanBuilderStore.values;
        const getDays = ()=> {
            var dayBoxes = []
            var momentInstance = Moment
            if (planTemplate.workout_day_objects){
                planTemplate.workout_day_objects.forEach( ( value, index ) => {
                   var momentDay= momentInstance(start_date).add(index,'days')
                   if  (value.is_rest_day){
                        dayBoxes.push(
                            <div key={index} className="day" >
                                <div className="dayNumber">{momentDay.format('DD').replace(/^0+/, '')}</div>
                                <div className="dayName">{momentDay.format('ddd')}</div>
                            </div>
                        )
                   }else{
                        dayBoxes.push(
                            <div key={index} className="day daySelected" >
                                <div className="dayNumber">{momentDay.format('DD')}</div>
                                <div className="dayName">{momentDay.format('ddd')}</div>
                                <ActiveDay planId={plan_id} handleClassChange={this.handleClassChange} handleChange={this.handleChange} index={index} value={value} />
                            </div>
                        )
                   }
                })
            }
            return dayBoxes
        }


        if (this.props.WeekClassesStore.inProgress) {
            return(
              <div className="appLoader">
                <div className="appLoadingSpinner">
                  <div className="loader"></div>
                  <span className="loadingText"></span>
                </div>
              </div>
            )
        }else{
            return (
                <div>

				        {this.showModal()}
                        {this.debugView()}
                    <div className="stepContent animate-fadeInDown">

                      <h1>Give us some more details about when and where.</h1>
                      <form className="form formDaysSelect formDaysSelected">
                        {getDays()}
                      </form>
                    </div>
            </div>
            )
        }
    }
}

DayDetails = inject('CommonStore', 'UserStore','WeekClassesStore', 'PlanBuilderStore','ClubDataStore','ReferralStore')(observer(DayDetails))
export default DayDetails;
