import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import VideoItem from './VideoItem'; 
import './VideoModal.scss'


class VideoModal extends Component {
    constructor(props){
        super(props);
        this.modalClose = this.modalClose.bind(this)
        this.outsideClick = this.outsideClick.bind(this)
    }
    modalClose(e){
        e.preventDefault();
        e.stopPropagation();
        //clear children
        this.props.VideoModalStore.setSection('nothing')
        this.props.VideoModalStore.closeModal()
    };

    outsideClick(e){
        e.preventDefault();
        e.stopPropagation();
        //close modal from oustide click
        if (e.target.className === 'appModal'){
                this.props.VideoModalStore.setSection('nothing')
                this.props.VideoModalStore.closeModal()
        }
    }
    modalItem(){
        switch (this.props.VideoModalStore.section){
            case "video":
                return (<VideoItem />)
            default:
                return (<div>default</div>)
        }
    }
    render() {
        const { isOpen  } = this.props.VideoModalStore;
        const styles = {
            modalVisibility: () => {
                if (isOpen) {
                    return {
                        display: 'block',
                    }
                }else{
                    return {
                        display: 'none',
                    }
                }
            },
        }
        return(
            <div className="appModal"
                style = {styles.modalVisibility()}
                onClick = { this.outsideClick }
            >
                <div className="modalVideoWrapper">
                    <button  className="closeButton"
                        onClick={this.modalClose}
                    >x</button>
                    <div className="modalContent">
                            {this.modalItem()}
                    </div>
                </div>
            </div>
        )
    }
}

VideoModal  = inject('VideoModalStore')(observer(VideoModal))
export default VideoModal;



