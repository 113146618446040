import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import TextField from '@material-ui/core/TextField';

class FinishPlan extends Component {
    handleChange = (e) => {
        this.props.PlanBuilderStore.setValue(e.target.name,e.target.value);
    }

    render() {
        const {custom_name} = this.props.PlanBuilderStore.values
        const { inProgress} = this.props.PlanBuilderStore
        if (!inProgress){
            return(
                <div className="stepContent animate-fadeInDown">
                    <h1>Name and save your plan.</h1>
                    <h2 className="fontSize150">What do you want to call your plan?</h2>
                    <TextField
                        id="custom_name"
                        label='Plan Name'
                        name='custom_name'
                        value={custom_name}
                        onChange={this.handleChange}
                        margin="normal"
                    />
                </div>
            );
        }else{
            return(
              <div className="appLoader">
                <div className="appLoadingSpinner">
                  <div className="loader"></div>
                  <span className="loadingText"></span>
                </div>
              </div>
            )
        }
    }
}

FinishPlan = inject('PlanBuilderStore')(observer(FinishPlan))
export default FinishPlan;
