import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class Height extends Component {

    handleChange = (e) => {
        var value = Number(e.target.value.replace(/\D/g,''));
        this.props.UserStore.setValue(e.target.name,value);
        this.props.UserStore.setHeight();
    }

    render(){
        var dataFeet = this.props.UserStore.currentUser.feet
        var dataInches = this.props.UserStore.currentUser.inches


        dataFeet = String(dataFeet)+'ft'
        dataInches = String(dataInches)+'in'

        //TODO make some sort of range function.
        const feetArray = [
            "4ft",
            "5ft",
            "6ft",
            "7ft"
        ]
        const inchesArray = [
            "0in",
            "1in",
            "2in",
            "3in",
            "4in",
            "5in",
            "6in",
            "7in",
            "8in",
            "9in",
            "10in",
            "11in"
        ]
        const feet = feetArray.map( (foot, i ) => {
            return (
                <MenuItem
                    key={foot}
                    value={foot}>
                        {foot}
                    </MenuItem>
            );
        })
        const inches = inchesArray.map( (inch, i ) => {
            return (
                <MenuItem
                    key={inch}
                    value={inch}>
                        {inch}
                    </MenuItem>
            );
        })
        return(
            <div className="stepContent animate-fadeInDown progress-Info-Wrapper">
                        <h3>How tall are you?</h3>
                        <div className="inputInlineWrapper">
                        <Select
                            label="Feet"
                            name="Feet"
                            className="feet"
                            value={String(dataFeet)}
                            onChange={this.handleChange}
                            inputProps={{
                            name: 'feet',
                            id: 'feet',
                            }}
                        >
                        {feet}
                        </Select>
                        </div>

                        <div className="inputInlineWrapper">
                        <Select
                            label="Inches"
                            name="Inches"
                            className="inches"
                            value={String(dataInches)}
                            onChange={this.handleChange}
                            inputProps={{
                            name: 'inches',
                            id: 'inches',
                            }}
                        >
                        {inches}
                        </Select>
                        </div>
            </div>


        )
    }
}
Height = inject('UserStore')(observer(Height))
export default Height;
