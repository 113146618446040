import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { IconCirclePlay } from '../Icons/Icons';
import './StreamInfo.scss'

class StreamInfo extends Component {

    componentWillMount(){
        const { streamID } = this.props
        this.props.StreamStore.getStream(streamID)
    }

    constructor(props){
        super(props);
        this.openVideo= this.openVideo.bind(this)
    }

    openVideo(e){
            e.preventDefault();
            e.stopPropagation();
            var vid = e.currentTarget.getAttribute('value') 
            this.props.VideoModalStore.setVideo(vid)
            this.props.VideoModalStore.openModal()
    }


    render(){
        const { stream } = this.props.StreamStore
        const { intensity } = this.props.UserStore.currentUser
        var localIntensity = 3
        if (intensity){
            localIntensity = intensity
        }
        var intensityStr = 'level_'+localIntensity
        
        const getBlocks = (blocks) => {
            if (blocks.length > 0){
                var block_items = []
                blocks.forEach( ( block, index3 ) =>{
                    
                    //name
                    var name = ''
                    name = block.name
                    //
                    //sets
                    //reset 
                    var rest = ''
                    var set = ''
                    var load = ''
                    var reps = ''
                    if (block.movements && block.movements.length > 0){
                       var movement = block.movements[0]
                       set = movement.sets 
                       rest = movement.rest
                       load = movement.load 
                       reps = movement.reps 
                    }

                    var minutes= ''
                    if (block.duration_seconds){
                        minutes = block.duration_seconds/60
                        minutes = Math.round(minutes) 
                    }
                    var equipment = ''
                    if (block.equipment_required && block.equipment_required.length > 0){
                        equipment = block.equipment_required.toString()
                    }


                    const showRepsOrDuration = () => {
                        if (reps){
                            return (
                                <span>{reps} reps</span>
                            )
                        }else{
                            return (
                                <span>{minutes} minutes</span>
                            )
                
                        }
                    }

                    const showRest = () => {
                        if (rest){
                            return (
                                <span> {rest} sec </span>
                            )
                        }else{
                            return (
                                <span> n/a </span>
                            )
                        }
                    }
                    const showLoad = () => {
                        if (load){
                            return (
                                <span> {load} </span>
                            )
                        }else{
                            return (
                                <span> n/a </span>
                            )
                        }
                    }

                    var video = block.video1_mp4
                    if (block.demo_mp4) video = block.demo_mp4
                    
                    block_items.push(
                        <tr key={index3}>
                            <td>
                                 <div onClick={this.openVideo} value={video} className="">
                                   <IconCirclePlay style={{'fontSize':12}} nativeColor="#44697D" id="icon" className="icon icon-play" />
                                    <span className="video-link">{name} </span>
                                </div>
                            </td>
                            <td>{set}</td>
                            <td>{showRepsOrDuration()}</td>
                            <td>{showRest()}</td>
                            <td>{showLoad()}</td>
                            <td className="lastColumn">{equipment}</td>
                        </tr>
                    )
                })
                return block_items
            }
        }

        const getBuckets = (set, index)=> {
            if (set.buckets){
                var buckets = []
                set.buckets.map( (bucket, index2 ) => {
                    if (bucket.positioned && bucket.positioned.length > 0){
                        buckets.push( getBlocks(bucket.positioned )) 
                    }
                    if (bucket.blocks && bucket.blocks.length > 0){
                        buckets.push( getBlocks(bucket.blocks) ) 
                    }
                    if (bucket.families && bucket.families.length > 0){
                        var familyBlockArray = []
                        if (bucket.families[0] && bucket.families[0][intensityStr]){
                            familyBlockArray.push(bucket.families[0][intensityStr])
                            buckets.push( getBlocks(familyBlockArray) ) 
                        }
                    }
                    return ''
                })
                return buckets
            } 
        }
        const getMinutes = (set) => {
            var total = 0
            set.buckets.forEach( (bucket) => {
                if (bucket.blocks.length > 0 ){
                   bucket.blocks.forEach( (block) => {
                        total = total + Number(block.duration_seconds)
                   })
                }
                if (bucket.positioned && bucket.positioned.length > 0 ){
                   bucket.positioned.forEach( (block) => {
                        total = total + Number(block.duration_seconds)
                   })
                }
                if (bucket.families.length > 0){
                   bucket.families.forEach( (family) => {
                        //for the time being 
                        if (family.level_2){
                            total = total + Number(family.level_2.duration_seconds)
                        }
                   })

                }
            })
            var minutes = (total/60)
            minutes = Math.round(minutes)
            return (
                <strong> {minutes} Minutes</strong>
            )    
        }
                
        const getSets = ()=> {
            if (stream.sets){
                var sets = []
                stream.sets.forEach( (set, index) => {
                    sets.push (
                        <div key = {index} className="appTableData">
                            <div className="tableHeader">
                                <h2>{set.name}: {getMinutes(set)} </h2>
                            </div>
                            <table className="tableData">
                            <tbody>
                                <tr>
                                    <th>Segment</th>
                                    <th>Sets</th>
                                    <th>Reps/Duration</th>
                                    <th>Rest After Set</th>
                                    <th>Load</th>
                                    <th className="lastColumn">Equipment</th>
                                </tr>
                                {getBuckets(set, index)}
                            </tbody>
                            </table>
                        </div>
                    )
                })
                return sets
            }
        }

        return (
            <div >
            {getSets()}
            </div>
        )
    }
}

StreamInfo = inject('UserStore', 'VideoModalStore', 'StreamStore')(observer(StreamInfo))
export default StreamInfo
