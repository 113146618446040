import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import TextField from '@material-ui/core/TextField';


import './Modal.scss'

class EmployeeLogin extends Component {
    handleChange = (e) => {
        this.props.UserStore.setValue(e.target.name,e.target.value);
    }
    submitLastName = (e) => {
        this.props.ModalStore.closeModal()
        this.props.UserStore.loginCheckIn()
            .then(() => {
                //close modal
                //TODO finish routing
                const { class_name  } = this.props.UserStore.currentUser;
                if (class_name === 'Member'){
                    this.props.history.replace('/users/confirmation');
                }else if (this.props.CommonStore.afterLoginRoute) {
                  var redirect = this.props.CommonStore.afterLoginRoute
                  this.props.CommonStore.afterLoginRoute = ''
                  this.props.history.replace(redirect);
                }else{
                    //dashboard
                    this.props.history.replace('/dashboard');
                }
            })
    }

    render() {
        const {employee_last_name} = this.props.UserStore.currentUser
        return(
            <div>
                <div className="modalContent">
                <h1 className="modalTitle">Employee Login</h1>
                <div className="modalSubTitle">Please enter your last name.</div>
                    <TextField
                        id="standard-name"
                        label='Last Name'
                        name='employee_last_name'
                        value={employee_last_name}
                        onChange={this.handleChange}
                        margin="normal"
                    />
                    <br/>
                    <button
                        onClick={this.submitLastName}
                        className="button-teal"
                    >Submit</button>
                </div>
            </div>
        );
    }
}

EmployeeLogin = inject('CommonStore','UserStore','ModalStore')(observer(EmployeeLogin))
export default EmployeeLogin;
