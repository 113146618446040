import { observable, action, runInAction, decorate  } from 'mobx';
import agent from '../../api/Agent';
import UserStore from '../UserStore';
import Moment from 'moment';

//TODO rename to just all and current or something
class CurrentPlanStore{
    inProgress = false;
    plan = {} 
    plans = []
    currentWeek = 0
    weeksCount = 0
    previewDayModalIsOpen = false;
    changeDayModalIsOpen = false;
    changeTimeModalIsOpen = false;
    planPreviewIndex = '' 

    clearValues(){
        this.plan={} 
        this.plans = []
        this.currentWeek = 0
        this.weeksCount = 0
        this.previewDayModalIsOpen = false;
        this.changeDayModalIsOpen = false;
        this.changeTimeModalIsOpen = false;
    }
    
    //setSection
    openChangeDayModal(){
        this.changeDayModalIsOpen  = true
    }
    openTimeChangeModal(){
        this.changeTimeModalIsOpen   = true
    }
    closeModals(){
        this.changeDayModalIsOpen  = false 
        this.changeTimeModalIsOpen   = false 
    }
    setPlanPreviewIndex(index){
        this.planPreviewIndex = index
    }
    getCurrentPlan(preserveWeek) {
        this.inProgress = true;
        var user_id = UserStore.currentUser.id
        return agent.Plans.getCurrentPlan(user_id)
        .then( (plan)  => {
            runInAction( () => {
               this.plan = plan.results[0]  
                if (this.plan && this.plan.week_objects){
                    this.weeksCount = this.plan.week_objects.length
                    var momentInstance = Moment
                    var today = momentInstance().startOf('day')
                    var today_str = today.format("YYYY-MM-DD")
                    if (!preserveWeek){
                        this.plan.week_objects.forEach( ( week , week_index ) => {
                            week.days.forEach( (day, index) => {
                                if (day.day_str === today_str){
                                    this.currentWeek = week_index  
                                    return
                                }
                            })
                        })
                    }
                }
            })
          })
		.catch(action((err) => {
			alert(err)
			//TODO error handling
			//TODO successfull errors too
			throw err;
		}))
        .finally(action(() => { this.inProgress  = false; }))
    }
    getAllPlans() {
        this.inProgress = true;
        var user_id = UserStore.currentUser.id
        return agent.Plans.getAllPlans(user_id)
        .then( (response_plans)  => {
            runInAction( () => {
                this.plans.replace(response_plans.results)

            })
          })
		.catch(action((err) => {
			alert(err)
			//TODO error handling
			//TODO successfull errors too
			throw err;
		}))
        .finally(action(() => { this.inProgress  = false; }))
    }
    quitCurrentPlan() {

        if (this.plan && this.plan.id){
            this.inProgress = true;
            return agent.Plans.quitPlan(this.plan.id)
            .then( (response)  => {
                // TODO: This same mess is in the Plan Join tracking.
                var obj = JSON.parse( JSON.stringify( response ) );
                if ( obj.plan ) var plan_obj = JSON.parse( JSON.stringify( obj.plan ) );
                if ( plan_obj ) {
                    for ( var key in plan_obj ) {
                        // Exclude some fields in the plan object.
                        if ( [ 'equipment_required', 'tags', 'days', 'plan_task_ids' ].indexOf( key ) === -1 ) {
                            if ( !obj[key] ) obj[key] = plan_obj[key];
                        }
                    }
                }
                delete obj.week_objects;
                delete obj.user_plan_task_ids;
                delete obj.plan;
                delete obj.templates;
                if ( window.analytics ) window.analytics.track( 'Plan Quit', obj );

                //TODO - this could be refactored,i think current is embedded in all 
                this.getAllPlans()
                this.getCurrentPlan()
              })
            .catch(action((err) => {
                alert(err)
                //TODO error handling
                //TODO successfull errors too
                throw err;
            }))
            .finally(action(() => { this.inProgress  = false; }))
        
        }
    }
}

decorate(CurrentPlanStore, {
    inProgress: observable,
    plan: observable,
    plans: observable,
    currentWeek: observable,
    weeksCount:observable,   
    getCurrentPlan: action,
    changeDayModalIsOpen: observable,
    changeTimeModalIsOpen: observable,
    quitPlanModalIsOpen: observable, 
    openTimeChangeModal : action,
    openChangeDayModal: action,
    closeModals: action,
    setPlanPreviewIndex: action,
    clearValues: action
});

export default new CurrentPlanStore();
