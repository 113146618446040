import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import Moment from 'moment';
import { IconPrint } from '../Icons/Icons';


class CurrentPlan extends Component {

    componentWillMount(){
        this.props.CurrentPlanStore.getCurrentPlan()
    }

    print(){
      if ( window.analytics ) window.analytics.track( 'print_workout');
      window.print()
    }

    render(){
        const {plan, inProgress} = this.props.CurrentPlanStore
        var momentInstance = Moment



        /////top part
        const created = ()=>{
           var create = momentInstance(plan.created_int, "x").format("MM-DD-YYYY")
           return (
                  <li><strong>Created:</strong> {create} </li>
           )
        }
        const started = ()=>{
           var start = momentInstance(plan.start_int, "x").format("MM-DD-YYYY")
           return (
                  <li><strong>Started:</strong> {start} </li>
           )
        }
        const getDuration = ()=> {
            if (plan && plan.plan && plan.plan.total_plan_days){
                return (
                    <li><strong>Duration:</strong> {plan.number_of_days} days</li>
                )
            }
        }

        const progressWorkouts = () =>{
            const {total_workouts, completed_workouts, workout_completion_percent} = plan 
            var divStyle = {
                width:workout_completion_percent+'%'
            }
            return (
              <div className="progressWorkouts">
                {/* this should count up as they complete, starting 0% */}
                <span className="progressBar" style={divStyle}></span>
                <span className="progressCount"><strong>{ completed_workouts }/{ total_workouts }</strong> Workouts</span>
              </div>
            )
        }
        const progressDate = () => {
            const { number_of_days, days_left, days_percent } = plan 

            var width = 100-days_percent 
            var divStyle = {
                width:width+'%'
            }

            return (
              <div className="progressDays">
                {/* this should count down, starting @ 100% */}
                <span className="progressBar" style={divStyle}></span>
                <span className="progressCount"><strong>{days_left}/{number_of_days}</strong> Days Left</span>
              </div>
            )
        }

        if (!inProgress ) {
            if (plan){
            return (
                <div className="currentPlan">
                    <h2>{plan.custom_name}</h2>
                    <div className="planSummary">
                        <ul>
                            {created()}
                            {started()}
                            {getDuration()}
                        </ul>
                        <div className="progressMeters">
                            {progressWorkouts()}
                            {progressDate()}
                        </div>
                        <button  className="printBtn" onClick= {this.print} ><IconPrint nativeColor="#000" id="icon" className="icon icon-print" /></button>
                    </div>
                </div>

            )
            }else{
                return (
                    <div></div>
                )
            }
        }else{
            return (
                <div>  </div>
            )
        }
    }
}

CurrentPlan = inject('CurrentPlanStore')(observer(CurrentPlan))
export default CurrentPlan
