import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';


class PrimaryGoal extends Component {

	radioSelected = undefined		

    data = []

    refData =  [
            {
                "name": "lose_weight",
                "label": "Lose Weight"
            },
            {
                "name": "tone_up",
                "label": "Tone Up"
            },
            {
                "name": "build_endurance",
                "label": "Build Endurance"
            },
            {
                "name": "increase_flexibility",
                "label": "Increase Flexibility"
            },
            {
                "name": "gain_muscle",
                "label": "Gain Muscle"
            },
            {
                "name": "strengthen_and_stabilize",
                "label": "Strengthen and Stabilize"
            }
    ]
    //TODO comp will mount business to make sure we have goods
    getData(){
        if (this.props.UserStore.currentUser.goals){
          //bust into array 
            var goals = this.props.UserStore.currentUser.goals 
            goals= goals.split('|')
            this.data = []
           
            //TODO cleaner ways to do this
            goals.forEach( (goal)=>{
                this.refData.forEach( (refDataValue, index )=> {
                    if(refDataValue.name === goal){
                       this.data.push(refDataValue) 
                    }
                })
            })
        }

    }

        
    componentWillMount(){
        this.getData()
    }

    constructor(props){
        super(props);
        this.handleToggle = this.handleToggle.bind(this)
    }
    handleToggle = e => (e) => {
        const {ident} = this.props
        if (e.target.value){
            this.radioSelected = Number(e.target.value);
        }
        if (e.currentTarget.getAttribute('value')) {
            this.radioSelected = Number(e.currentTarget.getAttribute('value'));
        }
        this.props.UserStore.setValue(ident,this.data[this.radioSelected].name)
    };


    render(){
        const {ident} = this.props
        //figure out if goals > 1
        

        var selected = undefined
        if (this.props.UserStore.currentUser[ident]){
            selected = this.props.UserStore.currentUser[ident] 
        }
        if (selected){
            this.data.forEach( (value, index) => {
                if (selected === value.name){
                    this.radioSelected = index
                }
            })
        }
		const radios = ()=> { 
			var components = [] 
			this.data.forEach( (value, index)=> {
				components.push( 

                    <ListItem
                            value= {index}
                        key={index}
                        role={undefined}
                        dense
                        className="listItem"
                        disableGutters={true}
                        button
                        divider={true}
                        onClick={this.handleToggle(index)}
                    >
                        <FormControlLabel 
                            value= {index}
                            checked={index === this.radioSelected} 
                            control={<Radio
                                style={{position:'absolute',right:0, bottom:0}}
                                color='primary'
                            />}
                            style={{paddingLeft:'15px',paddingRight:'15px'}}
                            label={this.data[index].label} />
                    </ListItem>
 				)
			})	
			return components
		}

        return (
            <div className="stepContent animate-fadeInDown">
              <h3>My primary fitness goal is to:</h3>
            <List 
                dense={true}
                disablePadding={true}
            >
                {radios()}	
            </List> 
            </div>
        )
    }
}

PrimaryGoal = inject('UserStore')(observer(PrimaryGoal))    
export default PrimaryGoal;
