import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import { observer , inject } from 'mobx-react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ExpansionPanelDetails, ExpansionPanelSummary,ExpansionPanel} from '../Shared/ExpansionPanel'

class Duration extends Component {

    handleToggle(e){
        const { handleChange } = this.props
        e.target.value = e.currentTarget.getAttribute('name')
        e.target.name = "duration" 
        handleChange(e)
    };
    
    constructor(props){
        super(props);
        this.handleToggle = this.handleToggle.bind(this)
    }
    render() {
        const {dayValues} = this.props.ChangeDayStore

        const durationObject = {
            'Under 30 Minutes':'is_duration_less_than_30',
            '30 - 45 Minutes':'is_duration_30_to_45',
            '45 - 60 Minutes':'is_duration_45_to_60',
            '60+ Minutes':'is_duration_more_than_60'
        }

        const theme =
            Object.keys(durationObject).map( (key, index) => {
                var val = durationObject[key]
                return (
                    <ListItem
                        key={index}
                        role={undefined}
                        dense
                        className="listItem"
                        disableGutters={true}
                        button
                        name={val}
                        divider={true}
                        onClick={this.handleToggle}
                    >
                        <div className=''> {` ${key}`} </div>
                        <Checkbox
                            checked = {dayValues[val]}
                            tabIndex={-1}
                            name={val}
                            style={{ color: '#3CB6CE',position:'absolute',right:0, bottom:-5 }}
                            disableRipple
                        />
                    </ListItem>
                )
            });
        return(

            <ExpansionPanel
            square     
            >
                <ExpansionPanelSummary className='expandTitle' expandIcon={<ExpandMoreIcon  />}>
                Duration 
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <div className="inputBlockWrapper">
                    <List 
                        dense={true}
                        disablePadding={true}
                    >
                    {theme}
                    </List> 
                </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

Duration = inject('ChangeDayStore')(observer(Duration))
export default Duration;
