import React from 'react';
import { IconWarrior, IconMartialArts, IconCore, IconMuscle, IconHeartRate, IconCycle, IconPool, IconTpose, IconStretch, IconJumpRope } from './Icons';

export default class IconsFactory {
    static build(name, props) {
        switch (name) {
            case 'dance':
                return <IconWarrior {...props}/>;
            case 'combat':
                return <IconMartialArts {...props}/>;
            case 'yoga':
                return <IconCore {...props}/>;
            case 'strength':
                return <IconMuscle {...props}/>;
            case 'cardio':
                return <IconHeartRate {...props}/>;
            case 'cycle':
                return <IconCycle {...props}/>;
            case 'pool':
                return <IconPool {...props}/>;
            case 'active_aging':
                return <IconTpose {...props}/>;
            case 'pilates_barre':
                return <IconStretch {...props}/>;
            case 'athletic_training':
                return <IconJumpRope {...props}/>;
            default:
                return undefined;
        }
    }
}