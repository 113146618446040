import React, { Component } from 'react';
//import { inject } from 'mobx-react';

class Mission extends Component {

    selectMission = (e) => {
        this.props.selectMission(e)
    }
    constructor(props){
        super(props);
        this.selectMission = this.selectMission.bind(this)
    }

    render(){

            return(
                <div className="stepContent animate-fadeInDown">
                    <h2>You're about to build a new FitPlan</h2>
                    <h1>Is this a Fit For Life Plan?</h1>
                    <h2>We noticed you signed up in person for specialized personal training</h2>
                    <button
                        onClick={this.selectMission}
                        className="button-mission button-skewed button-teal"
                        name="y"
                    >
                        Yes
                    </button>
                    <button
                        onClick={this.selectMission}
                        className="button-skewed button-teal"
                        name="n"
                    >
                       No 
                    </button>
                    <br/><br/>
                </div>
            );
    }


}
export default Mission;
