import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './Modal.scss'




class ClubNumber extends Component {
    constructor(props){
        super(props);
        this.state = {
            club_number : '' 
        };
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentWillMount(){
      //hack for cypress 
      this.props.UserStore.logout()
    }

    handleChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            club_number: e.target.value
        });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.ClubDataStore.getClubData(this.state.club_number) 
    }

    render() {
        return(
            <div className="modalContent">
                <h1 className="modalTitle">Club ID</h1>
                <div className="modalSubTitle">Please enter a club id.</div>
                <ValidatorForm
                    className="clubForm"
                    ref="form"
                    instantValidate = {false}
                    onSubmit={this.handleSubmit}
                    onError={errors => console.log(errors)}
                >
                    <TextValidator
                        label="ClubID"
                        onChange={this.handleChange}
                        name="ClubID"
                        value={this.state.club_number}
                        validators={['required']}
                        errorMessages={['this field is required']}
                    />
                    <button className="button-teal" type="submit">Submit</button>
                    <br/>
                </ValidatorForm>
            </div>
        );
    }
}

ClubNumber = inject('ClubDataStore','ModalStore', 'UserStore')(observer(ClubNumber))
export default ClubNumber;
