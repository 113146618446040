import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { observer , inject } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { IconBackCircle, IconGoLogo, IconDoor, IconProfile } from '../Icons/Icons';

import './Header.scss'

class Header extends Component {

    constructor(props){
        super(props);
        this.logout = this.logout.bind(this)
    }
    logout(){
        //fix logout bug - not the best fix, but cant figure out how there is a quick route to dashboard occasionally on logout 
        this.props.UserStore.clearValues()

        this.props.UserStore.logout()
        .then(() => {
            //TODO - put all of these in one place
            //this.props.UserStore.clearValues()
            this.props.TrainerSyncStore.clearValues()
            this.props.PlanBuilderStore.clearValues()
            this.props.CurrentPlanStore.clearValues()
            this.props.history.replace('/users');
        })
    }
    render() {
        const isLoggedIn = this.props.UserStore.isLoggedIn
        const {currentUser} = this.props.UserStore
        const pathname = this.props.location.pathname
        const showBack = ()=>{
            if (backPaths.indexOf(pathname) > -1 ){
            return(
                <Link to="/" className="">
                  <IconBackCircle nativeColor="#37424A" className="icon icon-back-circle" />
                </Link>
            )}
        }
        const showProfile = ()=> {
            if (currentUser.is_set_up ){
              return(
                <Link to="/dashboard" className="">
                   <IconProfile nativeColor="#fff" className="icon icon-profile" />
                </Link>
            )}
        }
        const noHeaderPaths = [
            '',
            '/',
            '/users',
            '/users/'
        ]
        const backPaths = [
            '/users/reset_password',
            '/users/reset_password/',
            '/users/create',
            '/users/create/',
            '/users/login',
            '/users/login/',
            '/users/trainer_login',
            '/users/trainer_login/',
            '/users/confirmation',
            '/users/confirmation/',
        ]
        if (noHeaderPaths.indexOf(pathname) > -1 ){
            return(
                <div></div>
            )
        }else{
            if (isLoggedIn){
              // logged in
               return (

                 <Grid container spacing={0} className="gc gc-Header gc-HeaderAuth">
                   <Grid item xs={1} className="gi gi-Header-Profile">

                    {showProfile()}

                   </Grid>
                   <Grid item xs={10} className="gi gi-Header-Logo">
                       <IconGoLogo nativeColor="#fff" className="icon icon-gologo" />
                   </Grid>
                   <Grid item xs={1} className="gi gi-Header-Logout">
                     <Link to="/dashboard" className="" onClick={this.logout}>
                       <IconDoor nativeColor="#fff" className="icon icon-logout" />
                     </Link>
                   </Grid>
                 </Grid>

               );
            } else {
              // logged out
              return(

                <div>

                  {/* new */}
                  <Grid container spacing={0} className="gc gc-Header gc-HeaderUnAuth">
                    <Grid item xs={2} sm={1} className="gi gi-Header-Back">
                      {showBack()}
                    </Grid>
                    <Grid item xs={8} sm={10} className="gi gi-Header-Logo">
                        <IconGoLogo nativeColor="#fff" className="icon icon-gologo" />
                    </Grid>
                    <Grid item xs={2} sm={1} className="gi gi-Header-Logout"></Grid>
                  </Grid>


                  {/*
                  <Grid container spacing={0} className="gc gc-Header gc-HeaderLanding">
                    <Grid item xs={2} sm={1} className="gi gi-Header-Logo">
                        <IconGoLogo nativeColor="#fff" className="icon icon-gologo" />
                    </Grid>
                    <Grid item xs={10} sm={11} className="gi gi-Header-Title">
                      <p>Get Started with 24GO</p>
                    </Grid>
                  </Grid>
                  */}

                  {/* old

                  <div className="header">
                      <header className="appHeader appHeaderNoAuth">
                          <nav className="leftNav">
                              {showBack()}
                          </nav>
                          <Icon24GO nativeColor="#000" id="icon" className="icon icon-24GO" />
                          <nav className="rightNav"></nav>
                      </header>
                  </div>
                   */}

                </div>


              )
            }
        }
  }
}
//TODO - header states (Eric fix above, modify as needed)
//back seems to make most sense to go home during reg
//instead of create to login to home

//logout
//trainer
//


Header = inject('CurrentPlanStore', 'PlanBuilderStore', 'TrainerSyncStore','UserStore')(observer(Header))
export default withRouter( Header );
