
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Intermission extends Component {

    constructor(props){
        super(props);
        this.continuePressed = this.continuePressed.bind(this)
    }
    continuePressed(e){
        const {continueFunc} = this.props
        continueFunc()
    }
    render() {
        return (
              <div className="appCenterMessage animate-fadeInDown">
                <h1>Now, tell us about your fitness experience.</h1>
                <h2>Take two minutes to complete your fitness profile so we can create your personalized GO plan.</h2>
                <button

                    onClick= {this.continuePressed}
                    className="button-teal button-skewed">Let's Do It</button>
              </div>
        );
    }
}

Intermission  = withRouter(Intermission )
export default Intermission
