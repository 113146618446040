import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import Moment from 'moment';
import StreamInfo from '../Dashboard/StreamInfo'
import { IconCalendar, IconClock, IconClose, IconHome, IconPrint } from '../Icons/Icons';


class PreviewDayModal extends Component {
    constructor(props){
        super(props);
        this.closeModal = this.closeModal.bind(this)
    }
    //move this to show modal functions
    componentWillMount(){
    }
    closeModal(){
      this.props.PreviewModalStore.closeModal()
    }
    print(){
      if ( window.analytics ) window.analytics.track( 'print_workout');
      window.print()
    }
    render() {
        const { isOpen, planItem } = this.props.PreviewModalStore;
        
        var task
        if (planItem.tasks.length > 0){
            task = planItem.tasks[0]
        }

        var formattedDay 
        var momentInstance = Moment

        if (planItem.day_str){
            formattedDay = momentInstance(planItem.day_str, "YYYY-MM-DD Z")
            formattedDay = formattedDay.format("dddd, MM/DD")
        }else{
            formattedDay = ''
        }
        
        // 
        //render functions
        //
        const getStream = ()=> {
            if (task.task.class_name === "Stream"){
                if (task.task.is_static) {
                    return (
                        <StreamInfo streamID={task.task.id} />
                    )
                }
            }
        }

        const intensity = ()=> {
            var intensityVal = task.task.applied_intensity
            if (intensityVal < 5){
                return (
                      <li>
                        <img alt='low_intensity' src="/fitplan/imgs/intensity/low.png" id="icon" className="icon icon-intensity-low" />
                        <span className="value">Low</span>
                        <span className="label">Intensity</span>
                      </li>
                )
            }else if (intensityVal < 8){
                return (
                      <li>
                        <img alt='medium_intensity' src="/fitplan/imgs/intensity/medium.png" id="icon" className="icon icon-intensity-medium" />
                        <span className="value">Medium</span>
                        <span className="label">Intensity</span>
                      </li>
                )
            }else if (intensityVal > 7 ){
                return (
                      <li>
                        <img alt='high_intensity' src="/fitplan/imgs/intensity/high.png" id="icon" className="icon icon-intensity-high" />
                        <span className="value">High</span>
                        <span className="label">Intensity</span>
                      </li>
                )
            }
        }
        const printBtn = ()=> {
              return(
                <button  className="printBtn" onClick= {this.print} ><IconPrint nativeColor="#000" id="icon" className="icon icon-print" /></button> 
              )
          /*
            if (task.task_int){
              return(
                <button  className="printBtn" onClick= {this.print} ><IconPrint nativeColor="#000" id="icon" className="icon icon-print" /></button> 
              )
            }
          */
        }

        const time = ()=> {
            if (task && task.task_int){
              var start = momentInstance(task.task_int, "x")
              var startFormat = start.format('h:mm a')
               return (
                  <li>
                    <IconCalendar nativeColor="#000" id="icon" className="icon icon-calendar" />
                    <span className="value">{startFormat}</span>
                    <span className="label">Start Time</span>
                  </li>
               )
            }
        }
        const duration = ()=> {
            if (task.task.class_name === "GroupClass"){
               return(
                  <li>
                    <IconClock nativeColor="#000" id="icon" className="icon icon-clock" />
                    <span className="value">{task.task.duration} min</span>
                    <span className="label">Duration</span>
                  </li>
               )
            }else{
                var seconds = parseInt(task.task.duration_seconds)
                var format =  momentInstance.duration(seconds,'seconds').minutes()
                return (
                      <li>
                        <IconClock nativeColor="#000" id="icon" className="icon icon-clock" />
                        <span className="value">{format} min</span>
                        <span className="label">Duration</span>
                      </li>
                )
            }
        }
        const location = ()=> {
            var location = ''
            if (task.task.class_name === "GroupClass"){
                location =  task.task.club_name
            } else if (task.task.class_name === "Stream"){
                if (task.task.is_club === true){
                    location = 'At Club'
                }else{
                    location = 'At Home'
                }
            }
               return (
                  <li>
                    <IconHome nativeColor="#000" id="icon" className="icon icon-house" />
                    <span className="value">{location}</span>
                    <span className="label">Location</span>
                  </li>
               )
        }
        const themeObject = {
            'Full Body Strength':'full_body_strength',
            'Upper Body Strength':'upper_body_strength',
            'Lower Body Strength':'lower_body_strength',
            'Abs/Core':'abs_core',
            'Cardio': 'cardio',
            'Combo - Cardio/Strength' : 'combo_cardio_strength',
            'Flexibility/Recovery':'flexibility_recovery',
            'Daily Challenge':'daily_challenge',
            'Class':'gx_class',
            'Fit Appointment Workout':'fit_appointment_workout'
        }
        const getTheme = () => {
            for (var key in themeObject) {
                if ( themeObject.hasOwnProperty(key)) {
                    if (task.theme === themeObject[key]){
                        return (
                            <li>
                              <p><strong>Theme:</strong> <span>{key}</span> </p>
                            </li>
                        )
                    }
                }
            }
        }

        const styles = {
            modalVisibility: () => {
                if (isOpen) {
                    return {
                        display: 'block',
                    }
                }else{
                    return {
                        display: 'none',
                    }
                }
            }
        }

        if (!this.props.PreviewModalStore.isOpen){
          return(
            <div></div>
          )
        }else{
            if ( window.analytics ) window.analytics.track( 'view_plan_day_detail', {
                club_id: this.props.ClubDataStore.values.id,
                club_number: this.props.ClubDataStore.club_number,
                trainer_code: this.props.TrainerSyncStore.values.share_code
            });
          return(
                <div className="previewDayModal appFullModal animate-fadeIn" style={styles.modalVisibility()}>
                  <div className="modalHeader animate-fadeIn animate-delay-0-5" onClick= {this.closeModal}>
                    <span className="leftSide">
                      <strong>Preview {formattedDay}</strong>
                    </span>
                    <button className="rightSide" >
                      Done <IconClose nativeColor="#fff" id="icon" className="icon icon-close" />
                    </button>
                  </div>

                  <div className="modalWrapper animate-slideUp">
                    <div className="modalContent planPreviewModal">
                      <div className="previewTop">
                        <div className="previewLeft">
                          <h1>
                            {task.task.name}
                          </h1>
                          {printBtn()}
                          <ul>
                            {time()}
                            {intensity()}
                            {duration()}
                            {location()}
                            <li>
                              <img alt='publisher' className="publisher" src="/fitplan/imgs/publishers/24hf.png" />
                              <span className="value">24 Hour Fitness</span>
                              <span className="label">Publisher</span>
                            </li>
                          </ul>
                        </div>

                        <div className="previewRight">
                          <img alt='' src={task.task.poster} />
                        </div>

                      </div>

                      <div className="previewTopExtra">
                        <ul>
                          {getTheme()}
                          <li>
                            <strong>Description:</strong> {task.task.description}
                          </li>
                        </ul>
                      </div>

                      <div className="fatSep fatSepFull"></div>
                      {getStream()}

                  </div>
                  </div>
                </div>
          )
      }
    }
}
PreviewDayModal = inject('PreviewModalStore', 'StreamStore', 'ClubDataStore', 'TrainerSyncStore')(observer(PreviewDayModal))
export default PreviewDayModal 
