import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import Moment from 'moment';
import { Hidden } from '@material-ui/core';

class WorkoutHistory extends Component {

    componentWillMount(){
        this.props.WorkoutHistoryStore.getWorkoutHistory()
    }

    formatHumanDuration( seconds ) {
        if ( !seconds ) return '';
        var hours = Math.floor( seconds / 3600 ),
            nonhours = seconds - ( hours * 3600 ),
            minutes = Math.floor( nonhours / 60 ),
            remainder = nonhours - ( minutes * 60 );
        return ( hours > 0 ? hours + ' hour ' : '' ) + ( minutes > 0 ? minutes + ' min ' : '' ) + ( remainder > 0 ? remainder + ' sec ' : '' );
    }

    render(){
        const {history, inProgress} = this.props.WorkoutHistoryStore
        var momentInstance = Moment




        const getHistory = ()=> {
            var historyItems = []
            if (history.length > 0){
                history.forEach( ( hItem, index ) => {
                    var start_date = momentInstance(hItem.start_int, "x").format("YYYY-MM-DD")
                    var start_time = momentInstance(hItem.start_int, "x").format("h:mm a")
                    var duration = this.formatHumanDuration( hItem.completed_seconds )
                    historyItems.push(
                        <tr key={index}>
                          <td>{start_date}</td>
                          <td>{start_time}</td>
                          <td>{duration}</td>
                          <td>{hItem.name}</td>
                          <td>{hItem.workout_display_type}</td>
                        </tr>
                    )
                })
            }else{
                return (
                    <tr>
                        <td>No History</td>
                    </tr>
                )
            }
            return historyItems

        }
        if (!inProgress) {
            return (
                <div className="appTableData appTableWorkoutHistory workoutHistory">
                  <div className="tableHeader">
                    <h2>My Workout History</h2>
                  </div>
                  <table className="tableData">
                    <tbody>
                    <Hidden smDown>
                        <tr>
                            <th>Date</th>
                            <th>Start Time</th>
                            <th>Duration</th>
                            <th>Name</th>
                            <th>Type</th>
                        </tr>
                    </Hidden>
                    {getHistory()}
                    </tbody>
                  </table>
                </div>
            )
        }else{
            return (
                <div>  </div>
            )
        }
    }
}
WorkoutHistory = inject('WorkoutHistoryStore')(observer(WorkoutHistory))
export default WorkoutHistory
