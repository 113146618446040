import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';

class TrainerSyncNotice extends Component {

    render() {
        const { id, first_name, last_name, avatar } = this.props.TrainerSyncStore.values;
        if (id){
            return (
                <div className="trainerSyncNotice">
                  <h2>
                    Your account is being paired with <strong><img alt='profile' src={avatar} /> {first_name} {last_name} </strong>
                  </h2>
                </div>
            );
            }else{
                return (
                    <span></span>
                )
        }
  }
}

TrainerSyncNotice = inject('TrainerSyncStore')(observer(TrainerSyncNotice))
export default TrainerSyncNotice;
