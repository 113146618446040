import React, { Component } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { observer , inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import './Debug.scss'
import { withRouter } from 'react-router-dom';

class Debug extends Component {
    componentDidMount(){

    }

    state = {
        top: false,
        left: false,
        bottom: false,
        right: false,
        url: ''
    };
    toggleDrawer = (side, open) => () => {
        this.setState({
          [side]: open,
        });
    };
    login = () =>  () => {
        this.props.UserStore.setValue('checkin_code', '5125123333');
        this.props.UserStore.setValue('year', '1982');
        this.props.UserStore.setValue('day','13');
        this.props.UserStore.setValue('month','4');
        this.props.UserStore.loginCheckIn()
        .then(() => {
            this.props.history.replace('/dashboard');
        });
    };
    create = () =>  () => {
        this.props.UserStore.setValue('checkin_code', '5125120000');
        this.props.UserStore.setValue('year', '1980');
        this.props.UserStore.setValue('day','25');
        this.props.UserStore.setValue('month','3');
        this.props.UserStore.loginCheckIn()
        .then(() => {
            //this.props.history.replace('/dashboard');
            this.props.history.replace('/users/confirmation');
            //email
            //then do confirmation shit 
            //
            var email = Math.random().toString(36).slice(2) +'@yourtrainer.com'
            this.props.UserStore.setValue('email', email);
            this.props.UserStore.confirmationCheckIn()
            .then(() => {
                this.props.history.replace('/profile');
            })
        });
    }
    createEmail = () => () => {
        //first_name, last_name, year, day, month, email, password 
        this.props.UserStore.setValue('first_name', 'test');
        this.props.UserStore.setValue('last_name', 'test');
        this.props.UserStore.setValue('day','27');
        this.props.UserStore.setValue('month','7');
        this.props.UserStore.setValue('year', '1981');
        var email = Math.random().toString(36).slice(2) +'@yourtrainer.com'
        this.props.UserStore.setValue('email', email);
        this.props.UserStore.setValue('password', 'asdfasdf');
        this.props.UserStore.register()
        .then(() => {
            this.props.history.replace('/profile');
        })
    }


    clearClub = () =>  () => {
        this.props.ClubDataStore.clearClubData()
    }
    clubSignIn = () =>  () => {
        this.props.ClubDataStore.getClubData('00202')
    }
    ericModalOverlay = () => () => {
        this.props.CommonStore.clearEricDebug() 
        this.props.CommonStore.ericDebugModalOverlay = true
        this.props.history.replace('/plans');
    }
    ericDebugReccomended = () => () => {
        this.props.CommonStore.clearEricDebug() 
        this.props.CommonStore.ericDebugReccomended = true
        this.props.history.replace('/plans');
    }
    ericDebugProfileGym = () => () => {
        this.props.CommonStore.clearEricDebug() 
        this.props.CommonStore.ericDebugProfileGym = true
        this.props.history.replace('/profile');
    }


    componentWillUpdate ({ location, history }) {
        if (location.pathname === this.props.location.pathname) {
            return;
        }
        this.setState({
            'url': location.pathname,
        });
    }

    render(){
        const {  debugMode } = this.props.CommonStore
        const {clubDataLoaded, club_number} = this.props.ClubDataStore
        const visibility = () => {
            if (debugMode){
                return {
                    display:'block'
                }
            }else{
                return {
                    display:'none'
                }
            }
        }
        const getClubButton = () => {
            if (clubDataLoaded){            
                return (
		            <button onClick={this.clearClub()}>ClearClub {club_number}</button>
                )
            }else{
                return (
                    <button onClick={this.clubSignIn()}>SELECT CLUB</button>
                )
            }
        }
        return(
            <div className="debugLinks" style={visibility()} >
		        <button onClick={this.toggleDrawer('left', true)}>Links</button>
                <input
                id="outlined-bare"
                className="debugText"
                margin="normal"
                value={this.state.url}
                readOnly
                variant="outlined"
                />
                <SwipeableDrawer
                    open={this.state.left}
                    onClose={this.toggleDrawer('left', false)}
                    onOpen={this.toggleDrawer('left', true)}
                >
                    <Link to="/users">Users</Link>
                    <Link to="/profile">Profile</Link>
                    <Link to="/plans">Plans</Link>
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to="/mock">Mock</Link>

                </SwipeableDrawer>
		        <button onClick={this.login()}>Login</button>
		        <button onClick={this.create()}>CreateUser</button>
		        <button onClick={this.createEmail()}>CreateEmailUser</button>
                {getClubButton()}
		        <button onClick={this.ericModalOverlay()}>EricModalOverlay</button>
		        <button onClick={this.ericDebugReccomended()}>EricReccomended</button>

		        <button onClick={this.ericDebugProfileGym()}>EricProfileGymQuestion</button>
            </div>
        )
    }
}


Debug = inject('CommonStore','UserStore', 'ClubDataStore')(observer(Debug))
export default withRouter(Debug);
