import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { inject } from 'mobx-react';

class WhenClasses extends Component {

    render() {
        //console.log("##### WhenClasses Render #####")
        const {handleChange, index, value, disabled } = this.props
        const { weekClasses } = this.props.WeekClassesStore
        const time =
            weekClasses[index].map( (gx_class, subIndex) => {
                //var momentInstance = Moment
                //var displayTime = momentInstance(value,'h:mm a').format('h:mm a');
                //console.log(gx_class.id)
                return (
                    <MenuItem
                        key={subIndex}
                        value={gx_class.id}
                        start_int = {gx_class.start_int}
                     >{gx_class.formatted_time} {gx_class.name} </MenuItem>
                )
			
            })
        //console.log("*****")
        //console.log('group class id in render ' + value.group_class_id)
        //console.log(value.group_class_id)
        //console.log(value)
        //console.log("*****")
        return(
            <FormControl
                    disabled={disabled}
                    fullWidth
                >
                <div className="inputBlockWrapper">
                  <InputLabel htmlFor="name-disabled">When?</InputLabel>
                  <Select
                      fullWidth
                      label="When?"
                      index={index}
                      value= {value.group_class_id}
                      onChange={handleChange}
                      className="dayWhen"
                      inputProps={{
                          name: "when"+index,
                          id: 'when',
                      }}
                   >
                      {time}
                  </Select>
                </div>
            </FormControl>
        );
    }
}

WhenClasses = inject('PlanBuilderStore', 'WeekClassesStore')(WhenClasses)
export default WhenClasses;
