import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import { inject } from 'mobx-react';
import {findReccomend} from './ReccomendationUtil.js';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { IconCheck } from '../../Icons/Icons';
import ActivityDropdown from './ActivityDropdown'
class What extends Component {


    render() {
        const {handleChange, index, value, disabled } = this.props
        const { recItems } = this.props.ReccomendationStore
        var themeObject = {
            'Full Body Strength':'full_body_strength',
            'Upper Body Strength':'upper_body_strength',
            'Lower Body Strength':'lower_body_strength',
            'Combo - Cardio/Strength':'combo_cardio_strength',
            'Abs/Core':'abs_core',
            'Cardio': 'cardio',
            'Flexibility/Recovery':'flexibility_recovery',
            'Class':'gx_class',
            'PT Session':'pt_session',
            'Daily Challenge':'daily_challenge',
            'Fit Appointment Workout':'fit_appointment_workout',
            'Activity':'activity'
        }
        if (!this.props.ClubDataStore.clubDataLoaded){
            themeObject = {
                'Full Body Strength':'full_body_strength',
                'Upper Body Strength':'upper_body_strength',
                'Lower Body Strength':'lower_body_strength',
                'Combo - Cardio/Strength':'combo_cardio_strength',
                'Abs/Core':'abs_core',
                'Cardio': 'cardio',
                'Flexibility/Recovery':'flexibility_recovery',
                'Class':'gx_class',
                'Daily Challenge':'daily_challenge',
                'Activity':'activity'
            }
        }
        //TODO - send copy of recItems, not direct pointer
        var newTheme = findReccomend(recItems, index, themeObject, 'gym')
		if (newTheme){
			themeObject = newTheme
		}
        const theme = () =>
            Object.keys(themeObject).map( (key, index) => {
                if (index === 0 ){
                    return (
                        <MenuItem key={index} value={themeObject[key]}>
                          {key}
                          <div className="whatRecommend">
                            <IconCheck nativeColor="#000" id="icon" className="icon icon-check" /> Recommended
                          </div>
                        </MenuItem>
                    )
                }else{
                    return (
                        <MenuItem
                            key={index}
                            value={themeObject[key]}
                         >{key}</MenuItem>
                    )
                }
            });
        return(
            <span>
            <FormControl
                    fullWidth
                    disabled={disabled}
                >
                <div className="inputBlockWrapper">
                  <InputLabel htmlFor="name-disabled">What?</InputLabel>
                  <Select
                      fullWidth
                      label="Theme?"
                      index={index}
                      value= {value.theme}
                      onChange={handleChange}
                      className="dayWhat"
                      inputProps={{
                          name: "theme"+index,
                          id: 'theme',
                      }}
                  >
                       {theme()}
                  </Select>
                </div>

            </FormControl>

                <ActivityDropdown value={value} index={index} handleChange={handleChange}/>
            </span>
        );
    }
}

What = inject('ClubDataStore', 'ReccomendationStore')(What)
export default What;
