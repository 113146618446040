import React, { Component } from 'react';
import {  Switch, Route  } from 'react-router-dom';
import Home from './Home.js';
import Create from './Create.js';
import Login from './Login.js';
import Confirmation from './Confirmation.js';
import Success from './Success.js';
import TrainerLogin from './TrainerLogin.js';
import ResetPassword from './ResetPassword.js';

import './Users.scss';

class Users extends Component {
    render() {
        return (
            <div className="users">
                <Switch basename="/fitplan/"  >
                    <Route path="/users/trainer_login" component={TrainerLogin} />
                    <Route path="/users/create" component={Create} />
                    <Route path="/users/login" component={Login} />
                    <Route path="/users/confirmation" component={Confirmation} />
                    <Route path="/users/success" component={Success} />
                    <Route path="/users/reset_password" component={ResetPassword} />
                    <Route path="/users" component={Home} />
                    <Route path="/" component={Home} />
                </Switch>
        </div>
        );
  }
}

export default Users;
