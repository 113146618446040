import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

class Theme extends Component {

    render() {
        //console.log("##### Theme Render #####")
        const {handleChange, index, value, disabled } = this.props

        const themeObject = {
            'Cardio': 'cardio',
            'Strength':'strength',
            'Core' : 'core',  
            'Stretch':'stretch',
            'Recovery':'recovery',
            'Combo':'combo'
        }
        const theme =
            Object.keys(themeObject).map( (key, index) => {
                return (
                    <MenuItem
                        key={index}
                        value={themeObject[key]}
                     >{key}</MenuItem>
                )
            });
        return(
            <FormControl
                    fullWidth
                    disabled={disabled}
                >
                <div className="inputBlockWrapper">
                  <InputLabel htmlFor="name-disabled">Theme</InputLabel>
                  <Select
                      fullWidth
                      label="Theme?"
                      index={index}
                      value= {value.theme}
                      onChange={handleChange}
                      className="dayWhat"
                      inputProps={{
                          name: "theme"+index,
                          id: 'theme',
                      }}
                  >
                       {theme}

                  </Select>
                </div>
            </FormControl>
        );
    }
}

export default Theme;
